import React, { useContext, useEffect } from "react";
import { TablePagination } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Theme, ThemeContext } from "../../../common/hoc/ThemeProvider/ThemeContext";

type TablePaginationMiu = {
  rowsPerPageOptions?: number[];
  countElement: any[];
  page: number;
  handleChangePage: (currentPage: number) => void;
  limitPage: number;
};

export const TablePaginationMiuComponent = (props: TablePaginationMiu) => {
  const { rowsPerPageOptions = [], countElement = [], page, limitPage, handleChangePage } = props;
  const count = countElement.length;

  useEffect(() => {
    if (count === 0 && page !== 0) {
      handleChangePage(0);
    }
  }, [count, page, handleChangePage]);

  const { theme } = useContext(ThemeContext);
  const themeMiu = createTheme({
    components: {
      MuiTablePagination: {
        defaultProps: { color: "green" },
        styleOverrides: {
          root: {
            color: theme === Theme.DARK ? "#c3c1c7" : "black",
          },
          selectIcon: {
            color: theme === Theme.DARK ? "#c3c1c7" : "black",
          },
          actions: {
            color: theme === Theme.DARK ? "#c3c1c7" : "black",
            "& .Mui-disabled": {
              color: theme === Theme.DARK ? "rgba(195,193,199,0.47)" : "", // Цвет для неактивных иконок
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={themeMiu}>
      <TablePagination
        component="div"
        rowsPerPageOptions={rowsPerPageOptions}
        count={count}
        rowsPerPage={limitPage}
        page={page}
        onPageChange={(event, newPage) => handleChangePage(newPage)}
      />
    </ThemeProvider>
  );
};
