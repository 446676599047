import React, { useContext, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useAppDispatch } from "../../common/hooks/hooks";
import { useSelector } from "react-redux";
import { AppRootStateType } from "../../app/store";
import { appAction } from "../../app/app-reducer";
import "react-toastify/dist/ReactToastify.css";
import { Theme, ThemeContext } from "../../common/hoc/ThemeProvider/ThemeContext";

type ErrorComponentPropsType = {
  position?:
    | "top-right"
    | "top-center"
    | "top-left"
    | "bottom-right"
    | "bottom-center"
    | "bottom-left";
  autoClose?: number | false;
  hideProgressBar?: boolean;
  newestOnTop?: boolean;
  rtl?: boolean;
  // theme?: 'light' | 'dark' | 'colored'
  className?: string;
  errorMessage?: string;
  closeOnClick?: boolean;
};

export const ErrorComponent: React.FC<ErrorComponentPropsType> = (props) => {
  const {
    rtl = false,
    hideProgressBar = true,
    autoClose = 5000,
    position = "bottom-left",
    newestOnTop = false,
    className,
    closeOnClick = true,
  } = props;
  const dispatch = useAppDispatch();
  const errorMessage =
    useSelector<AppRootStateType, string | null>((state) => state.app.error) || props.errorMessage;
  useEffect(() => {
    // toast("Loaded successfully");
    if (errorMessage) {
      toast.error(errorMessage, { draggable: false });
    }

    setTimeout(() => {
      dispatch(appAction.setError({ error: null }));
    }, 5000);
  }, [errorMessage, dispatch]);
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <ToastContainer
        position={position}
        autoClose={autoClose}
        hideProgressBar={hideProgressBar}
        newestOnTop={newestOnTop}
        closeOnClick={closeOnClick}
        rtl={rtl}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className={className}
        theme={theme === "dark" ? Theme.DARK : Theme.LIGHT}
      />
    </>
  );
};
