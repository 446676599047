import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "../../../common/utils/createAppAsyncThunk";
import { appAction } from "../../../app/app-reducer";
import { avitoAccountApi, DeleteAvitoAccountReqType } from "./avito.account.api";
import { handleApiError } from "../../../common/utils/errorUtils";
import { authThunks } from "../../Auth/auth-reducer";

export type LoadingAndDisabledAvitoAcc = "addAndDeleteAvitoAcc";
export type LoadingAndDisabledState = {
  [K in LoadingAndDisabledAvitoAcc]: boolean;
};

export const slice = createSlice({
  name: "avito-account",
  initialState: {
    avitoAccount: {
      link: "" as string,
      access: true as boolean,
    },
    loadingAndDisabledAvitoAcc: {
      addAndDeleteAvitoAcc: false,
    } as LoadingAndDisabledState,
    pagination: {
      portionPage: 10,
      limit: 1,
      totalCount: 0 as number,
      currentPage: 0 as number,
    },
  },
  reducers: {
    setCurrentPage: (state, action: PayloadAction<{ currentPage: number }>) => {
      state.pagination.currentPage = action.payload.currentPage;
    },
    setTotalCount: (state, action: PayloadAction<{ totalCount: number }>) => {
      state.pagination.currentPage = action.payload.totalCount;
    },
    setLink: (state, action: PayloadAction<{ link: string }>) => {
      state.avitoAccount.link = action.payload.link;
    },
    setInLoadingAndDisabledAvitoAcc: (
      state,
      action: PayloadAction<{
        key: LoadingAndDisabledAvitoAcc;
        value: boolean;
      }>,
    ) => {
      state.loadingAndDisabledAvitoAcc[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(avitoAccountThunks.link.fulfilled, (state, action) => {
        state.avitoAccount.link = action.payload.location;
      })
      .addCase(authThunks.profile.fulfilled, (state, action) => {
        state.pagination.totalCount = action.payload.profile.avito_accounts.length;
        if (action.payload.profile.avito_accounts.length >= 1) {
          state.pagination.currentPage = 1;
        }
      })
      // .addCase(avitoAccountThunks.addVkAccount.fulfilled, (state) => {
      //     state.pagination.totalCount = state.pagination.totalCount + 1
      //     state.pagination.currentPage = state.pagination.currentPage + 1
      // })
      .addCase(avitoAccountThunks.deleteAvitoAcc.fulfilled, (state) => {
        state.pagination.totalCount = state.pagination.totalCount - 1;
        state.pagination.currentPage = state.pagination.currentPage = 1;
      });
  },
});

/** Получить ссылку для привязки Авито аккаунта */
const link = createAppAsyncThunk<
  { location: string },
  {
    token: string;
  }
>("avito-account/link", async (arg, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));
  dispatch(
    avitoAccountActions.setInLoadingAndDisabledAvitoAcc({
      key: "addAndDeleteAvitoAcc",
      value: true,
    }),
  );
  try {
    const res = await avitoAccountApi.link({ token: arg.token });

    return { location: res.data.location };
  } catch (e: unknown) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
    dispatch(
      avitoAccountActions.setInLoadingAndDisabledAvitoAcc({
        key: "addAndDeleteAvitoAcc",
        value: false,
      }),
    );
  }
});

const deleteAvitoAcc = createAppAsyncThunk<
  { messageDelete: string; id: number },
  DeleteAvitoAccountReqType
>("avito-account/deleteAvitoAccount", async (arg, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    dispatch(
      avitoAccountActions.setInLoadingAndDisabledAvitoAcc({
        key: "addAndDeleteAvitoAcc",
        value: true,
      }),
    );
    const res = await avitoAccountApi.deleteAvitoAccount({
      account_id: arg.account_id,
      is_confirmed: arg.is_confirmed,
      token: arg.token,
    });
    dispatch(appAction.setAlerts({ alerts: "Авито аккаунт успешно удален!" }));
    return { messageDelete: res.data, id: arg.account_id };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(
      avitoAccountActions.setInLoadingAndDisabledAvitoAcc({
        key: "addAndDeleteAvitoAcc",
        value: false,
      }),
    );
  }
});

export const avitoAccountReducer = slice.reducer;
export const avitoAccountActions = slice.actions;
export const avitoAccountThunks = { link, deleteAvitoAcc };
