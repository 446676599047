import React, { ForwardedRef, forwardRef, ReactNode } from "react";
import s from "./MiniMenu.module.scss";

type MiniMenuProps = {
  onClose?: (open: boolean) => void;
  id?: number | null;
  open: boolean;
  classNameWrapper?: string;
  inlineStyle?: React.CSSProperties;
  children?: ReactNode;
  title?: string;
  positionTriangle?:
    | "leftCenter"
    | "rightCenter"
    | "topCenter"
    | "BottomCenter"
    | "rightBottom"
    | "leftBottom";
};

export const MiniMenu = forwardRef((props: MiniMenuProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { classNameWrapper, open, inlineStyle, children, title, onClose, positionTriangle } = props;

  const onClickCloseMenu = () => {
    onClose?.(open);
  };

  const miniMenu = `${s.miniMenu} ${positionTriangle ? s[positionTriangle] : ""} ${classNameWrapper ? classNameWrapper : ""} `;
  const styleWrapper = { inlineStyle };
  return (
    <>
      {open && (
        <div
          onClick={onClickCloseMenu}
          ref={ref}
          className={miniMenu}
          style={styleWrapper as React.CSSProperties}
        >
          <div className={s.menu}>
            <div className={s.title}>{title}</div>
            {children}
          </div>
        </div>
      )}
    </>
  );
});
