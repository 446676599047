import React from "react";
import { useAppSelector, useDisabledChatBot } from "../../common/hooks/hooks";
import { ErrorComponent } from "../ErrorsComponent/ErrorComponent";

export const AccessChecker = () => {
  const { isDisabled, message } = useDisabledChatBot();
  const me = useAppSelector<boolean>((state) => state.authorization.me);

  return isDisabled && me ? (
    <ErrorComponent
      autoClose={false}
      closeOnClick={false}
      errorMessage={message}
      position={"bottom-right"}
    />
  ) : null;
};
