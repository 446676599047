import * as yup from "yup";

/** schema VK and Avito messageText Component */
const schemesMessageText = {
  text: yup
    .string()
    .matches(
      /^[A-Za-zА-Яа-я0-9\s\d\S]*$/,
      'Текст может содержать буквы, цифры и пробелы, а также знаки препинания: «.,!?:"@*#»',
    )
    .max(1000)
    .min(0),
};

/** Validations in the project */
export const validationSchemes = {
  api_key: yup
    .string()
    .required("поле обязательно для ввода")
    .max(500, "не более 500 символов")
    .min(1, "не менее 1 символа")
    .transform((value) => value.trim()),
  pause: yup
    .number()
    .required("поле не может быть пустым")
    .integer("Допустимы только цифры")
    .typeError("Допустимы только цифры")
    .min(1, "минимальная пауза: 1 мин.")
    .transform((value) => value * 60),
  nameAutoAnswer: yup
    .string()
    .required("введите имя автоответчика")
    .min(2, "введите не менее 2 символов")
    .max(15, "разрешено не более 15 символов")
    .matches(/^[a-zA-Zа-яА-Я0-9 ]+$/, "*символы запрещены")
    .trim(),
  auth: {
    email: yup
      .string()
      .required("Введите email")
      .email("Введите корректный email")
      .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, "Введите корректный email")
      .transform((value) => value.trim().toLowerCase()),
    password: yup
      .string()
      .required("Введите пароль")
      .min(6, "Введите от 6 до 16 символов")
      .max(16, "Введите от 6 до 16 символов")
      .matches(/^[^\sа-яА-Я]+$/, "Только латинские буквы"),
    confirmPassword: yup
      .string()
      .required("Повторите пароль")
      .oneOf([yup.ref("password")], "Пароли не совпадают"),
  },
  commands: {
    question: yup
      .string()
      .required("поле обязательно для ввода")
      .min(2, "минимум 2 символа")
      .max(20, "максимально 20 символов")
      .transform((value) => value.trim()),
    answer: yup
      .string()
      .required("поле обязательно для ввода")
      .min(2, "минимум 2 символа")
      .max(1000, "максимально 1000 символов")
      .transform((value) => value.trim()),
  },
  schemesMessageText: schemesMessageText,
};
