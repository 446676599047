import React, { ElementRef, forwardRef, useContext } from "react";
import s from "./Switch.module.scss";
import { useId } from "../../common/hooks/useId";
import clsx from "clsx";
import { Typography } from "../Typography/Typography";
import * as AccordionRadixComponent from "@radix-ui/react-accordion/dist";
import * as SwitchRadix from "@radix-ui/react-switch";
import DarkModeSharpIcon from "@mui/icons-material/DarkModeSharp";
import WbSunnySharpIcon from "@mui/icons-material/WbSunnySharp";
import { Theme, ThemeContext } from "../../common/hoc/ThemeProvider/ThemeContext";

type SizeType = "big" | "small" | "medium";
type ColorType = "darkTheme" | "lightTheme";
type SwitchProps = {
  themeMod?: boolean;
  id?: string;
  label?: string;
  classNameWrapper?: string;
  defaultChecked?: boolean;
  size?: SizeType;
  color?: ColorType;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
};

export const Switch = forwardRef<ElementRef<typeof AccordionRadixComponent.Root>, SwitchProps>(
  (props, ref) => {
    const {
      id,
      label,
      themeMod = false,
      checked,
      onCheckedChange,
      classNameWrapper,
      defaultChecked,
      size = "small",
      color,
    } = props;
    const classNames = {
      wrapper: clsx(s.wrapper, classNameWrapper),
      switch: clsx(s.switch, size && s[size], color && s[color], themeMod && s.themeMod),
      label: clsx(s.label),
      switchThumb: clsx(s.switchThumb, size && s[size], themeMod && s.themeMod),
      themeModDark: clsx(s.themeModDark),
      themeModLight: clsx(s.themeModLight),
    };
    const { theme } = useContext(ThemeContext);
    const switchId = useId(id, "textField");

    return (
      <div className={classNames.wrapper}>
        <Typography asChild className={classNames.label}>
          <label htmlFor={switchId}>{label}</label>
        </Typography>
        <SwitchRadix.Root
          checked={checked}
          onCheckedChange={onCheckedChange}
          defaultChecked={defaultChecked}
          className={classNames.switch}
          id={switchId}
        >
          <SwitchRadix.Thumb className={classNames.switchThumb}>
            {themeMod && theme === Theme.DARK ? (
              <DarkModeSharpIcon className={classNames.themeModDark} />
            ) : (
              <WbSunnySharpIcon className={classNames.themeModLight} />
            )}
          </SwitchRadix.Thumb>
        </SwitchRadix.Root>
      </div>
    );
  },
);
