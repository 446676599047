import React from "react";
import s from "./ApiKeyVkGif.module.scss";

type GifHelpMeProps = {
  src: string;
  alt: string;
};

export const GifHelpMe = (props: GifHelpMeProps) => {
  return <img className={s.apiKeyGif} src={props.src} alt={props.alt} loading="lazy" />;
};
