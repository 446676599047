import React from "react";
import s from "./SetNewPassword.module.scss";
import { AuthContainer } from "../AuthContainer/AuthContainer";
import clsx from "clsx";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import VpnKeySharpIcon from "@mui/icons-material/VpnKeySharp";
import { ButtonMiu } from "../../../components/Buttons/Button/ButtonMiu";
import { Typography } from "../../../components/Typography/Typography";
import { Link, useNavigate } from "react-router-dom";
import { validationSchemes } from "../../../common/validationSchemes/validationSchemes";
import { getTokenFromUrl } from "../../../common/utils/getTokenFromUrl";
import { useAppDispatch } from "../../../common/hooks/hooks";
import { authThunks } from "../auth-reducer";
import { PATCH } from "../../Pages";
import { ControlledTextField } from "../../../components/TextField/controlled-textField";

const schema = yup.object().shape({
  email: validationSchemes.auth.email,
  password: validationSchemes.auth.password,
  confirmPassword: validationSchemes.auth.confirmPassword,
});
type FormDataType = yup.InferType<typeof schema>;
export const SetNewPassword = () => {
  const navigate = useNavigate();

  const classNames = {
    root: clsx(s.setNewPasswordBlock),
    form: clsx(s.formBlock),
    textField: clsx(s.textField),
    button: clsx(s.btnSave),
    typography: clsx(s.typography),
    link: clsx(s.linkBlock),
    typographyLink: clsx(s.typographyLinkText),
    linkToLogin: clsx(s.linkToLogin),
  };
  const hashToken = getTokenFromUrl({ params: "token" });
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = (data: FormDataType) => {
    if (hashToken !== null) {
      dispatch(
        authThunks.setNewPass({
          email: data.email,
          password: data.password,
          hash_string: hashToken,
        }),
      )
        .unwrap()
        .then(() => {
          navigate(PATCH.AUTH.LOGIN);
        })
        .catch((e) => {
          console.warn(e);
        });
    }
    reset();
  };

  return (
    <div className={classNames.root}>
      <AuthContainer title={"Восстановить пароль"} iconHeader={<VpnKeySharpIcon />}>
        <form onSubmit={handleSubmit(onSubmit)} className={classNames.form}>
          <ControlledTextField
            fullwidth
            label={"Введите email"}
            placeholder={"Введите email"}
            control={control}
            name={"email"}
            error={errors.email && errors.email.message}
          />
          <ControlledTextField
            fullwidth
            label={"Введите пароль"}
            placeholder={"Введите пароль"}
            control={control}
            name={"password"}
            error={errors.password && errors.password.message}
            type={"password"}
          />
          <ControlledTextField
            fullwidth
            label={"Повторите пароль"}
            placeholder={"Повторите пароль"}
            control={control}
            name={"confirmPassword"}
            error={errors.confirmPassword && errors.confirmPassword.message}
            type={"password"}
          />
          <ButtonMiu className={classNames.button} type={"submit"} title={"Сохранить"} />
        </form>
        <div className={classNames.link}>
          <Typography className={classNames.typography} variant={"Body2"}>
            Войти в аккаунт
          </Typography>
          <Link className={classNames.linkToLogin} to={PATCH.AUTH.LOGIN}>
            Войти
          </Link>
        </div>
      </AuthContainer>
    </div>
  );
};
