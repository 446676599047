import React, { ElementRef, forwardRef } from "react";
import * as AccordionRadixComponent from "@radix-ui/react-accordion";
import clsx from "clsx";
import s from "./AccordionItem.module.scss";
import { AccordionTrigger } from "./AccordionTrigger/AccordionTrigger";
import { AccordionContent } from "./AccordionContent/AccordionContent";

type AccordionItemProps = {
  value: string;
  triggerTitle: string;
  content: string;
};

export const AccordionItem = forwardRef<
  ElementRef<typeof AccordionRadixComponent.Item>,
  AccordionItemProps
>((props, ref) => {
  const { value, triggerTitle, content } = props;
  const classNames = {
    accordionItem: clsx(s.accordionItem),
  };

  return (
    <>
      <AccordionRadixComponent.Item className={classNames.accordionItem} value={value} ref={ref}>
        <AccordionTrigger>{triggerTitle}</AccordionTrigger>
        <AccordionContent>{content}</AccordionContent>
      </AccordionRadixComponent.Item>
    </>
  );
});
