import { instance } from "../../common/api/api";

type SubscriptionApiEndpointsType = {
  subscriptionGet: "/subscription/get";
};

const subscriptionApiEndpoints = {
  subscriptionGet: "/subscription/get",
} as SubscriptionApiEndpointsType;

export const subscriptionApi = {
  subscriptionGetGet: (arg: SubscriptionGetType) => {
    return instance.get<SubscriptionGetResponse>(subscriptionApiEndpoints.subscriptionGet, {
      params: {
        days: arg.days,
      },
      headers: { Authorization: `Bearer ${arg.token}` },
    });
  },
};

export type SubscriptionGetType = {
  days: DaySubscription;
  token: string;
};
export type DaySubscription = 30 | 90 | 180 | 1; // 1 this is debug

export type SubscriptionGetResponse = {
  msg: string | null;
  link: string | null;
  plan: number | null;
};
