import { ComponentPropsWithoutRef, ElementRef, ReactElement, forwardRef } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import * as SelectRadix from "@radix-ui/react-select";
import { clsx } from "clsx";
import s from "./Select.module.scss";
import { Typography, VariantType } from "../Typography/Typography";
import { useId } from "../../common/hooks/useId";
import { SelectItemProps } from "./SelectItem";
import { Loader } from "../Loadings/Loader/Loader";

type SelectProps = {
  classNameContent?: string;
  classNameTrigger?: string;
  disabled?: boolean;
  id?: string;
  variantLabel?: VariantType;
  align?: "start" | "center" | "end";
  label?: string;
  placeholder?: string;
  position?: "item-aligned" | "popper";
  side?: "bottom" | "left" | "right" | "top";
  children: ReactElement<SelectItemProps>[] | ReactElement<SelectItemProps>;
  maxHeight?: "fourtyVh";
  scrollBtn?: boolean;
  loading?: boolean;
} & Omit<ComponentPropsWithoutRef<typeof SelectRadix.Root>, "asChild">;

export const Select = forwardRef<ElementRef<typeof SelectRadix.Root>, SelectProps>(
  (props, ref): ReactElement => {
    const {
      classNameContent,
      classNameTrigger,
      disabled,
      id,
      loading,
      scrollBtn = true,
      variantLabel = "Caption",
      children,
      label,
      align = "start",
      placeholder,
      position = "popper",
      side = "bottom",
      maxHeight,
      ...restProps
    } = props;
    const selectId = useId(id, "select");
    const classNames = {
      content: clsx(s.content, classNameContent, maxHeight && s[maxHeight]),
      items: clsx(s.items),
      label: clsx(s.label, disabled && s.disabled),
      selectScrollButton: clsx(s.selectScrollButton),
      trigger: clsx(s.trigger, classNameTrigger && classNameTrigger),
      triggerIcon: clsx(s.triggerIcon, disabled && s.disabled, loading && s.loading),
      wrapper: clsx(s.wrapper),
      spinner: clsx(s.spinner),
    };

    return (
      <>
        <div className={classNames.wrapper}>
          {label && (
            <Typography asChild variant={variantLabel} className={classNames.label}>
              <label htmlFor={selectId}>{label}</label>
            </Typography>
          )}
          <SelectRadix.Root {...restProps}>
            <SelectRadix.Trigger
              className={classNames.trigger}
              disabled={disabled}
              id={selectId}
              ref={ref}
            >
              <Typography asChild>
                <SelectRadix.Value placeholder={placeholder} />
              </Typography>
              {loading && <Loader className={classNames.spinner} />}
              <SelectRadix.Icon className={classNames.triggerIcon}>
                <ChevronDownIcon />
              </SelectRadix.Icon>
            </SelectRadix.Trigger>

            <SelectRadix.Portal>
              <SelectRadix.Content
                align={align}
                className={classNames.content}
                position={position}
                side={side}
                sideOffset={-6}
              >
                {scrollBtn && (
                  <SelectRadix.ScrollUpButton className={classNames.selectScrollButton}>
                    <ChevronUpIcon />
                  </SelectRadix.ScrollUpButton>
                )}
                <SelectRadix.Viewport>
                  {children}
                  <SelectRadix.Separator />
                </SelectRadix.Viewport>
                {scrollBtn && (
                  <SelectRadix.ScrollDownButton className={classNames.selectScrollButton}>
                    <ChevronDownIcon />
                  </SelectRadix.ScrollDownButton>
                )}
                <SelectRadix.Arrow />
              </SelectRadix.Content>
            </SelectRadix.Portal>
          </SelectRadix.Root>
        </div>
      </>
    );
  },
);
