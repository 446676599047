import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "../../common/utils/createAppAsyncThunk";
import {
  authApi,
  ChangePass,
  confirmRegResponse,
  LoginResType,
  ProfileResType,
  RegisterReqType,
  RegisterResType,
  SetNewPassword,
  StatusRegister,
} from "./auth.api";
import { appAction } from "../../app/app-reducer";
import { errorTranslations, handleApiError } from "../../common/utils/errorUtils";
import { setLocalStorageItemWithExpiry } from "../../common/utils/localStorageUtils";
import { avitoAccountThunks } from "../Account/AvitoAccount/avitoAccountReducer";
import { vkAccountThunks } from "../Account/VkAccount/vkAccountReducer";

interface AxiosErrorResponse {
  response: {
    data: {
      detail: string;
    };
    status: number;
  };
}

interface AxiosErrorDetail {
  status: StatusRegister;
  msg: string;
}

type AxiosErrorResponseToken = {
  response?: {
    data: {
      detail: AxiosErrorDetail;
    };
    status: number;
  };
};

const slice = createSlice({
  name: "auth",
  initialState: {
    registration: {} as RegisterResType,
    email: "",
    login: {
      access_token: "",
      token_type: "Bearer",
      expires_in: 0,
    },
    profile: {} as ProfileResType,
    me: false,
  },
  reducers: {
    me: (state, action: PayloadAction<{ me: boolean }>) => {
      state.me = action.payload.me;
    },
    logout: (state, action: PayloadAction<{ me: boolean }>) => {
      state.me = false;
      state.login.access_token = "";
    },
    getToken: (state, action: PayloadAction<{ login: { access_token: string } }>) => {
      state.login.access_token = action.payload.login.access_token;
    },
    setStatusReg: (state, action: PayloadAction<{ status: StatusRegister; msg: string }>) => {
      state.registration.status = action.payload.status;
      state.registration.msg = action.payload.msg;
    },
    setEmail: (state, action: PayloadAction<{ email: string }>) => {
      state.email = action.payload.email;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authThunks.registration.fulfilled, (state, action) => {
        state.registration = action.payload.reg;
        state.email = action.payload.email;
      })
      .addCase(authThunks.login.fulfilled, (state, action) => {
        state.login = action.payload.login;
        state.email = action.payload.email;
        setLocalStorageItemWithExpiry("tokenChatBot", state.login.access_token);
      })
      .addCase(authThunks.profile.fulfilled, (state, action) => {
        state.profile = action.payload.profile;
      })
      .addCase(avitoAccountThunks.deleteAvitoAcc.fulfilled, (state, action) => {
        const index = state.profile.avito_accounts.findIndex(
          (avitoAcc) => avitoAcc.id === action.payload.id,
        );
        if (index !== -1) {
          state.profile.avito_accounts.splice(index, 1);
        }
      })
      .addCase(vkAccountThunks.deleteVkAcc.fulfilled, (state, action) => {
        const index = state.profile.vk_accounts.findIndex(
          (vkAcc) => vkAcc.id === action.payload.id,
        );
        if (index !== -1) {
          state.profile.vk_accounts.splice(index, 1);
        }
      })
      .addCase(authThunks.confirmReg.fulfilled, (state, action) => {
        const user = action.payload.user;
        state.profile.has_access = user.has_access;
      });
  },
});

const registration = createAppAsyncThunk<{ reg: RegisterResType; email: string }, RegisterReqType>(
  "auth/registration",
  async (arg, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    dispatch(appAction.setIsLoading({ isLocalLoading: true }));

    try {
      // debugger
      const newArg = { email: arg.email, password: arg.password };
      const res = await authApi.register(newArg);

      return { reg: res.data, email: arg.email };
    } catch (e) {
      if (e instanceof Error && "response" in e) {
        const axiosError = e as AxiosErrorResponse;
        if (axiosError.response) {
          let errorMessage = axiosError.response.data.detail;
          const sliceErrorMsg = errorMessage.slice(-36);
          if (sliceErrorMsg === "is already awaiting for confirmation") {
            const errorMessageEmail = `${errorMessage.split(" ")[0]} ${errorTranslations[sliceErrorMsg]}`;
            dispatch(appAction.setError({ error: errorMessageEmail }));
          } else {
            handleApiError(e, dispatch);
          }
        }
      } else {
        console.warn("Contact technical support", e);
      }
      return rejectWithValue(null);
    } finally {
      dispatch(appAction.setIsLoading({ isLocalLoading: false }));
    }
  },
);
const login = createAppAsyncThunk<
  {
    login: LoginResType;
    email: string;
  },
  RegisterReqType
>("auth/login", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));

  try {
    const res = await authApi.login(arg);
    dispatch(authActions.me({ me: true }));
    dispatch(authThunks.profile({ token: res.data.access_token }));
    return { login: res.data, email: arg.email };
  } catch (e: unknown) {
    if (typeof e === "object" && e !== null && "response" in e) {
      const error = e as AxiosErrorResponseToken;
      if (
        error.response &&
        error.response.data.detail &&
        typeof error.response.data.detail === "object"
      ) {
        const detail = error.response.data.detail;
        if (error.response.status === 400) {
          dispatch(authActions.setStatusReg({ status: detail.status, msg: detail.msg }));
          dispatch(authActions.setEmail({ email: arg.email }));
        }
      } else {
        handleApiError(e, dispatch);
      }
    } else {
      handleApiError(e, dispatch);
    }
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});
const profile = createAppAsyncThunk<{ profile: ProfileResType }, { token: string }>(
  "auth/profile",
  async (arg, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    dispatch(appAction.setIsLoading({ isLocalLoading: true }));
    try {
      const res = await authApi.profile({ token: arg.token });
      dispatch(authActions.me({ me: true }));
      // return {profile: res.data}

      /** для тестирования */
      const newRes = {
        ...res.data,
        has_access: true,
        has_used_demo: null,
        access_expiry_moment: "2025-06-29T12:48:17.185Z",
        // access_expiry_moment: null,
        // access_expiry_moment: null,
        // access_expiry_moment: null,
      };
      return { profile: newRes };
      /** для тестирования */
    } catch (e) {
      handleApiError(e, dispatch);
      dispatch(authActions.me({ me: false }));
      return rejectWithValue(null);
    } finally {
      dispatch(appAction.setIsLoading({ isLocalLoading: false }));
    }
  },
);
const changePass = createAppAsyncThunk<{ msg: string }, ChangePass>(
  "auth/changePass",
  async (arg, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    dispatch(appAction.setIsLoading({ isLocalLoading: true }));
    try {
      const res = await authApi.changePass({ new_password: arg.new_password, token: arg.token });
      dispatch(appAction.setAlerts({ alerts: "Пароль успешно обновлен." }));

      return { msg: res.data.msg };
    } catch (e) {
      handleApiError(e, dispatch);
      return rejectWithValue(null);
    } finally {
      dispatch(appAction.setIsLoading({ isLocalLoading: false }));
    }
  },
);
const resetPass = createAppAsyncThunk<{ msg: string }, { email: string }>(
  "auth/resetPass",
  async (arg, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    dispatch(appAction.setIsLoading({ isLocalLoading: true }));
    try {
      const res = await authApi.resetPass(arg.email);
      dispatch(
        appAction.setAlerts({
          alerts: `Для восстановления пароля перейдите на почту: ${arg.email}.`,
        }),
      );
      return { msg: res.data.msg };
    } catch (e) {
      handleApiError(e, dispatch);
      return rejectWithValue(null);
    } finally {
      dispatch(appAction.setIsLoading({ isLocalLoading: false }));
    }
  },
);

const setNewPass = createAppAsyncThunk<string, SetNewPassword>(
  "auth/setNewPass",
  async (arg, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;

    try {
      const res = await authApi.setNewPass({
        email: arg.email,
        password: arg.password,
        hash_string: arg.hash_string,
      });
      dispatch(appAction.setAlerts({ alerts: "Пароль успешно изменен! Войдите в аккаунт." }));
      return res.data;
    } catch (e) {
      handleApiError(e, dispatch);
      return rejectWithValue(null);
    }
  },
);

//* 1 lvl confirm email
const reconfirmReg = createAppAsyncThunk<
  {
    msg: string;
  },
  { email: string }
>("auth/reconfirmReg", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));
  try {
    const res = await authApi.reconfirmReg({ email: arg.email });
    dispatch(
      appAction.setAlerts({
        alerts: `${errorTranslations[res.data.msg]} на электронный адресс ${arg.email}`,
      }),
    );
    return { msg: res.data };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});
//* 2 lvl confirm email
const confirmReg = createAppAsyncThunk<
  { user: confirmRegResponse },
  {
    token: string;
  }
>("auth/confirmReg", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  try {
    const res = await authApi.confirmReg({ token: arg.token });

    return { user: res.data };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  }
});

const requestDemo = createAppAsyncThunk<
  { msg: string },
  {
    token: string;
  }
>("auth/requestDemo", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  const token = getState().authorization.login.access_token;
  try {
    const res = await authApi.requestDemo({ token: arg.token });
    dispatch(authThunks.profile({ token }));
    dispatch(appAction.setAlerts({ alerts: "Демо режим активирован" }));
    return { msg: res.data };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  }
});

export const authReducer = slice.reducer;
export const authActions = slice.actions;
export const authThunks = {
  registration,
  login,
  profile,
  changePass,
  resetPass,
  setNewPass,
  confirmReg,
  requestDemo,
  reconfirmReg,
};
