export enum LinkVkApiKey {
  linkInstruction = "https://dev.vk.com/ru/api/community-messages/getting-started#%D0%9F%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%BA%D0%BB%D1%8E%D1%87%D0%B0%20%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%B0%20%D0%B2%20%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B0%D1%85%20%D1%81%D0%BE%D0%BE%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%B0",
}

export enum VkAccountEnum {
  statusWebhookNull = "Ошибка вебхука (Callback API), попробуйте перепривязать аккаунт. Если ошибка повторится - обратитесь в техподдержку.",
  statusWebhookFalse = "Необходимо подтвердить вебхук (Callback API) в настройках сообщества, нажмите на эту инонку для получения инструкции.",
  accountReconnect = "Аккаунт можно переподключить, например, если вы случайно удалили Callback Сервер в настройках сообщества, либо же заменили API ключ. Все настройки автоответчиков сохранятся.",
}

export enum TooltipEnum {
  pause = "Минимальный интервал между автоматичесĸими ответами",
  timeInterval = "Бот будет отвечать в рамĸах установленного графиĸа",
  firstMessages = "Бот ответит один раз на первое сообщение в чате",
  sendsListCommands = "Отправлять пользователю списоĸ доступных ĸоманд чата",
}
