import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "../../../common/utils/createAppAsyncThunk";
import {
  GetAutoAnswerResponseType,
  avitoChatBotApi,
  UpdateAutoAnswerResDomainType,
  UpdateAutoAnswerReqType,
  UpdateAutoAnswerResDomainAPIType,
  AddedAutoAnswerObject,
  UpdateAutoAnswerReqAdsListType,
} from "./avito.chatBot.api";
import { appAction } from "../../../app/app-reducer";
import { handleApiError } from "../../../common/utils/errorUtils";
import { authThunks } from "../../Auth/auth-reducer";
import { avitoAccountThunks } from "../../Account/AvitoAccount/avitoAccountReducer";
import { avitoCommandThunk } from "./AvitoAnswer/AvitoCommand/avitoCommandReducer";

export type KeyDisabledAndLoading =
  | "name"
  | "ads_list_Mode"
  | "pause"
  | "auto_answers_setting"
  | "time_offset_from_utc"
  | "send_commands_list";
export type LoadingAndDisabledState = {
  [K in KeyDisabledAndLoading]: boolean;
};

export type AvitoAnswers = {
  [key: string]: Array<GetAutoAnswerResponseType>;
};
export type AvitoAnswersPagination = {
  [key: string]: { totalCount: number; currentPage: number };
};

const initialState = {
  avitoChatBot: {
    avitoAnswer: {} as AvitoAnswers,
    avitoAnswerPagination: {} as AvitoAnswersPagination,
    commandPreview: "" as string,
    loadingAndDisabledSystem: {
      basic_settings: {
        name: false,
        ads_list_Mode: false,
        auto_answers_setting: false,
        time_offset_from_utc: false,
      } as LoadingAndDisabledState,
      avito_command: {
        send_commands_list: false,
      } as LoadingAndDisabledState,
    },

    limit: 1 as number, //лимит на 1 странице (не менять!)
    totalCount: 0 as number,
    currentPage: 1 as number,
    portionPage: 10 as number,
  },
};
const slice = createSlice({
  name: "avitoChatBot",
  initialState,
  reducers: {
    setCurrentPageNew(state, action: PayloadAction<{ accountId: string; currentPage: number }>) {
      const { accountId, currentPage } = action.payload;
      if (state.avitoChatBot.avitoAnswerPagination[accountId]) {
        state.avitoChatBot.avitoAnswerPagination[accountId].currentPage = currentPage;
      }
    },
    setTotalCount(state, action: PayloadAction<{ accountId: string; totalCount: number }>) {
      const { accountId, totalCount } = action.payload;
      if (state.avitoChatBot.avitoAnswerPagination[accountId]) {
        state.avitoChatBot.avitoAnswerPagination[accountId].totalCount = totalCount;
      }
    },
    setLoadingAndDisabledSystem(
      state,
      action: PayloadAction<{ key: KeyDisabledAndLoading; value: boolean }>,
    ) {
      state.avitoChatBot.loadingAndDisabledSystem.basic_settings[action.payload.key] =
        action.payload.value;
      state.avitoChatBot.loadingAndDisabledSystem.avito_command[action.payload.key] =
        action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authThunks.profile.fulfilled, (state, action) => {
        action.payload.profile.avito_accounts.forEach((avitoAcc) => {
          const avitoAccounts = avitoAcc.id;
          state.avitoChatBot.avitoAnswer[avitoAccounts] = [];
          state.avitoChatBot.avitoAnswerPagination[avitoAccounts] = {
            currentPage: 0,
            totalCount: 0,
          };
        });
      })
      .addCase(thunkAvitoChatBot.getAutoAnswer.fulfilled, (state, action) => {
        const avitoAnswers = action.payload.avitoAnswer;
        avitoAnswers.forEach((avitoAnswerArray) => {
          avitoAnswerArray.forEach((answer) => {
            const accountId = answer.account_id;
            if (state.avitoChatBot.avitoAnswer[accountId]) {
              state.avitoChatBot.avitoAnswer[accountId] = [...avitoAnswerArray];
              state.avitoChatBot.avitoAnswerPagination[accountId] = {
                totalCount: avitoAnswerArray.length,
                currentPage: 1,
              };
            }
          });
        });
      })
      .addCase(thunkAvitoChatBot.updateAutoAnswer.fulfilled, (state, action) => {
        const avitoAccountId = action.payload.autoAnswer.account_id;
        const answerId = action.payload.autoAnswer.id;
        const autoAnswer = action.payload.autoAnswer;

        if (avitoAccountId === undefined) return;
        const autoAnswers = state.avitoChatBot.avitoAnswer[avitoAccountId];

        const index = autoAnswers.findIndex((el) => el.id === answerId);
        if (index !== -1) {
          autoAnswers[index] = { ...autoAnswers[index], ...autoAnswer };
        }
      })
      .addCase(thunkAvitoChatBot.addedAdsList.fulfilled, (state, action) => {
        const avitoAccountId = action.payload.autoAnswer.account_id;
        const answerId = action.payload.autoAnswer.id;
        const autoAnswer = action.payload.autoAnswer;

        if (avitoAccountId === undefined) return;
        const autoAnswers = state.avitoChatBot.avitoAnswer[avitoAccountId];

        const index = autoAnswers.findIndex((el) => el.id === answerId);
        if (index !== -1) {
          autoAnswers[index] = { ...autoAnswers[index], ...autoAnswer };
        }
      })
      .addCase(thunkAvitoChatBot.removedAdsList.fulfilled, (state, action) => {
        const avitoAccountId = action.payload.autoAnswer.account_id;
        const answerId = action.payload.autoAnswer.id;
        const autoAnswer = action.payload.autoAnswer;

        if (avitoAccountId === undefined) return;
        const autoAnswers = state.avitoChatBot.avitoAnswer[avitoAccountId];

        const index = autoAnswers.findIndex((el) => el.id === answerId);
        if (index !== -1) {
          autoAnswers[index] = { ...autoAnswers[index], ...autoAnswer };
        }
      })
      .addCase(thunkAvitoChatBot.addedAvitoAnswer.fulfilled, (state, action) => {
        const vkAccountId = action.payload.autoAnswer.account_id;
        const vkAnswer = action.payload.autoAnswer;
        state.avitoChatBot.avitoAnswer[vkAccountId].push(vkAnswer);
      })
      .addCase(thunkAvitoChatBot.deleteAutoAnswer.fulfilled, (state, action) => {
        const accountId = action.payload.avitoAccId;
        const answerId = action.payload.id;

        const index = state.avitoChatBot.avitoAnswer[accountId].findIndex(
          (autoAnswer) => autoAnswer.id === answerId,
        );
        if (index !== -1) {
          state.avitoChatBot.avitoAnswer[accountId].splice(index, 1);
        }
      })
      .addCase(avitoAccountThunks.deleteAvitoAcc.fulfilled, (state, action) => {
        //Зачистка стейта avitoAnswers после удаления авито аккаунта
        delete state.avitoChatBot.avitoAnswer[action.payload.id];
        delete state.avitoChatBot.avitoAnswerPagination[action.payload.id];
      })
      .addCase(thunkAvitoChatBot.commandPreview.fulfilled, (state, action) => {
        state.avitoChatBot.commandPreview = action.payload.previewValue;
      });
  },
});

const getAutoAnswer = createAppAsyncThunk<
  { avitoAnswer: GetAutoAnswerResponseType[][] },
  { account_ids: number[] }
>("avitoChatBot/getAutoAnswer", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));
  const token = getState().authorization.login.access_token;
  try {
    const promises = arg.account_ids.map((account_id) =>
      avitoChatBotApi.getAutoAnswer({ token, account_id: account_id }),
    );
    const responses = await Promise.all(promises);
    const avitoAnswers = responses.map((res) => res.data);

    /** Сущность получения комманд vkCommand */
    avitoAnswers.forEach((answers) => {
      answers.forEach((answer) => {
        const vkAnswerId = answer.id;
        dispatch(avitoCommandThunk.getAvitoCommand({ answer_id: vkAnswerId, token })); //Получение комманд
      });
    });

    return { avitoAnswer: avitoAnswers };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const updateAutoAnswer = createAppAsyncThunk<
  { autoAnswer: UpdateAutoAnswerResDomainType },
  UpdateAutoAnswerReqType
>("avitoChatBot/updateAutoAnswer", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));

  const state = getState();
  const autoAnswer = state.avitoChatBot.avitoChatBot.avitoAnswer[arg.avitoAccId].find(
    (el) => el.id === arg.id,
  );
  if (!autoAnswer) {
    console.warn("autoAnswer not found in the state");
    return rejectWithValue(null);
  }

  const apiModel: UpdateAutoAnswerResDomainAPIType = {
    ...autoAnswer,
    ...arg.domainModel,
  };
  try {
    const res = await avitoChatBotApi.updateAutoAnswer({
      avitoAccId: arg.avitoAccId,
      id: arg.id,
      token: arg.token,
      domainModel: apiModel,
    });
    return { autoAnswer: res.data };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const addedAdsList = createAppAsyncThunk<
  {
    autoAnswer: UpdateAutoAnswerResDomainType;
  },
  UpdateAutoAnswerReqAdsListType
>("avitoChatBot/addedAdsList", async (arg, thunkAPI) => {
  const { dispatch, getState, rejectWithValue } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));

  const state = getState();
  const autoAnswer = state.avitoChatBot.avitoChatBot.avitoAnswer[arg.avitoAccId].find(
    (el) => el.id === arg.id,
  );
  if (!autoAnswer) {
    console.warn("autoAnswer not found in the state");
    return rejectWithValue(null);
  }

  const newAdsList = [...autoAnswer.ads_list, arg.ads_list_str];
  const apiModel: UpdateAutoAnswerResDomainAPIType = {
    ...autoAnswer,
    ads_list: newAdsList as string[],
  };
  try {
    const res = await avitoChatBotApi.updateAutoAnswer({
      avitoAccId: arg.avitoAccId,
      id: arg.id,
      token: arg.token,
      domainModel: apiModel,
    });
    return { autoAnswer: res.data };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const removedAdsList = createAppAsyncThunk<
  {
    autoAnswer: UpdateAutoAnswerResDomainType;
  },
  UpdateAutoAnswerReqAdsListType
>("avitoChatBot/removedAdsList", async (arg, thunkAPI) => {
  const { dispatch, getState, rejectWithValue } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));
  const state = getState();

  const autoAnswer = state.avitoChatBot.avitoChatBot.avitoAnswer[arg.avitoAccId].find(
    (el) => el.id === arg.id,
  );
  if (!autoAnswer) {
    console.warn("autoAnswer not found in the state");
    return rejectWithValue(null);
  }

  const indexToRemove = autoAnswer.ads_list.indexOf(arg.ads_list_str);

  if (indexToRemove === -1) {
    console.warn("Элемент для удаления не найден в ads_list");
    return rejectWithValue(null);
  }
  const newAdsList = [...autoAnswer.ads_list];
  newAdsList.splice(indexToRemove, 1);

  const apiModel: UpdateAutoAnswerResDomainAPIType = {
    ...autoAnswer,
    ads_list: newAdsList,
  };
  try {
    const res = await avitoChatBotApi.updateAutoAnswer({
      avitoAccId: arg.avitoAccId,
      id: arg.id,
      token: arg.token,
      domainModel: apiModel,
    });
    return { autoAnswer: res.data };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const addedAvitoAnswer = createAppAsyncThunk<
  { autoAnswer: GetAutoAnswerResponseType },
  {
    avitoAccId: number;
    name: string;
  }
>("avitoChatBot/addedAvitoAnswer", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));

  const token = getState().authorization.login.access_token;

  const idAvitoAccount = arg.avitoAccId;
  const name = arg.name;

  const model = {
    name: name,
    account_id: idAvitoAccount,
    text: "",
    ads_list: [],
    ads_list_mode: "ALL",
    message_pause_seconds: 86400,
    auto_answers_setting: "OFF",
    send_commands_list: false,
    time_intervals: {
      "0": {
        end: "23:59",
        start: "00:00",
        next_day_end: false,
        is_active: false,
      },
      "1": {
        end: "23:59",
        start: "00:00",
        next_day_end: false,
        is_active: false,
      },
      "2": {
        end: "23:59",
        start: "00:00",
        next_day_end: false,
        is_active: false,
      },
      "3": {
        end: "23:59",
        start: "00:00",
        next_day_end: false,
        is_active: false,
      },
      "4": {
        end: "23:59",
        start: "00:00",
        next_day_end: false,
        is_active: false,
      },
      "5": {
        end: "23:59",
        start: "00:00",
        next_day_end: false,
        is_active: false,
      },
      "6": {
        end: "23:59",
        start: "00:00",
        next_day_end: false,
        is_active: false,
      },
    },
    time_offset_from_utc: 3,
    commands_status: false,
    commands_list: [],
    commands_description: "",
  } as AddedAutoAnswerObject;
  try {
    const res = await avitoChatBotApi.addAutoAnswer({ token: token, domainModel: model });

    const totalCount =
      getState().avitoChatBot.avitoChatBot.avitoAnswerPagination[idAvitoAccount].totalCount + 1;
    const setCurrentPage =
      getState().avitoChatBot.avitoChatBot.avitoAnswerPagination[idAvitoAccount].currentPage + 1;

    dispatch(
      avitoChatBotAction.setTotalCount({
        accountId: idAvitoAccount.toString(),
        totalCount: totalCount,
      }),
    );
    dispatch(
      avitoChatBotAction.setCurrentPageNew({
        accountId: idAvitoAccount.toString(),
        currentPage: setCurrentPage,
      }),
    );
    return { autoAnswer: res.data };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const deleteAutoAnswer = createAppAsyncThunk<
  { avitoAccId: string; id: number },
  { avitoAccId: string; id: number }
>("avitoChatBot/deleteAvitoAnswer", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));
  const token = getState().authorization.login.access_token;

  try {
    await avitoChatBotApi.deleteAutoAnswer({ id: arg.id, token: token });

    /** PAGINATION */
    const totalCount =
      getState().avitoChatBot.avitoChatBot.avitoAnswerPagination[arg.avitoAccId].totalCount;

    const resultTotalCount = Math.ceil(totalCount - 1);
    const resultCurrentPage = Math.ceil(1);
    /** PAGINATION */
    dispatch(
      avitoChatBotAction.setTotalCount({ accountId: arg.avitoAccId, totalCount: resultTotalCount }),
    );
    dispatch(
      avitoChatBotAction.setCurrentPageNew({
        accountId: arg.avitoAccId,
        currentPage: resultCurrentPage,
      }),
    );

    dispatch(appAction.setAlerts({ alerts: "Автоответчик удален" }));
    return { avitoAccId: arg.avitoAccId, id: arg.id };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const commandPreview = createAppAsyncThunk<
  { previewValue: string },
  {
    id: number;
    token: string;
  }
>("avitoChatBot/commandPreview", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));
  try {
    const res = await avitoChatBotApi.commandPreview({ id: arg.id, token: arg.token });

    return { previewValue: res.data };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});
export const avitoChatBotReducer = slice.reducer;
export const avitoChatBotAction = slice.actions;
export const thunkAvitoChatBot = {
  getAutoAnswer,
  updateAutoAnswer,
  addedAdsList,
  removedAdsList,
  addedAvitoAnswer,
  deleteAutoAnswer,
  commandPreview,
};
