import React, { ChangeEvent, useCallback, useState } from "react";
import s from "./VkShedule.module.scss";
import { Block } from "components/Layout/Block/Block";
import { clsx } from "clsx";
import { Typography } from "components/Typography/Typography";
import { Checkbox, Switch } from "@mui/material";
import { TooltipMiu } from "components/TooltipMiu/TooltipMiu";
import { useAppSelector } from "common/hooks/hooks";
import { DialogRadix } from "components/Dialog/DialogRadix/DialogRadix";
import {
  AutoAnswersSettingVkType,
  TimeIntervalItemType,
  TimeIntervals,
} from "../../vk.chatBot.api";
import { Select } from "components/Select";
import { SelectItem } from "components/Select/SelectItem";

type VkScheduleProps = {
  accountId: number;
  id: number;
  answer: AutoAnswersSettingVkType;
  timeIntervals: TimeIntervals;
  updateTimeIntervals: (accountId: number, id: number, timeInterval: TimeIntervals) => void;
};
const dayOfWeekLabels: Record<string, string> = {
  "0": "Пн",
  "1": "Вт",
  "2": "Ср",
  "3": "Чт",
  "4": "Пт",
  "5": "Сб",
  "6": "Вс",
};
const dayOfWeekLabelsModal: Record<string, string> = {
  "0": "Понедельник",
  "1": "Вторник",
  "2": "Среду",
  "3": "Четверг",
  "4": "Пятницу",
  "5": "Субботу",
  "6": "Воскресенье",
};

const VkSchedule: React.FC<VkScheduleProps> = (props) => {
  const { timeIntervals, updateTimeIntervals, answer, accountId, id } = props;
  const [openDialogEditTime, setOpenDialogEditTime] = React.useState<string | null>(null);
  const [schedule, setSchedule] = React.useState<TimeIntervals>({ ...timeIntervals });
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [disabledCheckBoxTime, setDisabledCheckBoxTime] = useState<boolean>(false);
  const isLoading = useAppSelector<boolean>((state) => state.app.isLocalLoading);
  const [disabledSwitch, setDisabledSwitch] = useState<string | null>(null);

  const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, "0"));
  const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, "0"));

  const classNames = {
    tooltipShedule: clsx(s.tooltipShedule),
    children: clsx(s.children),
    shedule: clsx(s.shedule),
    headerText: clsx(s.headerText),
    dialogShedule: clsx(s.dialogShedule),
    scheduleHeader: clsx(s.scheduleHeader),
    switch: clsx(s.switch),
    timeBlock: (dayOfWeek: string) =>
      clsx(s.timeBlock, {
        [s.blockDisabled]: !schedule[dayOfWeek]?.is_active || answer === "FIRST_MESSAGE",
      }),
    time: clsx(s.time),
    timeDelimiter: clsx(s.timeDilimiter),
    dialogInfoBlock: clsx(s.infoBlock),
    textField: clsx(s.textField),
    checkboxBlock: clsx(s.checkBoxBlock),
    checkBoxItem: clsx(s.checkBoxItem),
    selects: clsx(s.selects),
    titleSelect: clsx(s.titleSelect),
    nextDayCheck: (dayOfWeek: string) =>
      clsx(s.nextDayCheck, {
        [s.nextDayCheckDisabled]: answer === "FIRST_MESSAGE" || !schedule[dayOfWeek]?.is_active,
      }),
    circleOn: clsx(s.circleOn),
    circleOff: clsx(s.circleOff),
    spanNextDay: clsx(s.spanNextDay),
  };

  const handleTimeChange = useCallback(
    (dayOfWeek: string, field: keyof TimeIntervalItemType, value: string) => {
      setIsSaveButtonDisabled(false);
      const newSchedule = {
        ...schedule,
        [dayOfWeek]: {
          ...schedule[dayOfWeek],
          [field]: value,
        },
      };
      if (newSchedule[dayOfWeek].start > newSchedule[dayOfWeek].end) {
        newSchedule[dayOfWeek].next_day_end = true;
      } else if (newSchedule[dayOfWeek].end < newSchedule[dayOfWeek].start) {
        newSchedule[dayOfWeek].next_day_end = true;
      } else if (newSchedule[dayOfWeek].end === newSchedule[dayOfWeek].start) {
        newSchedule[dayOfWeek].next_day_end = true;
      } else if (newSchedule[dayOfWeek].start === newSchedule[dayOfWeek].end) {
        newSchedule[dayOfWeek].next_day_end = true;
      }
      setSchedule(newSchedule);
    },
    [schedule],
  );

  const handleCheckboxChange = (dayOfWeek: string) => {
    const updatedNextDayEnd = !schedule[dayOfWeek].next_day_end;
    setIsSaveButtonDisabled(false);
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [dayOfWeek]: {
        ...prevSchedule[dayOfWeek],
        next_day_end: updatedNextDayEnd,
      },
    }));
  };
  const onChangeIsWorkingDay = (e: ChangeEvent<HTMLInputElement>, dayOfWeek: string) => {
    const isActive = e.currentTarget.checked;

    setDisabledSwitch(dayOfWeek);
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [dayOfWeek]: {
        ...prevSchedule[dayOfWeek],
        is_active: isActive,
      },
    }));
    updateTimeIntervals(accountId, id, {
      ...schedule,
      [dayOfWeek]: {
        ...schedule[dayOfWeek],
        is_active: isActive,
      },
    });
  };
  const onClickSaveTime = () => {
    updateTimeIntervals(accountId, id, schedule);
    setOpenDialogEditTime(null);
    setIsSaveButtonDisabled(true);
  };
  const onClickCloseDialogEditTime = () => {
    setOpenDialogEditTime(null);
  };
  const onClickOpenEditTime = (dayOfWeek: string) => {
    setOpenDialogEditTime(dayOfWeek);
  };
  return (
    <>
      <TooltipMiu
        className={classNames.tooltipShedule}
        title={
          answer === "FIRST_MESSAGE" || answer === "OFF"
            ? "Настройка расписания не доступна при режиме работы: «Приветствие» или «Выкл»"
            : ""
        }
        followCursor={true}
      >
        <Block title={"Расписание автоответчика"} classNameChildren={classNames.children}>
          {Object.keys(timeIntervals).map((dayOfWeek, index) => {
            return (
              <div key={index} className={classNames.shedule}>
                <DialogRadix
                  className={classNames.dialogShedule}
                  open={openDialogEditTime === dayOfWeek}
                  title={`Расписание автоответчика на «${dayOfWeekLabelsModal[dayOfWeek]}»`}
                  onOpenCloseDialog={onClickCloseDialogEditTime}
                  typeButton={"submit"}
                  titleButtonConfirm={"Сохранить"}
                  disabledButton={isSaveButtonDisabled}
                  onClickDialogConfirmOk={onClickSaveTime}
                >
                  <div className={classNames.dialogInfoBlock}>
                    <div className={classNames.selects}>
                      <Typography className={classNames.titleSelect}>Начальное время:</Typography>
                      <Select
                        value={schedule[dayOfWeek].start.split(":")[0]}
                        onValueChange={(value) => {
                          handleTimeChange(
                            dayOfWeek,
                            "start",
                            `${value}:${schedule[dayOfWeek].start.split(":")[1]}`,
                          );
                        }}
                        label={"часы"}
                      >
                        {hours.map((el) => {
                          return (
                            <SelectItem key={el} value={el}>
                              {el}
                            </SelectItem>
                          );
                        })}
                      </Select>
                      <Typography asChild>
                        <span>:</span>
                      </Typography>
                      <Select
                        value={schedule[dayOfWeek].start.split(":")[1]}
                        onValueChange={(value) =>
                          handleTimeChange(
                            dayOfWeek,
                            "start",
                            `${schedule[dayOfWeek].start.split(":")[0]}:${value}`,
                          )
                        }
                        label={"минуты"}
                      >
                        {minutes.map((el) => {
                          return (
                            <SelectItem key={el} value={el}>
                              {el}
                            </SelectItem>
                          );
                        })}
                      </Select>
                    </div>
                    <div className={classNames.selects}>
                      <Typography className={classNames.titleSelect}>Конечное время:</Typography>
                      <Select
                        maxHeight={"fourtyVh"}
                        value={schedule[dayOfWeek].end.split(":")[0]}
                        onValueChange={(value) =>
                          handleTimeChange(
                            dayOfWeek,
                            "end",
                            `${value}:${schedule[dayOfWeek].end.split(":")[1]}`,
                          )
                        }
                        label={"часы"}
                      >
                        {hours.map((el) => {
                          return (
                            <SelectItem key={el} value={el}>
                              {el}
                            </SelectItem>
                          );
                        })}
                      </Select>
                      <Typography asChild>
                        <span>:</span>
                      </Typography>
                      <Select
                        value={schedule[dayOfWeek].end.split(":")[1]}
                        onValueChange={(value) =>
                          handleTimeChange(
                            dayOfWeek,
                            "end",
                            `${schedule[dayOfWeek].end.split(":")[0]}:${value}`,
                          )
                        }
                        label={"минуты"}
                        maxHeight={"fourtyVh"}
                      >
                        {minutes.map((el) => {
                          return (
                            <SelectItem key={el} value={el}>
                              {el}
                            </SelectItem>
                          );
                        })}
                      </Select>
                    </div>
                    <div className={classNames.checkboxBlock}>
                      <div className={classNames.checkBoxItem}>
                        <Checkbox
                          checked={schedule[dayOfWeek].next_day_end}
                          color="primary"
                          onChange={() => handleCheckboxChange(dayOfWeek)}
                          disabled={
                            schedule[dayOfWeek].start > schedule[dayOfWeek].end ||
                            disabledCheckBoxTime ||
                            schedule[dayOfWeek].start === schedule[dayOfWeek].end
                          }
                        />
                        <Typography asChild variant={"Body2"} colorText={"darkGray"}>
                          <label>следующего дня</label>
                        </Typography>
                      </div>
                    </div>
                  </div>
                </DialogRadix>
                <div key={index} className={classNames.scheduleHeader}>
                  <Typography className={classNames.headerText} variant={"Body2"}>
                    {dayOfWeekLabels[dayOfWeek]}
                  </Typography>
                  <TooltipMiu title={"вкл/выкл"} placement={"top"}>
                    <Switch
                      className={classNames.switch}
                      checked={schedule[dayOfWeek].is_active}
                      onChange={(e) => onChangeIsWorkingDay(e, dayOfWeek)}
                      inputProps={{ "aria-label": "controlled" }}
                      size={"small"}
                      color={"info"}
                      sx={{ color: "#fff" }}
                      disabled={
                        (disabledSwitch === dayOfWeek && isLoading) ||
                        answer === "FIRST_MESSAGE" ||
                        answer === "OFF"
                      }
                    />
                  </TooltipMiu>
                </div>
                <div
                  className={classNames.timeBlock(dayOfWeek)}
                  onClick={() => onClickOpenEditTime(dayOfWeek)}
                >
                  <div className={classNames.time}>
                    <Typography asChild variant={"Overline"}>
                      <span>{timeIntervals[dayOfWeek].start}</span>
                    </Typography>
                    <Typography asChild className={classNames.timeDelimiter}>
                      <span>-</span>
                    </Typography>
                    <Typography asChild variant={"Overline"}>
                      <span>{timeIntervals[dayOfWeek].end}</span>
                    </Typography>
                  </div>
                </div>
                <div className={classNames.nextDayCheck(dayOfWeek)}>
                  {schedule[dayOfWeek].next_day_end ? (
                    <div className={classNames.circleOn}></div>
                  ) : (
                    <div className={classNames.circleOff}></div>
                  )}
                  {schedule[dayOfWeek].next_day_end ? (
                    <div className={classNames.spanNextDay}>след. дня</div>
                  ) : (
                    <div className={classNames.spanNextDay}>тек. дня</div>
                  )}
                </div>
              </div>
            );
          })}
        </Block>
      </TooltipMiu>
    </>
  );
};

export default React.memo(VkSchedule);
