import React, { useCallback, useContext, useRef, useState } from "react";
import { VkCommand } from "./vkCommand/vkCommand";
import {
  AutoAnswersSettingVkType,
  GetAutoAnswerResponseType,
  TimeIntervals,
} from "../vk.chatBot.api";
import { AnswersPaginationState, thunkVkChatBot, vkChatBotAction } from "../vkChatBotReducer";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/hooks";
import vkIcon from "../../../../common/assets/icons/svg/vk/VKTextLogo.svg";
import vkIconDarkTheme from "../../../../common/assets/icons/svg/vk/VKTextLogoWhite.svg";
import s from "./VkAnswer.module.scss";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import IconButton from "@mui/material/IconButton";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProfileResType } from "../../../Auth/auth.api";
import { TooltipMiu } from "../../../../components/TooltipMiu/TooltipMiu";
import DeleteIcon from "@mui/icons-material/Delete";
import { PaginationTabs } from "../../../../components/Paginations/PaginationTabs/PaginationTabs";
import { Pagination } from "../../../../components/Paginations/Pagination/Pagination";
import VkBasicSettings from "./VkBasicSettings/VkBasicSettings";
import VkMessageText from "./VkMessageText/VkMessageText";
import VkSchedule from "./VkSchedule/VkShedule";
import { validationSchemes } from "../../../../common/validationSchemes/validationSchemes";
import { ControlledTextField } from "../../../../components/TextField/controlled-textField";
import { DialogRadix } from "../../../../components/Dialog/DialogRadix/DialogRadix";
import { Block } from "../../../../components/Layout/Block/Block";
import { Theme, ThemeContext } from "../../../../common/hoc/ThemeProvider/ThemeContext";

type AvitoAnswerType = {
  autoAnswers: GetAutoAnswerResponseType[];
  accountId: string;
};

const schema = yup.object().shape({
  name: validationSchemes.nameAutoAnswer,
});
type DataType = yup.InferType<typeof schema>;

const VkAnswer: React.FC<AvitoAnswerType> = (props) => {
  const { autoAnswers, accountId } = props;

  /** <PAGINATION> */
  const portionPage = useAppSelector<number>((state) => state.vkChatBot.vkChatBot.portionPage);
  const limit = useAppSelector<number>((state) => state.vkChatBot.vkChatBot.limit);
  const answersCurrentPages = useAppSelector<AnswersPaginationState>(
    (state) => state.vkChatBot.vkChatBot.vkAnswerPagination,
  );
  const answersElements = useAppSelector<AnswersPaginationState>(
    (state) => state.vkChatBot.vkChatBot.vkAnswerPagination,
  );
  const currentPage = answersCurrentPages[accountId]?.currentPage;
  const totalCount = answersElements[accountId]?.countElement;
  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;
  const itemsForCurrentPage = autoAnswers.slice(startIndex, endIndex);

  const onClickSetCurrentPage = (currentPage: number) => {
    dispatch(vkChatBotAction.setCurrentPageNew({ accountId, currentPage: currentPage }));
  };
  /** </PAGINATION> */

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModalAddAutoAnswer, setOpenModalAddAutoAnswer] = useState(false);
  const { theme } = useContext(ThemeContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { name: "" },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const dispatch = useAppDispatch();
  const token = useAppSelector<string>((state) => state.authorization.login.access_token);
  const profile = useAppSelector<ProfileResType>((state) => state.authorization.profile);
  const globalLoading = useAppSelector<boolean>((state) => state.app.isGlobalLoading);
  const isAdmin = useAppSelector<boolean>((state) => state.authorization.profile.is_admin);
  const formRef = useRef<HTMLFormElement>(null);

  const onSubmit = (data: DataType) => {
    if (profile?.vk_accounts) {
      dispatch(thunkVkChatBot.addedVkAnswer({ vkAccId: accountId, name: data.name }));
    }
    reset();
  };
  const onclickHandlerAddNewAutoAnswer = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
    setOpenModalAddAutoAnswer(false);
  };

  const onChangeSendCommandList = useCallback(
    (accountId: number, id: number, sendCommand: boolean) => {
      dispatch(
        vkChatBotAction.setLoadingAndDisabledSystem({ key: "send_commands_list", value: true }),
      );
      dispatch(
        thunkVkChatBot.updateAutoAnswer({
          accountId,
          id,
          token,
          domainModel: { send_commands_list: sendCommand },
        }),
      )
        .unwrap()
        .catch((e) => {
          console.warn(e);
        })
        .finally(() => {
          dispatch(
            vkChatBotAction.setLoadingAndDisabledSystem({
              key: "send_commands_list",
              value: false,
            }),
          );
        });
    },
    [dispatch, token],
  );
  const onChangeMessagePauseSeconds = useCallback(
    (accountId: number, id: number, pause: number) => {
      dispatch(vkChatBotAction.setLoadingAndDisabledSystem({ key: "pause", value: true }));
      dispatch(
        thunkVkChatBot.updateAutoAnswer({
          accountId,
          id,
          token,
          domainModel: { message_pause_seconds: pause },
        }),
      )
        .unwrap()
        .catch((e) => {
          console.warn(e);
        })
        .finally(() => {
          dispatch(vkChatBotAction.setLoadingAndDisabledSystem({ key: "pause", value: false }));
        });
    },
    [dispatch, token],
  );

  const onChangeAutoAnswersSetting = useCallback(
    (accountId: number, id: number, autoAnswerSetting: AutoAnswersSettingVkType) => {
      dispatch(
        vkChatBotAction.setLoadingAndDisabledSystem({ key: "auto_answers_setting", value: true }),
      );
      dispatch(
        thunkVkChatBot.updateAutoAnswer({
          accountId: accountId,
          id: id,
          token: token,
          domainModel: { auto_answers_setting: autoAnswerSetting },
        }),
      )
        .unwrap()
        .catch((e) => {
          console.warn(e);
        })
        .finally(() => {
          dispatch(
            vkChatBotAction.setLoadingAndDisabledSystem({
              key: "auto_answers_setting",
              value: false,
            }),
          );
        });
    },
    [dispatch, token],
  );

  const onChangeTimeOffsetFromUTC = useCallback(
    (accountId: number, id: number, utc: number) => {
      dispatch(
        vkChatBotAction.setLoadingAndDisabledSystem({ key: "time_offset_from_utc", value: true }),
      );
      dispatch(
        thunkVkChatBot.updateAutoAnswer({
          accountId,
          id,
          token,
          domainModel: { time_offset_from_utc: utc },
        }),
      )
        .unwrap()
        .catch((e) => {
          console.warn(e);
        })
        .finally(() => {
          dispatch(
            vkChatBotAction.setLoadingAndDisabledSystem({
              key: "time_offset_from_utc",
              value: false,
            }),
          );
        });
    },
    [dispatch, token],
  );

  const updateTimeIntervals = useCallback(
    (accountId: number, id: number, timeInterval: TimeIntervals) => {
      dispatch(
        thunkVkChatBot.updateAutoAnswer({
          accountId,
          id,
          token,
          domainModel: { time_intervals: timeInterval },
        }),
      );
    },
    [dispatch, token],
  );

  const messageText = useCallback(
    (accountId: number, id: number, text: string) => {
      dispatch(thunkVkChatBot.updateAutoAnswer({ accountId, id, token, domainModel: { text } }));
    },
    [dispatch, token],
  );

  const editTitleAvitoAnswer = useCallback(
    (accountId: number, id: number, name: string) => {
      dispatch(vkChatBotAction.setLoadingAndDisabledSystem({ key: "name", value: true }));
      dispatch(thunkVkChatBot.updateAutoAnswer({ accountId, id, token, domainModel: { name } }))
        .unwrap()
        .finally(() => {
          dispatch(vkChatBotAction.setLoadingAndDisabledSystem({ key: "name", value: false }));
        });
    },
    [token, dispatch],
  );

  const onClickDeleteAutoAnswer = useCallback(
    (answerId: number) => {
      dispatch(thunkVkChatBot.deleteAutoAnswer({ accountId, answerId }));
      setOpenDeleteModal(false);
    },
    [accountId, dispatch],
  );

  const onClickOpenModalDelAutoAnswer = () => {
    setOpenDeleteModal(true);
  };
  const onClickCloseModalDelAutoAnswer = () => {
    setOpenDeleteModal(false);
  };
  const onClickCloseModalAddAutoAnswer = () => {
    setOpenModalAddAutoAnswer(false);
    reset();
  };
  const onClickOpenModalAddAutoAnswer = () => {
    setOpenModalAddAutoAnswer(true);
  };

  return (
    <>
      {autoAnswers.length > 0 ? (
        itemsForCurrentPage.map((el) => {
          return (
            <div key={el.id}>
              {isAdmin && autoAnswers.length > 0 && (
                <div className={s.modalAndPagination}>
                  <PaginationTabs
                    array={autoAnswers}
                    tabNameProperty={"name"}
                    totalCount={totalCount}
                    currentPages={currentPage}
                    pageLimit={limit}
                    portionPage={portionPage}
                    onClickPagination={onClickSetCurrentPage}
                  />
                  <div className={s.title}>
                    {isAdmin && autoAnswers.length > 0 && autoAnswers.length < 2 && (
                      <TooltipMiu title={"добавить автоответчик"} placement={"right-start"}>
                        <IconButton
                          className={s.addAvitoAnswersIcon}
                          size="medium"
                          onClick={onClickOpenModalAddAutoAnswer}
                        >
                          <AddCircleSharpIcon />
                        </IconButton>
                      </TooltipMiu>
                    )}
                  </div>
                  <DialogRadix
                    childOverflowOff
                    title={"Добавить автоответчик"}
                    open={openModalAddAutoAnswer}
                    onOpenCloseDialog={onClickCloseModalAddAutoAnswer}
                    onClickDialogConfirmOk={onclickHandlerAddNewAutoAnswer}
                  >
                    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                      <ControlledTextField
                        control={control}
                        name={"name"}
                        error={errors.name && errors.name.message}
                        label={"Введите имя автоответчика"}
                      />
                    </form>
                  </DialogRadix>
                </div>
              )}
              <div className={s.deleteBlock}>
                <TooltipMiu title={"удалить автоответчик"} placement={"top-end"}>
                  <IconButton
                    onClick={onClickOpenModalDelAutoAnswer}
                    aria-label="delete=autoAnswer"
                    className={s.deleteAvitoAnswer}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TooltipMiu>
                <DialogRadix
                  title={"Удаление автоответчика"}
                  open={openDeleteModal}
                  onOpenCloseDialog={onClickCloseModalDelAutoAnswer}
                  onClickDialogConfirmOk={() => onClickDeleteAutoAnswer(el.id)}
                  description={"Вы уверены что хотите удалить автоответчик? "}
                />
              </div>
              <div className={s.untRowOne}>
                <VkBasicSettings
                  onChangeSelectMessagePause={onChangeMessagePauseSeconds}
                  onChangeMessagePause={onChangeMessagePauseSeconds}
                  onChangeAutoAnswersSetting={onChangeAutoAnswersSetting}
                  onChangeTimeOffsetFromUTC={onChangeTimeOffsetFromUTC}
                  editTitleAvitoAnswer={editTitleAvitoAnswer}
                  id={el.id}
                  accountId={el.account_id}
                  timeOffsetFromUtc={el.time_offset_from_utc}
                  messagePauseSeconds={el.message_pause_seconds}
                  autoAnswersSetting={el.auto_answers_setting}
                  name={el.name}
                />
                <VkSchedule
                  accountId={el.account_id}
                  id={el.id}
                  answer={el.auto_answers_setting}
                  timeIntervals={el.time_intervals}
                  updateTimeIntervals={updateTimeIntervals}
                />
              </div>
              <div className={s.untRow}>
                <VkMessageText
                  accountId={el.account_id}
                  id={el.id}
                  text={el.text}
                  messageText={messageText}
                />
                <VkCommand
                  accountId={el.account_id} //TODO: Лишний пропс (из за измененного функционала, он теперь не нужен) и нужно булет провести работу по его удалению
                  onChangeSendCommandList={onChangeSendCommandList}
                  sendCommandValue={el.send_commands_list}
                  vkAnswerId={el.id}
                />{" "}
                {/* Блок с командами */}
              </div>
              {isAdmin && autoAnswers.length > 0 && (
                <Pagination
                  totalCount={totalCount}
                  currentPages={currentPage}
                  pageLimit={limit}
                  portionPage={portionPage}
                  onClickPagination={onClickSetCurrentPage}
                />
              )}
            </div>
          );
        })
      ) : (
        <Block
          classNameWrapper={s.blockAddAutoAnswer}
          classNameChildren={s.blockAddAutoAnswerChildren}
        >
          <div className={s.avitoIcon}>
            {theme === Theme.DARK ? (
              <img className={s.vkLogo} src={vkIconDarkTheme} alt="vkIcon" />
            ) : (
              <img className={s.vkLogo} src={vkIcon} alt="vkIcon" />
            )}
          </div>
          <form className={s.inputBlock} onSubmit={handleSubmit(onSubmit)}>
            <ControlledTextField
              label={"Создать автоответчик"}
              placeholder={"Введите имя"}
              control={control}
              name={"name"}
              error={errors.name && errors.name.message}
            />
            <IconButton
              type={"submit"}
              className={s.addAvitoAnswersIcon}
              size="medium"
              disabled={globalLoading}
            >
              <AddCircleSharpIcon />
            </IconButton>
          </form>
        </Block>
      )}
    </>
  );
};

export default React.memo(VkAnswer);
