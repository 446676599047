import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "../../../common/utils/createAppAsyncThunk";
import {
  vkAccountApi,
  DeleteVkAccountReq,
  LinkVkAccountReq,
  LinkVkAccountRes,
  StatusWebHook,
  StatusWebHookRes,
} from "./vk.account.api";
import { handleApiError, handleApiErrorStatusCode200 } from "../../../common/utils/errorUtils";
import { authThunks } from "../../Auth/auth-reducer";
import { getTokenInLocalStorage } from "../../../common/utils/localStorageUtils";

export type KeyDisabledAndLoadingVkAcc = "addVkAccount" | "statusWebhook" | "deleteVkAcc";
export type LoadingAndDisabledVkAccState = {
  [K in KeyDisabledAndLoadingVkAcc]: { [accountId: number | string]: boolean };
};
export const MOCK_ID_LOADING_DISABLED_SYSTEM = {
  ADD_VK_ACC: "addedVkAccount",
};

export const slice = createSlice({
  name: "vk-account",
  initialState: {
    vkAccount: {} as LinkVkAccountRes,
    statusWebHook: false as StatusWebHookRes,
    loadingAndDisabledSystemVkAcc: {
      addVkAccount: {},
      statusWebhook: {},
      deleteVkAcc: {},
    } as LoadingAndDisabledVkAccState,
    pagination: {
      portionPage: 10,
      limit: 1,
      totalCount: 0 as number,
      currentPage: 0 as number,
    },
  },
  reducers: {
    setCurrentPage: (state, action: PayloadAction<{ currentPage: number }>) => {
      state.pagination.currentPage = action.payload.currentPage;
    },
    setTotalCount: (state, action: PayloadAction<{ totalCount: number }>) => {
      state.pagination.currentPage = action.payload.totalCount;
    },
    loadingAndDisabledSystemVkAcc: (
      state,
      action: PayloadAction<{
        key: KeyDisabledAndLoadingVkAcc;
        accountId: number | string;
        value: boolean;
      }>,
    ) => {
      const { key, accountId, value } = action.payload;
      if (!state.loadingAndDisabledSystemVkAcc[key]) {
        state.loadingAndDisabledSystemVkAcc[key] = {};
      }
      state.loadingAndDisabledSystemVkAcc[key][accountId] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(vkAccountThunks.statusWebHook.fulfilled, (state, action) => {
        state.statusWebHook = action.payload.statusWebhook;
      })
      .addCase(authThunks.profile.fulfilled, (state, action) => {
        state.pagination.totalCount = action.payload.profile.vk_accounts.length;
        if (action.payload.profile.vk_accounts.length >= 1) {
          state.pagination.currentPage = 1;
        }
      })
      .addCase(vkAccountThunks.addVkAccount.fulfilled, (state) => {
        state.pagination.totalCount = state.pagination.totalCount + 1;
        state.pagination.currentPage = state.pagination.currentPage + 1;
      })
      .addCase(vkAccountThunks.deleteVkAcc.fulfilled, (state) => {
        state.pagination.totalCount = state.pagination.totalCount - 1;
        state.pagination.currentPage = state.pagination.currentPage = 1;
      });
  },
});

const addVkAccount = createAppAsyncThunk<
  {
    msg: LinkVkAccountRes;
  },
  LinkVkAccountReq
>("vk-account/addVkAccount", async (arg, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  dispatch(
    vkAccountActions.loadingAndDisabledSystemVkAcc({
      key: "addVkAccount",
      value: true,
      accountId: MOCK_ID_LOADING_DISABLED_SYSTEM.ADD_VK_ACC,
    }),
  );
  try {
    const res = await vkAccountApi.addAvitoAccount({ token: arg.token, api_key: arg.api_key });
    const token = getTokenInLocalStorage("tokenChatBot");

    dispatch(authThunks.profile({ token: token }));
    handleApiErrorStatusCode200(res.data.detail, dispatch);

    return { msg: res.data };
  } catch (e: any) {
    handleApiErrorStatusCode200(e.response.data.detail, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(
      vkAccountActions.loadingAndDisabledSystemVkAcc({
        key: "addVkAccount",
        value: false,
        accountId: MOCK_ID_LOADING_DISABLED_SYSTEM.ADD_VK_ACC,
      }),
    );
  }
});

const deleteVkAcc = createAppAsyncThunk<{ messageDelete: string; id: number }, DeleteVkAccountReq>(
  "vk-account/deleteVkAccount",
  async (arg, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;

    dispatch(
      vkAccountActions.loadingAndDisabledSystemVkAcc({
        key: "deleteVkAcc",
        value: true,
        accountId: arg.account_id,
      }),
    );
    try {
      const res = await vkAccountApi.deleteAvitoAccount({
        account_id: arg.account_id,
        is_confirmed: arg.is_confirmed,
        token: arg.token,
      });
      return { messageDelete: res.data, id: arg.account_id };
    } catch (e) {
      handleApiError(e, dispatch);
      return rejectWithValue(null);
    } finally {
      dispatch(
        vkAccountActions.loadingAndDisabledSystemVkAcc({
          key: "deleteVkAcc",
          value: false,
          accountId: arg.account_id,
        }),
      );
    }
  },
);

const statusWebHook = createAppAsyncThunk<
  {
    statusWebhook: StatusWebHookRes;
  },
  StatusWebHook
>("vk-account/status-webhook", async (arg, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  dispatch(
    vkAccountActions.loadingAndDisabledSystemVkAcc({
      key: "statusWebhook",
      value: true,
      accountId: arg.account_id,
    }),
  );
  try {
    const res = await vkAccountApi.statusWebHook({ account_id: arg.account_id, token: arg.token });
    return { statusWebhook: res.data };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(
      vkAccountActions.loadingAndDisabledSystemVkAcc({
        key: "statusWebhook",
        value: false,
        accountId: arg.account_id,
      }),
    );
  }
});

export const vkAccountReducer = slice.reducer;
export const vkAccountActions = slice.actions;
export const vkAccountThunks = { addVkAccount, deleteVkAcc, statusWebHook };
