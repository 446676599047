import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { appAction } from "../../app/app-reducer";
import { useAppDispatch, useAppSelector } from "../../common/hooks/hooks";


type AlertsComponentType = {
  position?:
    | "top-right"
    | "top-center"
    | "top-left"
    | "bottom-right"
    | "bottom-center"
    | "bottom-left";
  autoClose?: number;
  hideProgressBar?: boolean;
  newestOnTop?: boolean;
  rtl?: boolean;
  theme?: "light" | "dark" | "colored";
  className?: string;
};

export const AlertsComponent: React.FC<AlertsComponentType> = (props) => {
  const dispatch = useAppDispatch();
  const alerts = useAppSelector<string | null>((state) => state.app.alerts);

  const {
    rtl = false,
    hideProgressBar = false,
    theme = "colored",
    autoClose = 20000,
    position = "top-left",
    newestOnTop = true,
    className,
  } = props;

  useEffect(() => {
    // toast("Loaded successfully");
    if (alerts) {
      toast.info(alerts, { draggable: false });
    }

    setTimeout(() => {
      dispatch(appAction.setAlerts({ alerts: null }));
    }, 6000);
  }, [alerts, dispatch]);

  return (
    <>
      <ToastContainer
        position={position}
        autoClose={autoClose}
        hideProgressBar={hideProgressBar}
        newestOnTop={newestOnTop}
        closeOnClick
        rtl={rtl}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className={className}
        theme={theme}
      />
    </>
  );
};
