import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import s from "./AvitoMessageText.module.scss";
import { ButtonMiu } from "../../../../../components/Buttons/Button/ButtonMiu";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import SaveAltSharpIcon from "@mui/icons-material/SaveAltSharp";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Emoji } from "../../../../../components/Emoji/Emoji";
import { useAppSelector } from "../../../../../common/hooks/hooks";
import SentimentSatisfiedSharpIcon from "@mui/icons-material/SentimentSatisfiedSharp";
import { NavigationMenu } from "../../../../../components/Layout/NavigationMenu/NavigationMenu";
import { Block } from "../../../../../components/Layout/Block/Block";
import { DialogRadix } from "../../../../../components/Dialog/DialogRadix/DialogRadix";
import { validationSchemes } from "../../../../../common/validationSchemes/validationSchemes";

type AvitoMessageGreetingsType = {
  avitoAccId: string;
  id: number;
  text: string;
  messageText: (avitoAccId: string, id: number, text: string) => void;
};

const schema = yup.object().shape({
  text: validationSchemes.schemesMessageText.text,
});
type FormDataType = yup.InferType<typeof schema>;

const AvitoMessageText: React.FC<AvitoMessageGreetingsType> = (props) => {
  const { register, getValues, setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const { text, avitoAccId, messageText } = props;
  const [remainingLetters, setRemainingLetters] = useState(text.length);
  const isLoading = useAppSelector<boolean>((state) => state.app.isLocalLoading);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [emojiWindow, setEmojiWindow] = useState<boolean>(false);
  const emojiRef = useRef<HTMLDivElement | null>(null);
  const [openDialogConfirmDeleteText, setOpenDialogConfirmDeleteText] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (emojiRef.current && !emojiRef.current.contains(event.target as Node)) {
        setEmojiWindow(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const onSubmit = useCallback(
    (data: FormDataType) => {
      const trimmedText = data.text ? data.text.trim() : "";
      messageText(avitoAccId, props.id, trimmedText);
      setButtonDisabled(true);
    },
    [avitoAccId, messageText, props.id],
  );

  const onTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const enteredText = e.currentTarget.value;
    const count = [...enteredText].length;
    setRemainingLetters(count);
    setButtonDisabled(false);
  };

  const onClickBtnClearText = useCallback(() => {
    reset();
    messageText(avitoAccId, props.id, "");
    setButtonDisabled(true);
    setOpenDialogConfirmDeleteText(false);
  }, [avitoAccId, messageText, props.id, reset]);

  const insertEmoji = (emoji: string) => {
    const currentText = getValues("text");
    const newText = currentText + emoji;
    setValue("text", newText);
    setRemainingLetters([...newText].length);
    setButtonDisabled(false);
    setEmojiWindow(false);
  };

  const onClickEmojiWindowsOpen = () => {
    setEmojiWindow(!emojiWindow);
  };

  return (
    <Block title={"Сообщение автоответа"} classNameWrapper={s.block}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={s.textArea}>
          <textarea
            id="textarea"
            className={s.textAreaInput}
            defaultValue={text}
            {...register("text")}
            onChange={onTextChange}
          />
          {/*{errors.text && <div>{errors.text.message}</div>}*/}
          <NavigationMenu className={s.emoji} ref={emojiRef}>
            <SentimentSatisfiedSharpIcon
              onClick={onClickEmojiWindowsOpen}
              className={s.imageOpenWindowEmoji}
            />
            {emojiWindow && (
              <>
                <div>
                  <div className={s.emojiDiv}>
                    <Emoji
                      searchDisabled={false}
                      onEmojiClick={insertEmoji}
                      width={270}
                      height={370}
                    />
                  </div>
                </div>
              </>
            )}
            <div className={s.countLetters}>{`${remainingLetters} / 1000`}</div>
          </NavigationMenu>
        </div>
        <div className={s.buttons}>
          <DialogRadix
            open={openDialogConfirmDeleteText}
            title={"Удалить сообщение автоответа"}
            description={"Подтвердите для удаления сообщения автоответа."}
            onClickDialogConfirmOk={onClickBtnClearText}
            onOpenCloseDialog={() => setOpenDialogConfirmDeleteText(false)}
          />
          <ButtonMiu
            title={"Очистить"}
            variant={"outlined"}
            callBack={() => setOpenDialogConfirmDeleteText(true)}
            endCoin={<ClearSharpIcon />}
          />
          <ButtonMiu
            title={"Сохранить"}
            variant={"outlined"}
            className={s.btnSave}
            endCoin={<SaveAltSharpIcon />}
            type={"submit"}
            loading={isLoading}
            loadingPosition={"end"}
            disabled={isButtonDisabled}
          />
        </div>
      </form>
    </Block>
  );
};

export default React.memo(AvitoMessageText);
