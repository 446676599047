import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "./Header.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage, faUser } from "@fortawesome/free-solid-svg-icons";
import React, { ReactNode, useContext } from "react";
import { PATCH } from "pages/Pages";
import { ButtonMiu } from "../Buttons/Button/ButtonMiu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAppDispatch, useAppSelector } from "common/hooks/hooks";
import { authActions } from "pages/Auth/auth-reducer";
import { deleteTokenInLocalStorage } from "common/utils/localStorageUtils";
import logo from "../../common/assets/icons/svg/logo/ChatBotHelperLogo.svg";
import logoDark from "../../common/assets/icons/svg/logo/ChatBotHelperLogoDarkTheme.svg";
import HelpCenterSharpIcon from "@mui/icons-material/HelpCenterSharp";
import { Theme, ThemeContext } from "common/hoc/ThemeProvider/ThemeContext";

type PagesType = {
  name: string;
  link: string;
  icon: ReactNode;
};

export const Header = () => {
  const { theme } = useContext(ThemeContext);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const authMe = useAppSelector<boolean>((state) => state.authorization.me);
  const token = useAppSelector<string>((state) => state.authorization.login.access_token);

  const styleIcon = {
    lineHeight: "100%",
    cursor: "pointer",
  };
  const profileIcon = <FontAwesomeIcon style={styleIcon} icon={faUser} />;
  const messages = <FontAwesomeIcon style={styleIcon} icon={faMessage} />;

  const pages: PagesType[] = [
    {
      name: "Чат бот",
      link: PATCH.CHAT_BOT,
      icon: messages,
    },
    {
      name: "Профиль",
      link: PATCH.PROFILE,
      icon: profileIcon,
    },
    {
      name: "Помощь",
      link: PATCH.HELP,
      icon: <HelpCenterSharpIcon />,
    },
  ];

  //Default active link  URL: /
  const isActiveLink = (path: string) =>
    pathname === path || (pathname === PATCH.DEFAULT && path === PATCH.PROFILE);
  const onClickLogout = () => {
    deleteTokenInLocalStorage("tokenChatBot");
    dispatch(authActions.logout({ me: false }));
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const onClickBtnSignIn = () => {
    navigate(PATCH.AUTH.LOGIN);
  };

  return (
    <AppBar position="static" className={styled.appBar}>
      <Container className={styled.appBarContainer}>
        <Toolbar className={styled.toolBar}>
          {theme === Theme.LIGHT ? (
            <a className={styled.logoIcon} href={PATCH.PROFILE}>
              <img src={logo} alt="logotype" />
            </a>
          ) : theme === Theme.DARK ? (
            <a className={styled.logoIcon} href={PATCH.PROFILE}>
              <img src={logoDark} alt="logotype" />
            </a>
          ) : (
            <a className={styled.logoIcon} href={PATCH.PROFILE}>
              <img src={logo} alt="logotype" />
            </a>
          )}
          <Box sx={{ flexGrow: 1, display: { xs: "flex" } }}>
            {authMe && token !== "" ? (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="error"
                className={styled.burgerMenu}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <></>
            )}
            <Menu
              className={styled.appBarMenu}
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: {
                  backgroundColor: theme === Theme.DARK ? "#161b22" : "",
                  border: theme === Theme.DARK ? "1px solid rgba(239, 239, 239, 0.25)" : "",
                },
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page, index) => (
                <MenuItem className={styled.appBarLink} key={index} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <NavLink
                      to={page.link}
                      style={{
                        textDecoration: "none",
                        color: isActiveLink(page.link)
                          ? theme === Theme.DARK
                            ? "rgba(31, 196, 96, 0.65)"
                            : "inherit"
                          : "inherit",
                        fontWeight: isActiveLink(page.link)
                          ? theme === Theme.DARK
                            ? "bold"
                            : "normal"
                          : "normal",
                      }}
                    >
                      {page.name}
                    </NavLink>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {theme === Theme.LIGHT ? (
            <a className={styled.logoCenter} href={PATCH.PROFILE}>
              <img src={logo} alt="logotype" />
            </a>
          ) : theme === Theme.DARK ? (
            <a className={styled.logoCenter} href={PATCH.PROFILE}>
              <img src={logoDark} alt="logotype" />
            </a>
          ) : (
            <a className={styled.logoCenter} href={PATCH.PROFILE}>
              <img src={logo} alt="logotype" />
            </a>
          )}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none" },
            }}
            className={styled.boxLinkCenter}
          >
            {authMe && token !== "" ? (
              <>
                {pages.map((page, index) => (
                  <div key={index} className={styled.ulLink}>
                    <NavLink
                      className={({ isActive }) =>
                        isActiveLink(page.link) ? styled.activeMenu : ""
                      }
                      to={page.link}
                      onClick={handleCloseNavMenu}
                      style={{ color: "white" }}
                    >
                      <span className={styled.styleIcon}>{page.icon}</span>
                      <Typography className={styled.textLink}>{page.name}</Typography>
                    </NavLink>
                  </div>
                ))}
              </>
            ) : (
              []
            )}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {authMe && token !== "" ? (
              <ButtonMiu title={"Выход"} callBack={onClickLogout} />
            ) : (
              <ButtonMiu title={"Войти"} callBack={onClickBtnSignIn} variant={"outlined"} />
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
