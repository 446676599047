import React, { ElementRef, forwardRef, useContext } from "react";
import s from "./AccordionContent.module.scss";
import * as AccordionRadixComponent from "@radix-ui/react-accordion";
import clsx from "clsx";
import { Theme, ThemeContext } from "../../../../common/hoc/ThemeProvider/ThemeContext";

type AccordionContentProps = {
  children: React.ReactNode;
};

export const AccordionContent = forwardRef<
  ElementRef<typeof AccordionRadixComponent.Content>,
  AccordionContentProps
>((props, ref) => {
  const { children } = props;
  const { theme } = useContext(ThemeContext);
  const classNames = {
    accordionContent: clsx(s.accordionContent),
    accordionContentText: clsx(s.accordionContentText),
  };

  const inlineStyle =
    theme === Theme.DARK
      ? {
          backgroundColor: "var(--dark-theme-background-color-block)",
          color: "var(--dark-theme-color-text)",
          border: "1px solid var(--dark-theme-border-color)",
        }
      : {};

  return (
    <AccordionRadixComponent.Content
      ref={ref}
      style={inlineStyle}
      className={classNames.accordionContent}
    >
      <div className={classNames.accordionContentText}>{children}</div>
    </AccordionRadixComponent.Content>
  );
});
