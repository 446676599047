import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import s from "./AvitoCommand.module.scss";
import { ButtonMiu } from "../../../../../components/Buttons/Button/ButtonMiu";
import SaveAltSharpIcon from "@mui/icons-material/SaveAltSharp";
import { useAppDispatch, useAppSelector } from "../../../../../common/hooks/hooks";
import { avitoCommandAction, AvitoCommandState, avitoCommandThunk } from "./avitoCommandReducer";
import { MiniMenu } from "../../../../../components/Menu/MinuMenu/MiniMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { TablePaginationMiuComponent } from "../../../../../components/Paginations/TablePaginationMuiComponent/TablePaginationMiuComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import { TooltipMiu } from "../../../../../components/TooltipMiu/TooltipMiu";
import ModeSharpIcon from "@mui/icons-material/ModeSharp";
import SourceSharpIcon from "@mui/icons-material/SourceSharp";
import { NavigationMenu } from "../../../../../components/Layout/NavigationMenu/NavigationMenu";
import DescriptionSharpIcon from "@mui/icons-material/DescriptionSharp";
import { Block } from "../../../../../components/Layout/Block/Block";
import { Switch } from "@mui/material";
import { LoadingAndDisabledState, thunkAvitoChatBot } from "../../avitoChatBotReducer";
import { DialogRadix } from "../../../../../components/Dialog/DialogRadix/DialogRadix";
import { workingWithStrings } from "../../../../../common/utils/workingWithStrings";
import { Loading } from "../../../../../components/Loadings/Loading";
import { Typography } from "../../../../../components/Typography/Typography";
import { TooltipEnum } from "../../../../../common/enam/enamProject";
import { AddVkCommandDataType } from "../../../VkChatBot/vkAnswer/vkCommand/vk.command.api";
import { CommandCreator } from "./CommandCreator/CommandCreator";
import { RefCommandEnum } from "../../../../../common/utils/handlerCommandsValues";

type AvitoCommandType = {
  avitoAnswerId: number;
  sendCommandValue: boolean;
  onChangeSendCommandList: (id: number, sendCommand: boolean) => void;
};

type CommandType = {
  openModal: boolean;
  commandId: number | null;
  commandValue: string | null;
  commandResponse: string | null;
};

export type TypeCommand = RefCommandEnum;

export const AvitoCommand: React.FC<AvitoCommandType> = (props) => {
  const { avitoAnswerId, onChangeSendCommandList, sendCommandValue } = props;
  const dispatch = useAppDispatch();
  const token = useAppSelector<string>((state) => state.authorization.login.access_token);
  const miniMenuRef = useRef<HTMLDivElement | null>(null);
  const [openMiniMenu, setOpenMiniMenu] = useState<number | null>(null);

  /** <COMMAND> */
  const commandLists = useAppSelector<AvitoCommandState>(
    (state) => state.avitoCommand.command_list,
  );
  const commandsForCurrentAnswerId = commandLists[avitoAnswerId] || []; //Сформированные команда по ID автоответчиков
  /** </COMMAND> */

  const onClickOpenMiniMenu = (id: number) => {
    if (openMiniMenu === id) {
      setOpenMiniMenu(null);
    } else {
      setOpenMiniMenu(id);
    }
  };

  const [openModalPreviewCommand, setOpenModalPreviewCommand] = useState(false);
  const [openModalSeeResponse, setOpenModalSeeResponse] = useState<CommandType>({
    openModal: false,
    commandId: null,
    commandValue: null,
    commandResponse: null,
  });
  const [openModalEditCommand, setOpenModalEditCommand] = useState<number | null>(null);
  const [openCreatorCommand, setOpenCreatorCommand] = useState(false);
  const [openModelDelCommand, setOpenModelDelCommand] = useState<number | null>(null);
  const loadingAndDisabledValues = useAppSelector<LoadingAndDisabledState>(
    (state) => state.avitoChatBot.avitoChatBot.loadingAndDisabledSystem.avito_command,
  );
  const localLoading = useAppSelector<boolean>((state) => state.app.isLocalLoading);
  const additionalParamRef = useRef<TypeCommand | undefined>();
  /** <PREVIEW> */
  const preview = useAppSelector<string>((state) => state.avitoChatBot.avitoChatBot.commandPreview);
  const replaceText = workingWithStrings(preview, "Список команд чата: ", "");
  const previewArr = replaceText.split("\n").filter((el) => {
    return el !== "";
  });
  /** </PREVIEW> */

  /** <PAGINATION COMMAND> */
  const page = useAppSelector<number>((state) => state.avitoCommand.currentPage);
  const limitInPage = useAppSelector<number>((state) => state.avitoCommand.limit);
  const startIndex = page * limitInPage;
  const endIndex = startIndex + limitInPage;
  const commandsToShow = commandsForCurrentAnswerId.slice(startIndex, endIndex);
  /** </PAGINATION COMMAND> */

  const onClickOpenCreatorCommand = (refRes: TypeCommand) => {
    additionalParamRef.current = refRes;
    setOpenCreatorCommand(true);
  };
  const onClickCloseOpenCreatorCommand = () => {
    setOpenCreatorCommand(false);
  };

  const onClickOpenModalEditVkCommand = (id: number, refRes: TypeCommand) => {
    additionalParamRef.current = refRes;
    setOpenModalEditCommand(id);
  };
  const onClickCloseModalEditVkCommand = () => {
    setOpenModalEditCommand(null);
  };

  const onClickOpenModalSeeResponse = (
    id: number,
    commandValue: string,
    commandResponse: string,
  ) => {
    setOpenModalSeeResponse({ openModal: true, commandId: id, commandValue, commandResponse });
  };
  const onClickCloseModalSeeResponse = () => {
    setOpenModalSeeResponse({
      openModal: false,
      commandId: null,
      commandValue: null,
      commandResponse: null,
    });
  };

  const onClickCloseMiniMenu = () => {
    setOpenMiniMenu(null);
  };
  const onClickCurrentPage = (currentPage: number) => {
    dispatch(avitoCommandAction.setCurrentPage({ currentPage: currentPage }));
  };
  const onClickOpenModalDelAvitoCommand = (id: number) => {
    setOpenModelDelCommand(id);
  };
  const onClickDeleteCommand = (id: number) => {
    dispatch(
      avitoCommandThunk.deleteAvitoCommand({
        answer_id: avitoAnswerId,
        command_id: id,
        token,
      }),
    );
    setOpenModelDelCommand(null);
  };

  const addCommandHandler = (data: AddVkCommandDataType) => {
    dispatch(avitoCommandThunk.addAvitoCommand({ answer_id: avitoAnswerId, token, data: data }));
  };
  const editCommandHandler = (data: AddVkCommandDataType) => {
    const id = openModalEditCommand as number;
    const model = {
      id: id,
      command_value: data.command_value,
      command_response: data.command_response,
    };
    dispatch(avitoCommandThunk.editAvitoCommand({ answerId: avitoAnswerId, token, model }));
  };

  const onChangeSendCommands = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const checked = e.currentTarget.checked;
      onChangeSendCommandList(avitoAnswerId, checked);
    },
    [avitoAnswerId, onChangeSendCommandList],
  );
  const onClickOpenPreviewCommand = () => {
    dispatch(thunkAvitoChatBot.commandPreview({ id: avitoAnswerId, token }));
    setOpenModalPreviewCommand(true);
  };
  const onClickCloseModalPreviewCommand = () => {
    setOpenModalPreviewCommand(false);
  };
  return (
    <Block
      title={"Список команд чата"}
      tooltipTrigger={TooltipEnum.sendsListCommands}
      classNameWrapper={s.block}
      trigger={
        <Switch
          disabled={loadingAndDisabledValues["send_commands_list"]}
          checked={sendCommandValue}
          onChange={(e) => onChangeSendCommands(e)}
          inputProps={{ "aria-label": "controlled" }}
          size={"small"}
          color={"info"}
          sx={{ color: "#fff" }}
        />
      }
    >
      <div className={s.commandListBlock}>
        <table className={s.commandTable}>
          <thead className={s.tableHead}>
            <tr>
              <td className={s.tdHeadOne}>№</td>
              <td className={s.tdHeadTwo}>Команды</td>
              <td className={s.tdHeadThree}></td>
            </tr>
          </thead>
          <tbody className={s.tableBody}>
            {commandsToShow.map((el, i) => {
              return (
                <React.Fragment key={el.id}>
                  <tr className={s.trBody}>
                    <td className={s.tdBodyOne}>{startIndex + i + 1}</td>
                    <td className={s.tdBodyTwo}>{el.command_value}</td>
                    <td className={s.tdBodyThree}>
                      <MenuIcon
                        onClick={() => onClickOpenMiniMenu(el.id)}
                        className={s.openMiniMenu}
                      />
                      <MiniMenu
                        ref={miniMenuRef}
                        onClose={onClickCloseMiniMenu}
                        open={openMiniMenu === el.id}
                        classNameWrapper={s.miniMenu}
                        id={openMiniMenu}
                        positionTriangle={"rightBottom"}
                      >
                        <div className={s.commandList}>
                          <TooltipMiu title={"Посмотреть ответ команды"} placement={"left"}>
                            <SourceSharpIcon
                              className={s.itemIconCommands}
                              onClick={() =>
                                onClickOpenModalSeeResponse(
                                  el.id,
                                  el.command_value,
                                  el.command_response,
                                )
                              }
                            />
                          </TooltipMiu>
                          <TooltipMiu title={"Отредактировать"}>
                            <ModeSharpIcon
                              className={s.itemIconCommands}
                              onClick={() =>
                                onClickOpenModalEditVkCommand(el.id, RefCommandEnum.EDITCOMMAND)
                              }
                            />
                          </TooltipMiu>
                          <TooltipMiu title={"Удалить"}>
                            <DeleteIcon
                              onClick={() => onClickOpenModalDelAvitoCommand(el.id)}
                              className={s.itemIconCommands}
                            />
                          </TooltipMiu>
                        </div>
                      </MiniMenu>
                    </td>
                  </tr>
                  <DialogRadix
                    title={"Удаление команды"}
                    open={openModelDelCommand === el.id}
                    onOpenCloseDialog={() => {
                      setOpenModelDelCommand(null);
                    }}
                    description={"Вы уверены что хотите удалить команду?"}
                    onClickDialogConfirmOk={() => {
                      onClickDeleteCommand(el.id);
                    }}
                  />
                  <CommandCreator
                    additionalParamRef={additionalParamRef}
                    answerId={el.auto_answer_id}
                    commandList={commandsForCurrentAnswerId}
                    commandValue={el.command_value}
                    commandResponse={el.command_response}
                    onClose={onClickCloseModalEditVkCommand}
                    open={openModalEditCommand === el.id}
                    title={"Отредактировать команду"}
                    addCommandHandler={editCommandHandler}
                  />
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      <DialogRadix
        title={"Список команд чата"}
        open={openModalPreviewCommand}
        onClickDialogConfirmOk={onClickCloseModalPreviewCommand}
        onOpenCloseDialog={onClickCloseModalPreviewCommand}
        className={s.previewCommand}
        separator={false}
      >
        <div
          className={
            localLoading || previewArr.length === 0
              ? `${s.dialogPreview} ${s.loading}`
              : s.dialogPreview
          }
        >
          {localLoading ? (
            <Loading size={30} sx={{ color: "gray" }} />
          ) : (
            <>
              {previewArr.length > 0 ? (
                previewArr.map((command, i) => {
                  return (
                    <div className={s.preview} key={i}>
                      <Typography variant={"Body1"} asChild>
                        <span>{command}</span>
                      </Typography>
                    </div>
                  );
                })
              ) : (
                <Typography className={s.previewArrNull}>Список команд пуст.</Typography>
              )}
            </>
          )}
        </div>
      </DialogRadix>
      <NavigationMenu className={s.navigation}>
        <TooltipMiu title={"Посмотреть список команда чата"} placement={"bottom-end"}>
          <DescriptionSharpIcon
            onClick={onClickOpenPreviewCommand}
            className={s.descriptionCommandIcon}
          />
        </TooltipMiu>
        <TablePaginationMiuComponent
          handleChangePage={onClickCurrentPage}
          page={page}
          countElement={commandLists[avitoAnswerId]}
          limitPage={5}
        />
      </NavigationMenu>
      <div className={s.button}>
        <ButtonMiu
          title={"Добавить"}
          variant={"outlined"}
          callBack={() => onClickOpenCreatorCommand(RefCommandEnum.CREATECOMMAND)}
          endCoin={<SaveAltSharpIcon />}
        />
      </div>
      <React.Fragment>
        <CommandCreator
          answerId={avitoAnswerId}
          commandList={commandsForCurrentAnswerId}
          open={openCreatorCommand}
          title={"Добавить команду"}
          onClose={onClickCloseOpenCreatorCommand}
          addCommandHandler={addCommandHandler}
          additionalParamRef={additionalParamRef}
        />
        <DialogRadix
          title={"Ответ команды"}
          open={openModalSeeResponse.openModal}
          description={`Команда: ${openModalSeeResponse.commandValue}`}
          onClickDialogConfirmOk={onClickCloseModalSeeResponse}
          onOpenCloseDialog={onClickCloseModalSeeResponse}
          className={s.dialogRadixAnswer}
        >
          <Typography className={s.commandRes}>{openModalSeeResponse.commandResponse}</Typography>
        </DialogRadix>
      </React.Fragment>
    </Block>
  );
};
