import { Control, FieldPath, FieldValues, useController } from "react-hook-form";
import { BaseTextFieldProps, TextField } from "../TextField";

/** Текстовое поле используемое совместно с React Hook Form */

export type ControlledTextFieldProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  disabled?: boolean;
} & Omit<BaseTextFieldProps, "onChange" | "value">;

export const ControlledTextField = <TFieldValues extends FieldValues>(
  props: ControlledTextFieldProps<TFieldValues>,
) => {
  const { field } = useController({
    control: props.control,
    name: props.name,
    disabled: props.disabled,
  });

  return <TextField disabled={props.disabled} {...props} {...field} />;
};
