import React from "react";

export const Offer = () => {
  return (
    <div className="container-offer">
      <h1 className="title-one">Публичная оферта</h1>
      <h2 className="title-two">о заключении договора об оказании услуг</h2>
      <div className="offer-description">
        <p className="title-desc">1. Общие положения</p>
      </div>
      <div className="offer-description-text">
        В настоящей Публичной оферте содержатся условия заключения Договора об оказании услуг (далее
        по тексту - «Договор об оказании услуг» и/или «Договор»). Настоящей офертой признается
        предложение,адресованное одному или нескольким конкретным лицам, которое достаточно
        определенно и выражает намерение лица, сделавшего предложение, считать себя заключившим
        Договор с адресатом, которым будет принято предложение. <br />
        Совершение указанных в настоящей Оферте действий является подтверждением согласия обеих
        Сторон заключить Договор об оказании услуг на условиях, в порядке и объеме, изложенных в
        настоящей Оферте. <br />
        Нижеизложенный текст Публичной оферты является официальным публичным предложением
        Исполнителя, адресованный заинтересованному кругу лиц заключить Договор об оказании услуг в
        соответствии с положениями пункта 2 статьи 437 Гражданского кодекса РФ.
        <br />
        Договор об оказании услуг считается заключенным и приобретает силу с момента совершения
        Сторонами действий,предусмотренных в настоящей Оферте, и, означающих безоговорочное, а также
        полное принятие всех условий настоящей Оферты без каких-либо изъятий или ограничений на
        условиях присоединения.
        <br />
        <br />
        <strong>Термины и определения:</strong>
        <br />
        <strong>Договор</strong> – текст настоящей Оферты с Приложениями, являющимися неотъемлемой
        частьюнастоящей Оферты, акцептованный Заказчиком путем совершения конклюдентных
        действий,предусмотренных настоящей Офертой
        <br />
        <strong>Конклюдентные действия</strong>- это поведение,которое выражает согласие с
        предложением контрагента заключить, изменить или расторгнуть договор. Действия состоят в
        полном или частичном выполнении условий, которые предложил контрагент. <br />
        <strong>Сайт Исполнителя в сети «Интернет»</strong> – совокупность программ для электронных
        вычислительных машин и иной информации, содержащейся в информационной системе, доступ к
        которой обеспечивается посредством сети«Интернет» по доменному имени и сетевому адресу:
        https://chatbothelper.ru
        <br />
        <strong>Стороны Договора (Стороны)</strong> – Исполнитель и Заказчик. <br />
        <strong>‍Услуга</strong> – услуга, оказываемая Исполнителем Заказчику в порядке и на
        условиях,установленных настоящей Офертой.
        <br />
      </div>
      <div className="offer-description">
        <p className="title-desc">2. Предмет договора</p>
      </div>
      <div className="offer-description-text">
        1.1. &nbsp; &nbsp;Исполнитель обязуется оказать Заказчику Услуги,а Заказчик обязуется
        оплатить их в размере, порядке и сроки, установленные настоящим Договором.
        <br />
        1.2. &nbsp; &nbsp;Наименование, количество, порядок и иные условия оказания Услуг
        определяются на основании сведений Исполнителя при оформлении заявки Заказчиком, либо
        устанавливаются на сайте Исполнителя в сети «Интернет» https://chatbothelper.ru
        <br />
        1.3. &nbsp; &nbsp;Исполнитель оказывает Услуги по настоящему Договору лично, либо с
        привлечением третьих лиц, при этом задействия третьих лиц Исполнитель отвечает перед
        Заказчиком как за свои собственные.
        <br />
        1.4. &nbsp; &nbsp;Договор заключается путем акцепта настоящей Оферты через совершение
        конклюдентных действий, выраженных в:
        <br />
        • действиях,связанных с регистрацией учетной записи на Сайте Исполнителя в сети «Интернет»
        при наличии необходимости регистрации учетной записи;
        <br />
        • оформлении и направлении Заказчиком заявки в адрес Исполнителя для оказания Услуг;
        <br />
        • действиях,связанных с оплатой Услуг Заказчиком;
        <br />
        • действиях,связанных с оказанием Услуг Исполнителем
        <br />
      </div>
      <div className="offer-description">
        <p className="title-desc">3. Права и обязанности Сторон</p>
      </div>
      <div className="offer-description-text">
        3.1.&nbsp; &nbsp; <strong>Права и обязанности Исполнителя:</strong>
        <br />
        3.1.1. Исполнитель обязуется оказать Услуги в соответствии с положениями настоящего
        Договора, в сроки и объеме, указанные в настоящем Договоре и (или) в порядке, указанном на
        Сайте Исполнителя. <br />
        3.1.2. Исполнитель обязуется предоставлять Заказчику доступ к разделам Сайта, необходимым
        для получения информации, согласно пункту 3.2. Договора.
        <br />
        3.1.3. Исполнитель несет ответственность за хранение и обработку персональных данных
        Заказчика,обеспечивает сохранение конфиденциальности этих данных и использует их
        исключительно для качественного оказания Услуг Заказчику.
        <br />
        3.1.4. Исполнитель оставляет за собой право изменять сроки (период) оказания Услуг и условия
        настоящей Оферты в одностороннем порядке без предварительного уведомления Заказчика,публикуя
        указанные изменения на Сайте Исполнителя в сети «Интернет». При этом новые / измененные
        условия,указываемые на Сайте, действуют только в отношении вновь заключаемых Договоров.
        <br />
        3.2.&nbsp; &nbsp; <strong>Права и обязанности Заказчика:</strong> <br />
        3.2.1. Заказчик обязан предоставлять достоверную информацию о себе при получении
        соответствующих Услуг.
        <br />
        3.2.2. Заказчик обязуется не воспроизводить, не повторять, не копировать, не продавать, а
        также не использовать в каких бы тони было целях информацию и материалы, ставшие ему
        доступными в связи с оказанием Услуг, за исключением личного использования непосредственно
        самим Заказчиком без предоставления в какой-либо форме доступа каким-либо третьим лицам.
        <br />
        3.2.3. Заказчик обязуется принять Услуги, оказанные Исполнителем;
        <br />
        3.2.4. Заказчик вправе потребовать от Исполнителя вернуть денежные средства за неоказанные
        услуги, некачественно оказанные услуги, услуги,оказанные с нарушением сроков оказания, а
        также, если Заказчик решил отказаться от услуг по причинам, не связанным с нарушением
        обязательств со стороны Исполнителя, исключительно по основаниям, предусмотренным
        действующим законодательством Российской Федерации.
        <br />
        3.2.5. Заказчик гарантирует, что все условия Договора ему понятны; Заказчик принимает
        условия без оговорок, а также в полном объеме.
        <br />
      </div>
      <div className="offer-description">
        <p className="title-desc">4. Цена и порядок расчетов</p>
      </div>
      <div className="offer-description-text">
        4.1.&nbsp; &nbsp;Стоимость услуг Исполнителя, оказываемых Заказчиком и порядок их оплаты,
        определяются на основании сведений Исполнителя при оформлении заявки Заказчиком либо
        устанавливаются на Сайте Исполнителя в сети «Интернет»: https://chatbothelper.ru
        <br />
        4.2.&nbsp; &nbsp;Все расчеты по Договору производятся в безналичном порядке. <br />
      </div>
      <div className="offer-description">
        <p className="title-desc">5. Конфиденциальность и безопасность</p>
      </div>
      <div className="offer-description-text">
        5.1.&nbsp; &nbsp;При реализации настоящего Договора Стороны обеспечивают конфиденциальность
        и безопасность персональных данных в соответствии с актуальной редакцией ФЗ от 27.07.2006 г.
        № 152-ФЗ «О персональных данных» и ФЗ от 27.07.2006 г. № 149-ФЗ «Об информации,
        информационных технологиях и о защите информации».
        <br />
        5.2.&nbsp; &nbsp;Стороны обязуются сохранять конфиденциальность информации, полученной в
        ходе исполнения настоящего Договора, и принять все возможные меры, чтобы предохранить
        полученную информацию от разглашения.
        <br />
        5.3.&nbsp; &nbsp;Под конфиденциальной информацией понимается любая информация, передаваемая
        Исполнителем и Заказчиком в процессе реализации Договора и подлежащая защите, исключения
        указаны ниже.
        <br />
        5.4.&nbsp; &nbsp;Такая информация может содержаться в предоставляемых Исполнителю локальных
        нормативных актах, договорах, письмах,отчетах, аналитических материалах, результатах
        исследований, схемах, графиках,спецификациях и других документах, оформленных как на
        бумажных, так и на электронных носителях.
        <br />
      </div>
      <div className="offer-description">
        <p className="title-desc">6. Форс-мажор</p>
      </div>
      <div className="offer-description-text">
        6.1.&nbsp; &nbsp;Стороны освобождаются от ответственности за неисполнение или ненадлежащее
        исполнение обязательств по Договору, если надлежащее исполнение оказалось невозможным в
        следствие непреодолимой силы, то есть чрезвычайных и непредотвратимых приданных условиях
        обстоятельств, под которыми понимаются: запретные действия властей, эпидемии, блокада,
        эмбарго, землетрясения, наводнения, пожары или другие стихийные бедствия.
        <br />
        6.2.&nbsp; &nbsp;В случае наступления этих обстоятельств Сторона обязана в течение 30
        (Тридцати) рабочих дней уведомить об этом другую Сторону.
        <br />
        6.3.&nbsp; &nbsp;Документ,выданный уполномоченным государственным органом, является
        достаточным подтверждением наличия и продолжительности действия непреодолимой силы.
        <br />
        6.4.&nbsp; &nbsp;Если обстоятельства непреодолимой силы продолжают действовать более 60
        (Шестидесяти)рабочих дней, то каждая Сторона вправе отказаться от настоящего Договора в
        одностороннем порядке.
        <br />
      </div>
      <div className="offer-description">
        <p className="title-desc">7. Ответственность Сторон</p>
      </div>
      <div className="offer-description-text">
        7.1.&nbsp; &nbsp;В случае неисполнения и/или ненадлежащего исполнения своих обязательств по
        Договору, Стороны несут ответственность в соответствии с условиями настоящей Оферты.
        <br />
        7.2.&nbsp; &nbsp;Исполнитель не несет ответственности за неисполнение и/или ненадлежащее
        исполнение обязательств по Договору, если такое неисполнение и/или ненадлежащее исполнение
        произошло по вине Заказчика.
        <br />
        7.3.&nbsp; &nbsp;Сторона, не исполнившая или ненадлежащим образом исполнившая обязательства
        по Договору, обязана возместить другой Стороне причиненные такими нарушениями убытки.
        <br />
      </div>
      <div className="offer-description">
        <p className="title-desc">8. Срок действия настоящей Оферты</p>
      </div>
      <div className="offer-description-text">
        8.1.&nbsp; &nbsp;Оферта вступает в силу с момента размещения на Сайте Исполнителя и
        действует до момента её отзыва Исполнителем.
        <br />
        8.2.&nbsp; &nbsp;Исполнитель оставляет за собой право внести изменения в условия Оферты
        и/или отозвать Оферту в любой момент по своему усмотрению.Сведения об изменении или отзыве
        Оферты доводятся до Заказчика по выбору Исполнителя посредством размещения на сайте
        Исполнителя в сети «Интернет», в Личном кабинете Заказчика, либо путем направления
        соответствующего уведомления на электронный или почтовый адрес, указанный Заказчиком при
        заключении Договора или в ходе его исполнения.
        <br />
        8.3.&nbsp; &nbsp;Договор вступает в силу с момента Акцепта условий Оферты Заказчиком и
        действует до полного исполнения Сторонами обязательств по Договору.
        <br />
        8.4.&nbsp; &nbsp;Изменения, внесенные Исполнителем в Договор и опубликованные на сайте в
        форме актуализированной Оферты, считаются принятыми Заказчиком в полном объеме.
        <br />
      </div>
      <div className="offer-description">
        <p className="title-desc">9. Дополнительные условия</p>
      </div>
      <div className="offer-description-text">
        9.1.&nbsp; &nbsp;Договор, его заключение и исполнение регулируется действующим
        законодательством Российской Федерации. Все вопросы, не урегулированные настоящей Офертой
        или урегулированные не полностью, регулируются в соответствии с материальным правом
        Российской Федерации.
        <br />
        9.2.&nbsp; &nbsp;В случае возникновения спора, который может возникнуть между Сторонами в
        ходе исполнения ими своих обязательств по Договору, заключенному на условиях настоящей
        Оферты, Стороны обязаны урегулировать спор мирным путем до начала судебного
        разбирательства.Судебное разбирательство осуществляется в соответствии с законодательством
        Российской Федерации. Споры или разногласия, по которым Стороны не достигли договоренности,
        подлежат разрешению в соответствии с законодательством РФ. Досудебный порядок урегулирования
        спора является обязательным.
        <br />
        9.3.&nbsp; &nbsp;В качестве языка Договора, заключаемого на условиях настоящей Оферты, а
        также языка, используемого при любом взаимодействии Сторон (включая ведение переписки,
        предоставление требований /уведомлений / разъяснений, предоставление документов и т. д.),
        Стороны определили русский язык.
        <br />
        9.4.&nbsp; &nbsp;Все документы, подлежащие предоставлению в соответствии с условиями
        настоящей Оферты, должны быть составлены на русском языке либо иметь перевод на русский
        язык, удостоверенный в установленном порядке.
        <br />
        9.5.&nbsp; &nbsp;Бездействие одной из Сторон в случае нарушения условий настоящей Оферты не
        лишает права заинтересованной Стороны осуществлять защиту своих интересов позднее, а также
        не означает отказа от своих прав в случае совершения одной из Сторон подобных либо сходных
        нарушений в будущем.
        <br />
        9.6.&nbsp; &nbsp;Если на Сайте Исполнителя в сети«Интернет» есть ссылки на другие веб-сайты
        и материалы третьих лиц, такие ссылки размещены исключительно в целях информирования, и
        Исполнитель не имеет контроля в отношении содержания таких сайтов или материалов.
        Исполнитель не несет ответственность за любые убытки или ущерб, которые могут возникнуть в
        результате использования таких ссылок.
        <br />
      </div>
      <div className="offer-description">
        <p className="title-desc">10. Реквизиты Исполнителя</p>
      </div>
      <div className="offer-description-text">
        Полное наименование: Иззука Диана Артуровна
        <br />
        ИНН: 505203374759ОГРН/ОГРНИП: 323508100444902
        <br />
        Контактный телефон: +7 926 890-65-42
        <br />
        Контактный e-mail: diana120404@yandex.ru
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};
