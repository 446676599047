import React, { ReactNode } from "react";
import { Layout } from "../Layout";
import { clsx } from "clsx";
import s from "./Block.module.scss";
import { Typography, VariantType } from "../../Typography/Typography";
import { ButtonProps } from "@mui/material";
import { TooltipMiu } from "../../TooltipMiu/TooltipMiu";

type BlockNewProps = {
  classNameWrapper?: string;
  title?: string;
  children?: ReactNode;
  classNameChildren?: string;
  variant?: VariantType;
  mb?: number | string;
  trigger?: React.ReactElement<ButtonProps>;
  tooltipTrigger?: string;
  borderNone?: boolean;
  classNameTitleBlock?: string;
};

export const Block = (props: BlockNewProps) => {
  const {
    classNameWrapper,
    classNameTitleBlock,
    title,
    children,
    variant = "H3",
    classNameChildren,
    borderNone,
    tooltipTrigger,
    mb,
    trigger,
  } = props;
  const classNames = {
    root: clsx(s.root, classNameWrapper && classNameWrapper, borderNone && s.borderNone),
    titleBlock: clsx(s.titleBlock, classNameTitleBlock),
    children: clsx(s.children, classNameChildren && classNameChildren),
    trigger: clsx(s.trigger),
    triggerBlock: clsx(s.triggerBlock),
  };

  return (
    <Layout className={classNames.root}>
      {title && (
        <div className={classNames.titleBlock}>
          <Typography style={{ marginBottom: mb }} variant={variant}>
            {title}
          </Typography>
          {trigger && (
            <div className={classNames.triggerBlock}>
              <TooltipMiu title={tooltipTrigger as string} placement={"top-end"}>
                {trigger}
              </TooltipMiu>
            </div>
          )}
        </div>
      )}
      <div className={classNames.children}>{children}</div>
    </Layout>
  );
};
