import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks/hooks";
import { ReactNode } from "react";

/** Запрет на доступ к роутингам при  */
type ProtectedRouteType = {
  redirectPath?: string;
  inverse?: boolean;
  children: ReactNode;
};

export const ProtectedRoute = ({
  redirectPath = "/login",
  inverse = false,
  children,
}: ProtectedRouteType): ReactNode => {
  const isLoggedIn = useAppSelector<boolean>((state) => state.authorization.me);
  const token = useAppSelector<string>((state) => state.authorization.login.access_token);
  if (!isLoggedIn || token === "") {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};
