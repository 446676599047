import React, { useEffect, useState } from "react";
import { clsx } from "clsx";
import s from "./SignIn.module.scss";
import { AuthContainer } from "../AuthContainer/AuthContainer";
import LockSharpIcon from "@mui/icons-material/LockSharp";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { authThunks } from "../auth-reducer";
import { ButtonMiu } from "../../../components/Buttons/Button/ButtonMiu";
import { Typography } from "../../../components/Typography/Typography";
import { validationSchemes } from "../../../common/validationSchemes/validationSchemes";
import { ControlledTextField } from "../../../components/TextField/controlled-textField";
import { PATCH } from "../../Pages";
import { ModalStatusReg } from "../ModalStatusReg/ModalStatusReg";
import { RegisterResType } from "../auth.api";

const schema = yup.object().shape({
  email: validationSchemes.auth.email,
  password: validationSchemes.auth.password,
});
type FormDataType = yup.InferType<typeof schema>;

export const SignIn = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openDialogStatusReg, setOpenDialogStatusReg] = useState(false);
  const statusRegistration = useAppSelector<RegisterResType>(
    (state) => state.authorization.registration,
  );
  const classNames = {
    root: clsx(s.signInBlock),
    form: clsx(s.formBlock),
    textField: clsx(s.textField),
    linkBlockResetPass: clsx(s.resetPass),
    linkResetPass: clsx(s.linkResetPass),
    button: clsx(s.btnSave),
    linkBlockRegister: clsx(s.linkBlockRegister),
    typographyLink: clsx(s.typographyLinkText),
    linkToRegister: clsx(s.linkToRegister),
  };

  const {
    control,
    handleSubmit,
    reset,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = (data: FormDataType) => {
    dispatch(authThunks.login(data))
      .unwrap()
      .then(() => {
        reset();
        navigate(PATCH.PROFILE);
      })
      .catch((e) => {
        resetField("password");
      });
  };

  useEffect(() => {
    if (statusRegistration.status === "AWAITING") {
      setOpenDialogStatusReg(true);
    }
  }, [statusRegistration.status]);

  const onClickCloseDialogModalHandler = () => {
    setOpenDialogStatusReg(false);
  };

  return (
    <div className={classNames.root}>
      <AuthContainer title={"Войти"} iconHeader={<LockSharpIcon />}>
        <form className={classNames.form} onSubmit={handleSubmit(onSubmit)}>
          <ControlledTextField
            placeholder={"Введите email"}
            label={"Введите email"}
            fullwidth
            control={control}
            name={"email"}
            error={errors.email && errors.email.message}
          />
          <ControlledTextField
            placeholder={"Введите пароль"}
            label={"Введите пароль"}
            fullwidth
            control={control}
            name={"password"}
            error={errors.password && errors.password.message}
            type={"password"}
          />
          <div className={classNames.linkBlockResetPass}>
            <Link className={classNames.linkResetPass} to={PATCH.AUTH.FORGOT_PASSWORD}>
              Восстановить пароль
            </Link>
          </div>
          <ButtonMiu
            title={"Войти"}
            variant={"outlined"}
            className={classNames.button}
            type={"submit"}
          />
        </form>
        <div className={classNames.linkBlockRegister}>
          <Typography className={classNames.typographyLink} variant={"Body2"}>
            Нет аккаунта? Пройдите регистрацию!
          </Typography>
          <Link className={classNames.linkToRegister} to={PATCH.AUTH.REGISTER}>
            Регистрация
          </Link>
        </div>
      </AuthContainer>
      <ModalStatusReg open={openDialogStatusReg} onClose={onClickCloseDialogModalHandler} />
    </div>
  );
};
