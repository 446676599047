import { instance } from "../../../common/api/api";
import { GetVkCommandResType } from "./vkAnswer/vkCommand/vk.command.api";

type ChatBotApiEndpointsType = {
  vkAutoAnswer: "vk/auto_answer/";
};

const chatBotApiEndpoints: ChatBotApiEndpointsType = {
  vkAutoAnswer: "vk/auto_answer/",
};

export const vkChatBotApi = {
  getAutoAnswer: (arg: GetAutoAnswerReqType) => {
    return instance.get<GetAutoAnswerResponseType[]>(
      chatBotApiEndpoints.vkAutoAnswer + `?account_id=${arg.account_id}`,
      { headers: { Authorization: `Bearer ${arg.token}` } },
    );
  },
  addAutoAnswer: (arg: AddedAutoAnswerReqType) => {
    return instance.post(chatBotApiEndpoints.vkAutoAnswer, arg.domainModel, {
      headers: { Authorization: `Bearer ${arg.token}` },
    });
  },
  updateAutoAnswer: (arg: UpdateAutoAnswerReqApi) => {
    return instance.put<UpdateAutoAnswerResDomainAPIType>(
      chatBotApiEndpoints.vkAutoAnswer + `${arg.id}`,
      arg.domainModel,
      {
        headers: { Authorization: `Bearer ${arg.token}` },
      },
    );
  },
  deleteAutoAnswer: (arg: { id: number; token: string }) => {
    return instance.delete<string>(chatBotApiEndpoints.vkAutoAnswer + `${arg.id}`, {
      headers: { Authorization: `Bearer ${arg.token}` },
    });
  },
  commandPreview: (arg: { id: number; token: string }) => {
    return instance.get<string>(chatBotApiEndpoints.vkAutoAnswer + `${arg.id}/commands_preview`, {
      headers: { Authorization: `Bearer ${arg.token}` },
    });
  },
};

export type GetAutoAnswerReqType = {
  account_id: number;
  token: string;
};
export type GetAutoAnswerResponseType = Omit<AddAutoAnswerResponseType, "token">;
export type AddAutoAnswerResponseType = {
  name: string;
  id: number;
  account_id: number;
  text: string;
  message_pause_seconds: number;
  auto_answers_setting: AutoAnswersSettingVkType;
  time_intervals: TimeIntervals;
  time_offset_from_utc: number;
  commands_status: boolean;
  commands_list: GetVkCommandResType[];
  commands_description: string;
  send_commands_list: boolean;
} & { token: string };

export type AutoAnswersSettingVkType = "OFF" | "TIME_INTERVAL" | "FIRST_MESSAGE";
export type TimeIntervalItemType = {
  end: string;
  start: string;
  next_day_end: boolean;
  is_active: boolean;
};
export type TimeIntervals = {
  [key: string]: TimeIntervalItemType;
};
export type UpdateAutoAnswerResDomainType = {
  name?: string;
  id?: number;
  account_id?: number;
  text?: string;
  message_pause_seconds?: number;
  auto_answers_setting?: AutoAnswersSettingVkType;
  time_intervals?: TimeIntervals;
  time_offset_from_utc?: number;
  commands_status?: boolean;
  commands_list?: GetVkCommandResType[];
  commands_description?: string;
  send_commands_list?: boolean;
};
export type UpdateAutoAnswerResDomainAPIType = {
  name: string;
  id: number;
  account_id: number;
  text: string;
  message_pause_seconds: number;
  auto_answers_setting: AutoAnswersSettingVkType;
  time_intervals: TimeIntervals;
  time_offset_from_utc: number;
  commands_status: boolean;
  commands_list: GetVkCommandResType[];
  commands_description: string;
  send_commands_list: boolean;
};

export type UpdateAutoAnswerReqDomainType = {
  accountId: number;
  id: number;
  token: string;
  domainModel: UpdateAutoAnswerResDomainType;
};

type UpdateAutoAnswerReqApi = Omit<UpdateAutoAnswerReqDomainType, "accountId">;

export type AddedAutoAnswerReqType = {
  token: string;
  domainModel: AddedAutoAnswerObject;
};
export type AddedAutoAnswerObject = {
  name: string;
  account_id: number;
  text: string;
  message_pause_seconds: number;
  auto_answers_setting: AutoAnswersSettingVkType;
  time_intervals: TimeIntervals;
  time_offset_from_utc: number;
  commands_status: boolean;
  commands_list: GetVkCommandResType[];
  commands_description: string;
  send_commands_list: boolean;
};
