import { errorTranslations } from "./errorUtils";

export const handlerStringWithRegEndpoints = (responseStr: string) => {
  // debugger
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  const foundEmails = responseStr?.match(emailRegex);

  if (errorTranslations[responseStr]) {
    return errorTranslations[responseStr];
  } else if (foundEmails && foundEmails.length > 0) {
    const foundEmail = foundEmails[0];
    const parts = responseStr.split(foundEmail);

    let postEmailText = parts[1];

    const errorTranslation = errorTranslations[postEmailText.trim()];
    if (errorTranslation) {
      return `${foundEmail} ${errorTranslation}`;
    } else {
      return `${foundEmail} ${postEmailText}`;
    }
  }
  return responseStr;
};
