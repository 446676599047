import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authActions, authThunks } from "../pages/Auth/auth-reducer";
import { getTokenInLocalStorage } from "../common/utils/localStorageUtils";
import { createAppAsyncThunk } from "../common/utils/createAppAsyncThunk";

export type RequestStatusType = "idle" | "loading" | "succeeded" | "failed";
export type ThemeType = "light" | "dark" | null;

const slice = createSlice({
  name: "app",
  initialState: {
    isInitialized: false,
    error: null as string | null,
    alerts: null as string | null, //различные оповещения(допустим о том что бы купить подписку)
    isLocalLoading: false,
    isGlobalLoading: false,
    actualDate: null as number | null, //актуальная дата,
    status: "idle" as RequestStatusType,
    theme: null as ThemeType,
  },
  reducers: {
    setTheme: (state, action: PayloadAction<{ typeTheme: ThemeType }>) => {
      state.theme = action.payload.typeTheme;
    },
    setIsInitialized: (state, action: PayloadAction<{ isInitialized: boolean }>) => {
      // console.log(current(state)); // показать стейт
      state.isInitialized = action.payload.isInitialized;
    },
    setIsLoading: (state, action: PayloadAction<{ isLocalLoading: boolean }>) => {
      state.isLocalLoading = action.payload.isLocalLoading;
    },
    setError: (state, action: PayloadAction<{ error: string | null }>) => {
      state.error = action.payload.error;
    },
    setAlerts: (state, action: PayloadAction<{ alerts: string | null }>) => {
      state.alerts = action.payload.alerts;
    },
    setActualDate: (state, action: PayloadAction<{ actualDate: null | number }>) => {
      state.actualDate = action.payload.actualDate;
    },
    setStatus: (state, action: PayloadAction<{ status: RequestStatusType }>) => {
      state.status = action.payload.status;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => {
        return action.type.endsWith("/pending");
      },
      (state) => {
        state.isGlobalLoading = true;
      },
    );
    builder.addMatcher(
      (action) => {
        return action.type.endsWith("/rejected");
      },
      (state) => {
        state.isGlobalLoading = false;
      },
    );
    builder.addMatcher(
      (action) => {
        return action.type.endsWith("/fulfilled");
      },
      (state) => {
        state.isGlobalLoading = false;
      },
    );
  },
});
export const initializeApp = createAppAsyncThunk("app/initializeApp", async (_, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  dispatch(appAction.setIsInitialized({ isInitialized: false }));
  const token = getTokenInLocalStorage("tokenChatBot");
  const actualDate = new Date().getTime();
  dispatch(authActions.getToken({ login: { access_token: token } }));
  dispatch(appAction.setActualDate({ actualDate: actualDate }));

  try {
    await dispatch(authThunks.profile({ token: token }));
    // dispatch(authActions.me({me: true}));
  } catch (error) {
    dispatch(authActions.me({ me: false }));
    rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsInitialized({ isInitialized: true }));
  }
});

export const appReducer = slice.reducer;
export const appAction = slice.actions;
export const appThunks = { initializeApp };
