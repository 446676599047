import { instance } from "../../common/api/api";

/** API АВТОРИЗАЦИИ */

type AuthEndpointsType = {
  reg: "user/reg";
  login: "user/token";
  profile: "user/profile";
  changePass: "user/change_password";
  resetPass: "user/request_password_reset";
  setNewPass: "user/set_new_password";
  confirmReg: "user/confirm_reg";
  reconfirmReg: "user/reconfirm_reg";
  requestDemo: "user/request_demo";
};

const authEndpoints: AuthEndpointsType = {
  reg: "user/reg",
  login: "user/token",
  profile: "user/profile",
  changePass: "user/change_password",
  resetPass: "user/request_password_reset",
  setNewPass: "user/set_new_password",
  confirmReg: "user/confirm_reg",
  requestDemo: "user/request_demo",
  reconfirmReg: "user/reconfirm_reg",
};

export const authApi = {
  register: (arg: RegisterReqType) => {
    return instance.post(authEndpoints.reg, arg);
  },
  login: (arg: RegisterReqType) => {
    //token
    return instance.post<LoginResType>(authEndpoints.login, arg);
  },
  profile: (token: { token: string }) => {
    return instance.get<ProfileResType>(authEndpoints.profile, {
      headers: { Authorization: `Bearer ${token.token}` },
    });
  },
  changePass: (arg: ChangePass) => {
    return instance.post(
      authEndpoints.changePass + `/?new_password=${arg.new_password}`,
      {},
      { headers: { Authorization: `Bearer ${arg.token}` } },
    );
  },
  resetPass: (email: string) => {
    return instance.get(authEndpoints.resetPass, {
      params: {
        email: email,
      },
    });
  },
  setNewPass: (arg: SetNewPassword) => {
    return instance.post<string>(authEndpoints.setNewPass, {
      email: arg.email,
      hash_string: arg.hash_string,
      new_password: arg.password,
    });
  },
  confirmReg: (arg: { token: string }) => {
    //2 этап
    return instance.post<confirmRegResponse>(
      authEndpoints.confirmReg,
      {},
      {
        params: {
          token: arg.token,
        },
      },
    );
  },
  reconfirmReg: (arg: { email: string }) => {
    return instance.post(
      authEndpoints.reconfirmReg,
      {},
      {
        params: {
          email: arg.email,
        },
      },
    );
  },
  requestDemo: (arg: { token: string }) => {
    return instance.get(authEndpoints.requestDemo, {
      headers: { Authorization: `Bearer ${arg.token}` },
    });
  },
};

export type confirmRegResponse = {
  id: number;
  email: string;
  has_access: boolean;
};
export type SetNewPassword = RegisterReqType & { hash_string: string };
export type RegisterReqType = {
  email: string;
  password: string;
};

// export type RegisterResType = {
//     statusText: any;
//     secure_id: number
//     email: string
//     has_access: boolean
//     has_used_demo: boolean
//     access_expiry_moment: string | null
// }
export type RegisterResType = {
  msg: string;
  status: StatusRegister;
};
export type StatusRegister = "AWAITING" | "EXISTS" | "OK";

export type LoginResType = {
  access_token: string;
  token_type: string;
  expires_in: number;
};
export type ProfileResType = {
  id: number;
  email: string;
  has_access: null | boolean;
  has_used_demo: null | boolean;
  access_expiry_moment: Date | string | null;
  avito_accounts: AvitoAccountType[];
  vk_accounts: VkAccountType[];
  is_admin: boolean;
};
export type VkAccountType = {
  id: number;
  user_id: number;
};
export type AvitoAccountType = {
  id: number;
  user_id: number;
  token_expires: number;
};
export type ChangePass = {
  new_password: string;
  token: string;
};
