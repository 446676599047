export const workingWithStrings = (
  str: string = "",
  replace: string = "",
  repValue: string,
): string => {
  if (str !== null && str !== undefined) {
    return str.replace(replace, repValue);
  } else {
    return "";
  }
};
