import React, { CSSProperties, ReactNode } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import s from "./DialogRadix.module.scss";
import { Typography } from "../../Typography/Typography";
import { clsx } from "clsx";
import { IcClose } from "../../Icon/IconComponents/IcClose";
import { IconButton } from "../../Buttons/IconButton";
import { ButtonMiu } from "../../Buttons/Button/ButtonMiu";
import { Separator } from "../../Separator/Separator";
import { TooltipMiu } from "../../TooltipMiu/TooltipMiu";

type DialogRadixProps = {
  asChild?: boolean;
  childOverflowOff?: boolean;
  separator?: boolean;
  title: string;
  description?: string;
  open: boolean;
  tooltipTrigger?: string;
  className?: string;
  children?: ReactNode;
  onOpenCloseDialog?: (open: boolean) => void;
  titleButtonConfirm?: string;
  style?: CSSProperties;
  typeButton?: "button" | "submit" | "reset" | undefined;
  onClickDialogConfirmOk?: (...args: any[]) => void;
  disabledButton?: boolean;
  classNameChildren?: string;
  trigger?: React.ReactElement;
  placementTooltipTrigger?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
};

export const DialogRadix = (props: DialogRadixProps) => {
  const {
    title,
    childOverflowOff,
    placementTooltipTrigger = "right",
    trigger,
    tooltipTrigger,
    description,
    classNameChildren,
    disabledButton,
    open,
    className,
    children,
    onOpenCloseDialog,
    style,
    titleButtonConfirm = "ок",
    typeButton = "button",
    onClickDialogConfirmOk,
    separator = true,
    asChild = true,
  } = props;

  const classNames = {
    blockTrigger: clsx(s.blockTrigger),
    titleBlock: clsx(s.titleBlock),
    titleAndBtn: clsx(s.titleAndBtn),
    dialogOverlay: clsx(s.dialogOverlay),
    dialogContent: clsx(s.dialogContent, className && className),
    dialogTitle: clsx(s.dialogTitle),
    title: clsx(s.title),
    dialogDescription: clsx(s.dialogDescription),
    descriptionTypography: clsx(s.descriptionTypography),
    btnBlockConfirm: clsx(s.btnBlockConfirm),
    btnIconClose: clsx(s.iconButton),
    separatorRoot: clsx(s.separatorRoot),
    children: clsx(s.children, classNameChildren, childOverflowOff && s.childOverflowOff),
  };

  const onClickBtnOkHandler = () => {
    onClickDialogConfirmOk?.();
  };

  return (
    <Dialog.Root open={open} onOpenChange={onOpenCloseDialog}>
      <Dialog.Portal>
        <Dialog.Overlay className={classNames.dialogOverlay} />
        <Dialog.Content className={classNames.dialogContent} style={style}>
          <div className={classNames.titleBlock}>
            <div className={classNames.titleAndBtn}>
              <div className={classNames.blockTrigger}>
                <Typography className={classNames.title} variant="Subtitle1">
                  <span>{title}</span>
                </Typography>
                <TooltipMiu placement={placementTooltipTrigger} title={tooltipTrigger!}>
                  {" "}
                  {trigger && trigger}
                </TooltipMiu>
              </div>
              <Dialog.Close
                className={classNames.btnIconClose}
                aria-label="Close"
                asChild={asChild}
              >
                <IconButton>
                  <IcClose size={1} />
                </IconButton>
              </Dialog.Close>
            </div>
          </div>
          {separator && <Separator />}
          {description && (
            <div className={classNames.dialogDescription}>
              <Typography asChild className={classNames.descriptionTypography} variant="Body2">
                <span>{description}</span>
              </Typography>
            </div>
          )}
          <div className={classNames.children}>{children}</div>
          {separator && <Separator />}
          <div className={classNames.btnBlockConfirm}>
            <ButtonMiu
              disabled={disabledButton}
              title={titleButtonConfirm}
              type={typeButton}
              callBack={onClickBtnOkHandler}
            />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
