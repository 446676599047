import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { AppRootStateType } from "../../app/store";
import { parsingDateInMilliseconds } from "../utils/DateProcessing";

/** Кастомный useDispatch (что бы не типизировать каждый раз при вызове) */
export type AppDispatch = ThunkDispatch<AppRootStateType, unknown, AnyAction>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

/** Кастомный useAppSelector (что бы не типизировать каждый раз при вызове) */
export const useAppSelector: TypedUseSelectorHook<AppRootStateType> = useSelector;

/** Кастомный useDisabledChatBot( для закрытия доступа к функционалу чат-бота) */
export const useDisabledChatBot = () => {
  const actualDate = useAppSelector((state) => state.app.actualDate);
  const isAccountAccess = useAppSelector((state) => state.authorization.profile);
  const dataExpiryMoment = parsingDateInMilliseconds(isAccountAccess.access_expiry_moment);

  let message = "";

  if (isAccountAccess.has_access !== true) {
    message = "Для взаимодействия с чат-ботом подтвердите аккаунт!";
  } else if (
    dataExpiryMoment === null &&
    (isAccountAccess.has_used_demo === null || isAccountAccess.has_used_demo)
  ) {
    message = "Необходимо приобрести подписку для доступа к функционалу!";
  } else if (dataExpiryMoment !== null && actualDate !== null && dataExpiryMoment < actualDate) {
    message = "Срок действия подписки истек. Пожалуйста, продлите подписку.";
  }

  const isDisabled = !!message;

  return { isDisabled, message };
};
