import React, { useRef, useState } from "react";
import s from "./EdiatableText.module.scss";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TooltipMiu } from "../TooltipMiu/TooltipMiu";
import { validationSchemes } from "../../common/validationSchemes/validationSchemes";
import { Typography } from "../Typography/Typography";

type EditableSpan = {
  text: string;
  onEditableSpanData: (text: string) => void;
};

const schema = yup.object().shape({
  text: validationSchemes.nameAutoAnswer,
});

type DataType = yup.InferType<typeof schema>;

export const EditableText: React.FC<EditableSpan> = (props) => {
  const { text, onEditableSpanData } = props;
  const [edit, setEdit] = useState(false);
  const originalText = useRef(text);
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (data: DataType) => {
    const trimmedText = data.text ? data.text.trim() : "";
    if (trimmedText === originalText.current) {
      return;
    }
    onEditableSpanData(trimmedText);
  };

  const onClickEditTextHandler = () => {
    setEdit(!edit);
  };
  const onBlurInputHandler = () => {
    handleSubmit(onSubmit)();
    setEdit(!edit);
  };

  return (
    <TooltipMiu title={"Отредактировать название автоответчика"} placement={"top"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          edit ? (
            <input
              className={s.inputText}
              defaultValue={text}
              {...register("text")}
              onBlur={onBlurInputHandler}
              autoFocus
              maxLength={15}
            />
          ) : (
            <Typography asChild variant={"Body2"}>
              <span className={s.spanText} onClick={onClickEditTextHandler}>
                {text}
              </span>
            </Typography>
          )
          // <span className={s.spanText} onClick={onClickEditTextHandler}>{text}</span>
        }
      </form>
    </TooltipMiu>
  );
};
