import { instance } from "../../../../../common/api/api";

type AvitoCommandEndpointsType = {
  vkCommand: "vk/command/";
};
const endpointsAvitoCommand: AvitoCommandEndpointsType = {
  vkCommand: "vk/command/",
};

export const vkCommandApi = {
  getVkCommand: (arg: GetVkCommandApiReqType) => {
    return instance.get<GetVkCommandResType[]>(
      `${endpointsAvitoCommand.vkCommand}?auto_answer_id=${arg.answer_id}`,
      {
        headers: { Authorization: `Bearer ${arg.token}` },
      },
    );
  },
  addVkCommand: (arg: AddVkCommandReqApiType) => {
    return instance.post(
      `${endpointsAvitoCommand.vkCommand}?auto_answer_id=${arg.answer_id}`,
      arg.data,
      {
        headers: { Authorization: `Bearer ${arg.token}` },
      },
    );
  },
  deleteVkCommand: (arg: DeleteVkCommandApi) => {
    // debugger
    return instance.delete<{ msg: string }>(`${endpointsAvitoCommand.vkCommand}` + arg.command_id, {
      headers: { Authorization: `Bearer ${arg.token}` },
    });
  },
  editCommandResponse: (arg: EditVkCommandApiReq) => {
    return instance.put<GetVkCommandResType>(`${endpointsAvitoCommand.vkCommand}`, arg.model, {
      headers: { Authorization: `Bearer ${arg.token}` },
    });
  },
};

type DeleteVkCommandApi = Omit<DeleteVkCommandDomain, "answer_id" | "account_id">;
export type DeleteVkCommandDomain = {
  command_id: number;
  account_id: number;
  answer_id: number;
  token: string;
};

export type GetVkCommandDomainReqType = Omit<AddVkCommandDomainReqType, "data">;
type GetVkCommandApiReqType = Omit<AddVkCommandDomainReqType, "data" | "account_id">;

export type GetVkCommandResType = {
  id: number;
  auto_answer_id: number;
  command_value: string;
  command_response: string;
  command_description: string | null;
  is_command_exact: false;
};

type AddVkCommandReqApiType = Omit<AddVkCommandDomainReqType, "account_id">;
export type AddVkCommandDomainReqType = {
  account_id: number;
  answer_id: number;
  token: string;
  data: AddVkCommandDataType;
};
export type AddVkCommandDataType = {
  command_value: string;
  command_response: string;
  command_description: string | null;
};

type EditVkCommandApiReq = Omit<EditVkCommandDomainReq, "accountId" | "answerId">;
export type EditVkCommandDomainReq = {
  accountId: number;
  answerId: number;
  token: string;
  model: ApiModelEditCommandReq;
};

export type ApiModelEditCommandReq = {
  id: number;
  command_value: string;
  command_response: string;
};
