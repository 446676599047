import { IconProps, IconWrapper } from "../IconWrapper/IconWrapper";

export const IcInfo = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      icon={
        <svg
          height={"100%"}
          viewBox={"0 0 52 52"}
          width={"100%"}
          xmlns={"http://www.w3.org/2000/svg"}
          {...props}
        >
          <path
            fill={"currentColor"}
            d="M25 2a23 23 0 1 0 0 46 23 23 0 0 0 0-46zm0 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm4 27h-8v-2h2V23h-2v-2h6v15h2v2z"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
