import React, { ForwardedRef, forwardRef, ReactNode, useContext } from "react";
import styled from "./ButtonMiu.module.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { Theme, ThemeContext } from "../../../common/hoc/ThemeProvider/ThemeContext";

type ButtonPropsType<T = void> = {
  title: string;
  callBack?: (...args: any[]) => T;
  variant?: "text" | "outlined" | "contained";
  className?: string;
  endCoin?: JSX.Element | null;
  inlineStyles?: object;
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  children?: ReactNode;
  style?: {};
  loading?: boolean;
  loadingPosition?: "start" | "end" | "center";
  tabIndex?: number;
};

export const ButtonMiu = forwardRef(
  (props: ButtonPropsType, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      title,
      variant = "outlined",
      className,
      callBack,
      endCoin,
      inlineStyles,
      color = "secondary",
      type = "button",
      disabled,
      children,
      style,
      loading,
      loadingPosition,
      tabIndex,
      ...otherProps
    } = props;

    const onclickHandler = () => {
      if (callBack) {
        callBack();
      }
    };

    const { theme } = useContext(ThemeContext);

    const styleButton = `${styled.button} ${className}`;

    const themeMiu = createTheme({
      palette: {
        secondary: {
          main: theme === Theme.DARK ? "#d0d0d0" : "#625b71",
          light: "#F5EBFF",
          contrastText: "#ffffff",
        },
      },
    });

    return (
      <ThemeProvider theme={themeMiu}>
        <LoadingButton
          className={styleButton}
          onClick={onclickHandler}
          variant={variant}
          endIcon={endCoin}
          color={color}
          type={type}
          disabled={disabled}
          style={style}
          loading={loading}
          loadingPosition={loadingPosition}
          ref={ref}
          tabIndex={tabIndex}
          {...otherProps}
        >
          <span>{title}</span>
        </LoadingButton>
      </ThemeProvider>
    );
  },
);

// export const ButtonMiu: React.FC<ButtonPropsType> = (props) => {
//
//     const {
//         title
//         , variant = 'outlined'
//         , className
//         , callBack,
//         endCoin,
//         inlineStyles,
//         color = 'secondary',
//         type = 'button',
//         disabled,
//         children,
//         style,
//         loading ,
//         loadingPosition ,
//         ref,
//         ...otherProps
//     } = props
//
//     const onclickHandler = () => {
//         if (callBack) {
//             callBack()
//         }
//     }
//
//     const styleButton = `${styled.button} ${className}`
//
//     const theme = createTheme({
//         palette: {
//             secondary: {
//                 main: '#625b71',
//                 light: '#F5EBFF',
//                 contrastText: '#ffffff',
//             }
//         },
//     });
//
//     return (
//         <ThemeProvider theme={theme}>
//             <LoadingButton
//                 className={styleButton}
//                 onClick={onclickHandler}
//                 variant={variant}
//                 endIcon={endCoin}
//                 color={color}
//                 type={type}
//                 disabled={disabled}
//                 style={style}
//                 loading={loading}
//                 loadingPosition={loadingPosition}
//                 ref={ref}
//                 {...otherProps}
//             >
//                 <span>{title}</span>
//             </LoadingButton>
//         </ThemeProvider>
//     );
// };
