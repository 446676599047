import React, {useContext, useEffect} from "react";
import "./App.scss";
import {Content} from "components/Content/Content";
import {Header} from "components/HeaderNew/Header";
import {LinearLoading} from "components/Loadings/LinearLoading";
import {ErrorComponent} from "components/ErrorsComponent/ErrorComponent";
import {useAppDispatch, useAppSelector} from "common/hooks/hooks";
import {appThunks} from "./app-reducer";
import {Loading} from "components/Loadings/Loading";
import {AlertsComponent} from "components/AlertsComponent/AlertsComponent";
import {useSelector} from "react-redux";
import {AppRootStateType} from "./store";
import "./scrollContainer.scss";
import {ThemeProvider} from "common/hoc/ThemeProvider/ThemeProvider";
import {AccessChecker} from "components/AccessChecker/AccessChecker";
import {Footer} from "components/Footer/Footer";
import {Separator} from "components/Separator/Separator";
import {ThemeContext} from "../common/hoc/ThemeProvider/ThemeContext";

function App() {
  const isAppInitialized = useAppSelector<boolean>((state) => state.app.isInitialized);
  const dispatch = useAppDispatch();
  const alerts = useAppSelector<string | null>((state) => state.app.alerts);
  const authError = useSelector<AppRootStateType, string | null>((state) => state.app.error);


  useEffect(() => {
    dispatch(appThunks.initializeApp());
  }, [dispatch]);



  //update

  if (!isAppInitialized) {
    return (
      <div style={{ position: "fixed", top: "30%", textAlign: "center", width: "100%" }}>
        <Loading size={50} sx={{ color: "gray" }} />
      </div>
    );
  }

  return (
    <ThemeProvider>
      <div className={`App ${isAppInitialized ? "appInitialized" : ""}`}>
        <div className={"scrollContainer"}>
          <div className={"appContent"}>
            <div className={"fixed"}>
              <Header />
            </div>
            <div className={"headerPlaceholder"}></div>
            <div className={"linearBlock"}>
              <div className={"linearLoading"}>
                <LinearLoading />
              </div>
            </div>
            <Content />
            {/*<Separator colorSeparator={theme === Theme.DARK  ? 'dark' : 'light'} />*/}
            <Separator/>
            <AccessChecker />
            {authError && <ErrorComponent />}
            {alerts && <AlertsComponent />}
            <Footer />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
