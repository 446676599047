import React from "react";
import styled from "./Content.module.scss";
import { Pages } from "../../pages/Pages";

export const Content = () => {
  return (
    <main>
      <div className={styled.mainWrapper}>
        <Pages />
      </div>
    </main>
  );
};
