import React, { useContext, useEffect } from "react";
import s from "./AvitoChatBot.module.scss";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/hooks";
import { AvitoAnswers, thunkAvitoChatBot } from "./avitoChatBotReducer";
import { AvitoAccountType, ProfileResType } from "../../Auth/auth.api";
import AvitoAnswer from "./AvitoAnswer/AvitoAnswer";
import avitoIcon from "../../../common/assets/icons/svg/avito/avitoIcon.svg";
import avitoText from "../../../common/assets/icons/svg/avito/avitoText.svg";
import { ButtonMiu } from "../../../components/Buttons/Button/ButtonMiu";
import EmailSharpIcon from "@mui/icons-material/EmailSharp";
import { Block } from "../../../components/Layout/Block/Block";
import { Theme, ThemeContext } from "../../../common/hoc/ThemeProvider/ThemeContext";
import avitoTextWhite from "../../../common/assets/icons/svg/avito/avitoTextWhite.svg";
import { useNavigate } from "react-router-dom";
import { PATCH } from "../../Pages";
import { avitoAccountActions } from "../../Account/AvitoAccount/avitoAccountReducer";
import { PaginationTabs } from "../../../components/Paginations/PaginationTabs/PaginationTabs";
import { TooltipMiu } from "../../../components/TooltipMiu/TooltipMiu";

type AvitoChatBotType = {
  disabled?: boolean | null;
};

export const AvitoChatBot: React.FC<AvitoChatBotType> = (props) => {
  const { disabled } = props;
  const dispatch = useAppDispatch();
  const { theme } = useContext(ThemeContext);
  const profile = useAppSelector<ProfileResType>((state) => state.authorization.profile);
  const navigate = useNavigate();

  useEffect(() => {
    if (profile?.avito_accounts && profile.avito_accounts.length > 0) {
      const accountIds = profile.avito_accounts.map((acc) => acc.id);
      dispatch(thunkAvitoChatBot.getAutoAnswer({ account_ids: accountIds }));
    }
  }, [dispatch, profile.avito_accounts]);

  /** <Pagination> */
  const portionPage = useAppSelector<number>((state) => state.avitoAccount.pagination.portionPage);
  const currentPage = useAppSelector<number>((state) => state.avitoAccount.pagination.currentPage);
  const totalCount = useAppSelector<number>((state) => state.avitoAccount.pagination.totalCount);
  const limit = useAppSelector<number>((state) => state.avitoAccount.pagination.limit);

  const vkAccount = useAppSelector<AvitoAccountType[]>(
    (state) => state.authorization.profile.avito_accounts,
  );
  const currentAccountId = vkAccount[currentPage - 1]?.id.toString();

  const avitoAnswers = useAppSelector<AvitoAnswers>(
    (state) => state.avitoChatBot.avitoChatBot.avitoAnswer,
  );
  const autoAnswers = avitoAnswers[currentAccountId];
  /** </Pagination> */

  const navigateToProfile = () => {
    navigate(PATCH.PROFILE);
  };

  const onClickSetCurrentPage = (currentPage: number) => {
    dispatch(avitoAccountActions.setCurrentPage({ currentPage: currentPage }));
  };

  return (
    <TooltipMiu
      title={disabled ? "Чат-бот недоступен" : ""}
      followCursor={!!disabled}
      className={s.chatBotDisabledTooltip}
    >
      <div className={disabled ? `${s.chatBot} ${s.disabled}` : s.chatBot}>
        {profile?.avito_accounts && profile.avito_accounts.length > 0 ? (
          <React.Fragment>
            <PaginationTabs
              classNameWrapper={s.paginationWrapper}
              elementTabClassName={s.paginationTab}
              activeTabClassName={s.activeTab}
              classNamePage={s.page}
              array={vkAccount}
              tabNameProperty={"id"}
              totalCount={totalCount}
              currentPages={currentPage}
              pageLimit={limit}
              portionPage={portionPage}
              onClickPagination={onClickSetCurrentPage}
            />
            <Block
              borderNone
              title={"Автоответчик"}
              variant={"Large"}
              mb={"20px"}
              classNameWrapper={s.layout}
            >
              {currentAccountId && (
                <React.Fragment key={currentAccountId}>
                  <AvitoAnswer avitoAccId={currentAccountId} autoAnswers={autoAnswers || []} />
                </React.Fragment>
              )}
            </Block>
          </React.Fragment>
        ) : (
          <Block classNameChildren={s.blockAddAccChildren}>
            <div className={s.avitoIcon}>
              {theme === Theme.DARK ? (
                <>
                  <img src={avitoIcon} alt="avitoIcon" />
                  <img src={avitoTextWhite} alt="avitoText" />
                </>
              ) : (
                <>
                  <img src={avitoIcon} alt="avitoIcon" />
                  <img src={avitoText} alt="avitoText" />
                </>
              )}
            </div>
            <ButtonMiu
              endCoin={<EmailSharpIcon />}
              title={"Привязать аккаунт авито"}
              callBack={navigateToProfile}
            />
          </Block>
        )}
      </div>
    </TooltipMiu>
  );
};
