import { instance } from "../../../../../common/api/api";

type AvitoCommandEndpointsType = {
  avitoCommand: "avito/command/";
};
const endpointsAvitoCommand: AvitoCommandEndpointsType = {
  avitoCommand: "avito/command/",
};

export const avitoCommandApi = {
  getAvitoCommand: (arg: GetAvitoCommandReqDomain) => {
    return instance.get<GetAvitoCommandResType[]>(
      `${endpointsAvitoCommand.avitoCommand}?auto_answer_id=${arg.answer_id}`,
      {
        headers: { Authorization: `Bearer ${arg.token}` },
      },
    );
  },
  addAvitoCommand: (arg: AddAvitoCommandReqDomain) => {
    return instance.post(
      `${endpointsAvitoCommand.avitoCommand}?auto_answer_id=${arg.answer_id}`,
      arg.data,
      {
        headers: { Authorization: `Bearer ${arg.token}` },
      },
    );
  },
  deleteAvitoCommand: (arg: DeleteAvitoCommandApi) => {
    return instance.delete<{ msg: string }>(
      `${endpointsAvitoCommand.avitoCommand}` + arg.command_id,
      { headers: { Authorization: `Bearer ${arg.token}` } },
    );
  },
  editCommandResponse: (arg: EditAvitoCommandReqApi) => {
    return instance.put<GetAvitoCommandResType>(
      `${endpointsAvitoCommand.avitoCommand}`,
      arg.model,
      {
        headers: { Authorization: `Bearer ${arg.token}` },
      },
    );
  },
};

export type GetAvitoCommandReqDomain = Omit<AddAvitoCommandReqDomain, "data">;
export type AddAvitoCommandReqDomain = {
  answer_id: number;
  token: string;
  data: AddAvitoCommandDataType;
};

type DeleteAvitoCommandApi = Omit<DeleteAvitoCommandDomain, "answer_id">;
export type DeleteAvitoCommandDomain = {
  answer_id: number;
  command_id: number;
  token: string;
};

type EditAvitoCommandReqApi = Omit<EditAvitoCommandReqDomain, "answerId">;
export type EditAvitoCommandReqDomain = {
  answerId: number;
  token: string;
  model: ApiModelEditCommandReq;
};

export type GetAvitoCommandResType = {
  id: number;
  auto_answer_id: number;
  command_value: string;
  command_response: string;
  command_description: string | null;
};

export type AddAvitoCommandDataType = {
  command_value: string;
  command_response: string;
  command_description: string | null;
};

export type ApiModelEditCommandReq = {
  id: number;
  command_value: string;
  command_response: string;
};

export type EditAvitoCommandModel = {
  token: string;
  model: ApiModelEditCommandModel;
};
export type ApiModelEditCommandModel = {
  id: number;
  command_value?: string;
  command_response?: string;
};
