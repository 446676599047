import React from "react";
import { Block } from "../../components/Layout/Block/Block";
import { clsx } from "clsx";
import s from "./Subscription.module.scss";
import { Typography } from "../../components/Typography/Typography";
import { ButtonMiu } from "../../components/Buttons/Button/ButtonMiu";
import { useAppDispatch, useAppSelector } from "../../common/hooks/hooks";
import { subscriptionThunks } from "./subscription-reducer";
import { DaySubscription } from "./subscription-api";

export const Subscription = () => {
  const classNames = {
    layout: clsx(s.layoutBlock),
    variantsSubscribe: clsx(s.variantsSubscribe),
    cardWrapper: clsx(s.cardWrapper),
    price: clsx(s.price),
    priceValue: clsx(s.priceValue),
    discount: clsx(s.discount),
    discountText: clsx(s.discountText),
    discountSpan: clsx(s.discountSpan),
    cardList: clsx(s.cardList),
    buttonBlock: clsx(s.buttonBlock),
    btn: clsx(s.btn),
    response: clsx(s.response),
  };

  const token = useAppSelector<string>((state) => state.authorization.login.access_token);
  const disabledBtns = useAppSelector<boolean>(
    (state) => state.subscription.subscriptionGet.disabledBtn,
  );

  const dispatch = useAppDispatch();
  // const navigate = useNavigate()

  const onClickGetSubscriptionOneMonth = (days: DaySubscription) => {
    dispatch(subscriptionThunks.subscriptionGet({ days, token }));
  };
  const onClickGetSubscriptionSixMonth = (days: DaySubscription) => {
    dispatch(subscriptionThunks.subscriptionGet({ days, token }));
  };
  const onClickGetSubscriptionThreeMonth = (days: DaySubscription) => {
    dispatch(subscriptionThunks.subscriptionGet({ days, token }));
  };

  return (
    <Block
      borderNone
      mb={"20px"}
      variant={"Large"}
      title={"Управление подпиской"}
      classNameWrapper={classNames.layout}
    >
      <div className={classNames.variantsSubscribe}>
        <Block classNameWrapper={classNames.cardWrapper} title={"1 месяц"}>
          <div className={classNames.response}>
            <Typography className={classNames.priceValue} variant={"Large"}>
              790 ₽
            </Typography>
            <div className={`${classNames.cardList} ${s.cardListOne}`}>
              <ul>
                <li>Моментальные ответы в любое время</li>
                <li>Гибкое управление графиком работы</li>
                <li>Пробный период — 7 дней</li>
              </ul>
            </div>
          </div>
          <div className={classNames.buttonBlock}>
            <ButtonMiu
              loading={disabledBtns}
              disabled={disabledBtns}
              callBack={() => {
                onClickGetSubscriptionOneMonth(30);
              }}
              className={classNames.btn}
              title={"Приобрести"}
            />
          </div>
        </Block>
        <Block classNameWrapper={classNames.cardWrapper} title={"6 месяцев"}>
          <div className={classNames.response}>
            <div>
              <div className={classNames.price}>
                <Typography className={classNames.priceValue} variant={"Large"}>
                  3790 ₽
                </Typography>
                <div className={classNames.discount}>
                  <Typography className={classNames.discountText} variant={"Body2"}>
                    -20%
                  </Typography>
                </div>
              </div>
              <Typography className={classNames.discountSpan}>4740 ₽</Typography>
            </div>
            <div className={classNames.cardList}>
              <ul>
                <li>Моментальные ответы в любое время</li>
                <li>Гибкое управление графиком работы</li>
                <li>Пробный период — 7 дней</li>
              </ul>
            </div>
          </div>
          <div className={classNames.buttonBlock}>
            <ButtonMiu
              loading={disabledBtns}
              disabled={disabledBtns}
              callBack={() => {
                onClickGetSubscriptionSixMonth(180);
              }}
              className={classNames.btn}
              title={"Приобрести"}
            />
          </div>
        </Block>
        <Block classNameWrapper={classNames.cardWrapper} title={"3 месяца"}>
          <div className={classNames.response}>
            <div>
              <div className={classNames.price}>
                <Typography className={classNames.priceValue} variant={"Large"}>
                  2150 ₽
                </Typography>
                <div className={classNames.discount}>
                  <Typography className={classNames.discountText} variant={"Body2"}>
                    -10%
                  </Typography>
                </div>
              </div>
              <Typography className={classNames.discountSpan}>2400 ₽</Typography>
            </div>
            <div className={classNames.cardList}>
              <ul>
                <li>Моментальные ответы в любое время</li>
                <li>Гибкое управление графиком работы</li>
                <li>Пробный период — 7 дней</li>
              </ul>
            </div>
          </div>
          <div className={classNames.buttonBlock}>
            <ButtonMiu
              loading={disabledBtns}
              disabled={disabledBtns}
              callBack={() => {
                onClickGetSubscriptionThreeMonth(90);
              }}
              className={classNames.btn}
              title={"Приобрести"}
            />
          </div>
        </Block>
      </div>
    </Block>
  );
};
