import React, { useState } from "react";
import s from "./SignUp.module.scss";
import * as yup from "yup";
import { useAppDispatch } from "../../../common/hooks/hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { authThunks } from "../auth-reducer";
import HowToRegSharpIcon from "@mui/icons-material/HowToRegSharp";
import { AuthContainer } from "../AuthContainer/AuthContainer";
import { clsx } from "clsx";
import { ButtonMiu } from "../../../components/Buttons/Button/ButtonMiu";
import { Typography } from "../../../components/Typography/Typography";
import { Link } from "react-router-dom";
import { validationSchemes } from "../../../common/validationSchemes/validationSchemes";
import { ControlledTextField } from "../../../components/TextField/controlled-textField";
import { ModalStatusReg } from "../ModalStatusReg/ModalStatusReg";

const schema = yup.object().shape({
  email: validationSchemes.auth.email,
  password: validationSchemes.auth.password,
  confirmPassword: validationSchemes.auth.confirmPassword,
});

type FormDataType = yup.InferType<typeof schema>;
export const SignUp = () => {
  const classNames = {
    root: clsx(s.signUpBlock),
    form: clsx(s.formBlock),
    textField: clsx(s.textField),
    button: clsx(s.btnSave),
    typography: clsx(s.typography),
    link: clsx(s.linkBlock),
    typographyLink: clsx(s.typographyLinkText),
    linkToLogin: clsx(s.linkToLogin),
  };
  const dispatch = useAppDispatch();
  const [openDialogStatusReg, setOpenDialogStatusRef] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormDataType>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
  });
  const onSubmit = (data: FormDataType) => {
    dispatch(authThunks.registration(data))
      .unwrap()
      .then(() => {
        setOpenDialogStatusRef(true);
      })
      .catch((e) => {
        console.log(e);
      });
    reset();
  };

  const onClickCloseDialogModalHandler = () => {
    setOpenDialogStatusRef(false);
  };

  return (
    <div className={classNames.root}>
      <AuthContainer title={"Регистрация"} iconHeader={<HowToRegSharpIcon />}>
        <form onSubmit={handleSubmit(onSubmit)} className={classNames.form}>
          <ControlledTextField
            fullwidth
            control={control}
            name={"email"}
            label={"Введите email"}
            placeholder={"Введите email"}
            error={errors.email && errors.email.message}
          />
          <ControlledTextField
            fullwidth
            control={control}
            name={"password"}
            label={"Введите пароль"}
            type={"password"}
            placeholder={"Введите пароль"}
            error={errors.password && errors.password.message}
          />
          <ControlledTextField
            fullwidth
            control={control}
            name={"confirmPassword"}
            type={"password"}
            label={"Повторите пароль"}
            placeholder={"Повторите пароль"}
            error={errors.confirmPassword && errors.confirmPassword.message}
          />
          <ButtonMiu className={classNames.button} type={"submit"} title={"Зарегистрироваться"} />
        </form>
        <div className={classNames.link}>
          <Typography className={classNames.typography} variant={"Body2"}>
            Войти в аккаунт
          </Typography>
          <Link className={classNames.linkToLogin} to={"/login"}>
            Войти
          </Link>
        </div>
      </AuthContainer>
      <ModalStatusReg open={openDialogStatusReg} onClose={onClickCloseDialogModalHandler} />
    </div>
  );
};
