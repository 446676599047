import React from "react";
import { IconProps, IconWrapper } from "../IconWrapper/IconWrapper";

export const IcReconnection = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={"100%"}
          height={"100%"}
          viewBox={"0 0 30 30"}
          {...props}
        >
          <path
            fill={"currentColor"}
            d="M15 3c-3 0-5.7 1-7.8 2.9a1 1 0 1 0 1.3 1.5A10 10 0 0 1 25 14h-3l4 6 4-6h-3A12 12 0 0 0 15 3zM4 10l-4 6h3a12 12 0 0 0 19.8 8.1 1 1 0 1 0-1.3-1.5A10 10 0 0 1 5 16h3l-4-6z"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
