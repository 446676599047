import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import s from "./Customized.module.scss";
import { JSXElementConstructor, ReactElement } from "react";

export type TabItem = {
  icon?: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
  label?: string;
  component: React.ReactNode;
  onTabChange?: (tabType: number) => void;
  link?: string;
};
type CustomizedTabsPropsType = {
  tabs: TabItem[];
  disabled?: boolean | null;
  onTabChange?: (tabType: string) => void;
  link?: string;
};
export const CustomizedTabs: React.FC<CustomizedTabsPropsType> = (props) => {
  const [value, setValue] = React.useState(0);
  const { tabs, disabled } = props;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ bgColor: "#ffffff" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
          variant="scrollable"
          indicatorColor="primary"
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} icon={tab.icon} disabled={disabled!} />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={s.tabComponents}
            style={{ display: value === index ? "block" : "none" }}
          >
            {tab.component}
          </div>
        ))}
      </Box>
    </Box>
  );
};
