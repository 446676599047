import React, { useCallback, useContext, useRef, useState } from "react";
import { AvitoCommand } from "./AvitoCommand/AvitoCommand";
import {
  AdsListModeType,
  AutoAnswersSettingType,
  GetAutoAnswerResponseType,
  TimeIntervals,
} from "../avito.chatBot.api";
import {
  AvitoAnswersPagination,
  avitoChatBotAction,
  thunkAvitoChatBot,
} from "../avitoChatBotReducer";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/hooks";
import AvitoBasicSettings from "./AvitoBasicSettings/AvitoBasicSettings";
import AvitoMessageText from "./AvitoMessageText/AvitoMessageText";
import avitoIcon from "../../../../common/assets/icons/svg/avito/avitoIcon.svg";
import avitoText from "../../../../common/assets/icons/svg/avito/avitoText.svg";
import avitoTextWhite from "../../../../common/assets/icons/svg/avito/avitoTextWhite.svg";
import s from "./AvitoAnswer.module.scss";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProfileResType } from "../../../Auth/auth.api";
import { TooltipMiu } from "../../../../components/TooltipMiu/TooltipMiu";
import DeleteIcon from "@mui/icons-material/Delete";
import { PaginationTabs } from "../../../../components/Paginations/PaginationTabs/PaginationTabs";
import { Pagination } from "../../../../components/Paginations/Pagination/Pagination";
import { AdsList } from "./AdsList/AdsList";
import AvitoSchedule from "./AvitoSchedule/AvitoShedule";
import { ControlledTextField } from "../../../../components/TextField/controlled-textField";
import { Block } from "../../../../components/Layout/Block/Block";
import IconButton from "@mui/material/IconButton";
import { validationSchemes } from "../../../../common/validationSchemes/validationSchemes";
import { appAction } from "../../../../app/app-reducer";
import { DialogRadix } from "../../../../components/Dialog/DialogRadix/DialogRadix";
import { Theme, ThemeContext } from "../../../../common/hoc/ThemeProvider/ThemeContext";
import { Loading } from "../../../../components/Loadings/Loading";

type AvitoAnswerType = {
  avitoAccId: string;
  autoAnswers: GetAutoAnswerResponseType[];
};
const schema = yup.object().shape({
  nameAutoAnswer: validationSchemes.nameAutoAnswer,
});
type DataType = yup.InferType<typeof schema>;

const AvitoAnswer: React.FC<AvitoAnswerType> = (props) => {
  const { autoAnswers, avitoAccId } = props;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModalAddAutoAnswer, setOpenModalAddAutoAnswer] = useState(false);
  const { theme } = useContext(ThemeContext);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      nameAutoAnswer: "",
    },
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const formRef = useRef<HTMLFormElement>(null);
  const dispatch = useAppDispatch();
  const token = useAppSelector<string>((state) => state.authorization.login.access_token);
  const profile = useAppSelector<ProfileResType>((state) => state.authorization.profile);
  const globalLoading = useAppSelector<boolean>((state) => state.app.isGlobalLoading);
  const isAdmin = useAppSelector<boolean>((state) => state.authorization.profile.is_admin);
  const localLoading = useAppSelector<boolean>((state) => state.app.isLocalLoading);

  /** <PAGINATION> */
  const answersCurrentPages = useAppSelector<AvitoAnswersPagination>(
    (state) => state.avitoChatBot.avitoChatBot.avitoAnswerPagination,
  );
  const answersElements = useAppSelector<AvitoAnswersPagination>(
    (state) => state.avitoChatBot.avitoChatBot.avitoAnswerPagination,
  );
  const limit = useAppSelector<number>((state) => state.avitoChatBot.avitoChatBot.limit);
  const portionPage = useAppSelector<number>(
    (state) => state.avitoChatBot.avitoChatBot.portionPage,
  );
  const currentPage = answersCurrentPages[avitoAccId]?.currentPage;
  const totalCount = answersElements[avitoAccId]?.totalCount;
  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;
  const itemsForCurrentPage = autoAnswers.slice(startIndex, endIndex);
  /** </PAGINATION> */

  const onSubmit = (data: DataType) => {
    const trimmedText = data.nameAutoAnswer?.trim();
    if (profile?.avito_accounts) {
      dispatch(
        thunkAvitoChatBot.addedAvitoAnswer({ avitoAccId: Number(avitoAccId), name: trimmedText }),
      );
    }
    reset();
  };
  const handleDialogConfirm = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
  };

  const onChangeSendCommandList = useCallback(
    (id: number, sendCommand: boolean) => {
      dispatch(
        avitoChatBotAction.setLoadingAndDisabledSystem({ key: "send_commands_list", value: true }),
      );
      dispatch(
        thunkAvitoChatBot.updateAutoAnswer({
          avitoAccId,
          id,
          token,
          domainModel: { send_commands_list: sendCommand },
        }),
      )
        .unwrap()
        .catch((e) => {
          throw new Error(e);
        })
        .finally(() => {
          dispatch(
            avitoChatBotAction.setLoadingAndDisabledSystem({
              key: "send_commands_list",
              value: false,
            }),
          );
        });
    },
    [avitoAccId, dispatch, token],
  );

  const onChangeSelectMessagePause = useCallback(
    (avitoAccId: string, id: number, time: number) => {
      dispatch(avitoChatBotAction.setLoadingAndDisabledSystem({ key: "pause", value: true }));
      dispatch(
        thunkAvitoChatBot.updateAutoAnswer({
          avitoAccId,
          id,
          token,
          domainModel: { message_pause_seconds: time },
        }),
      )
        .unwrap()
        .catch((e) => {
          throw new Error(e);
        })
        .finally(() => {
          dispatch(avitoChatBotAction.setLoadingAndDisabledSystem({ key: "pause", value: false }));
          dispatch(appAction.setAlerts({ alerts: "Пауза успешно сохранена" }));
        });
    },
    [dispatch, token],
  );

  const onChangeAdsListMode = useCallback(
    (avitoAccId: string, id: number, mode: AdsListModeType) => {
      dispatch(
        avitoChatBotAction.setLoadingAndDisabledSystem({ key: "ads_list_Mode", value: true }),
      );
      dispatch(
        thunkAvitoChatBot.updateAutoAnswer({
          avitoAccId,
          id,
          token,
          domainModel: { ads_list_mode: mode },
        }),
      )
        .unwrap()
        .catch((e) => {
          throw new Error(e);
        })
        .finally(() => {
          dispatch(
            avitoChatBotAction.setLoadingAndDisabledSystem({ key: "ads_list_Mode", value: false }),
          );
        });
    },
    [dispatch, token],
  );

  const onChangeAutoAnswersSetting = useCallback(
    (avitoAccId: string, id: number, autoAnswerSetting: AutoAnswersSettingType) => {
      dispatch(
        avitoChatBotAction.setLoadingAndDisabledSystem({
          key: "auto_answers_setting",
          value: true,
        }),
      );
      dispatch(
        thunkAvitoChatBot.updateAutoAnswer({
          avitoAccId,
          id,
          token,
          domainModel: { auto_answers_setting: autoAnswerSetting },
        }),
      )
        .unwrap()
        .catch((e) => {
          throw new Error(e);
        })
        .finally(() => {
          dispatch(
            avitoChatBotAction.setLoadingAndDisabledSystem({
              key: "auto_answers_setting",
              value: false,
            }),
          );
        });
    },
    [dispatch, token],
  );

  const onChangeTimeOffsetFromUTC = useCallback(
    (avitoAccId: string, id: number, utc: number) => {
      dispatch(
        avitoChatBotAction.setLoadingAndDisabledSystem({
          key: "time_offset_from_utc",
          value: true,
        }),
      );
      dispatch(
        thunkAvitoChatBot.updateAutoAnswer({
          avitoAccId,
          id: id,
          token: token,
          domainModel: { time_offset_from_utc: utc },
        }),
      )
        .unwrap()
        .catch((e) => {
          throw new Error(e);
        })
        .finally(() => {
          dispatch(
            avitoChatBotAction.setLoadingAndDisabledSystem({
              key: "time_offset_from_utc",
              value: false,
            }),
          );
        });
    },
    [dispatch, token],
  );

  const addedAdsList = useCallback(
    (avitoAccId: string, id: number, adsList: string) => {
      dispatch(thunkAvitoChatBot.addedAdsList({ avitoAccId, id, token, ads_list_str: adsList }));
    },
    [dispatch, token],
  );

  const removeElementAdsList = useCallback(
    (avitoAccId: string, id: number, elementRemove: string) => {
      dispatch(
        thunkAvitoChatBot.removedAdsList({ avitoAccId, id, token, ads_list_str: elementRemove }),
      );
    },
    [dispatch, token],
  );

  const updateTimeIntervals = useCallback(
    (avitoAccId: string, id: number, timeInterval: TimeIntervals) => {
      dispatch(
        thunkAvitoChatBot.updateAutoAnswer({
          avitoAccId,
          id,
          token,
          domainModel: { time_intervals: timeInterval },
        }),
      );
    },
    [dispatch, token],
  );

  const messageText = useCallback(
    (avitoAccId: string, id: number, text: string) => {
      dispatch(
        thunkAvitoChatBot.updateAutoAnswer({ avitoAccId, id, token, domainModel: { text } }),
      );
    },
    [dispatch, token],
  );

  const editTitleAvitoAnswer = useCallback(
    (avitoAccId: string, id: number, name: string) => {
      dispatch(avitoChatBotAction.setLoadingAndDisabledSystem({ key: "name", value: true }));
      dispatch(
        thunkAvitoChatBot.updateAutoAnswer({ avitoAccId, id, token, domainModel: { name } }),
      ).finally(() => {
        dispatch(avitoChatBotAction.setLoadingAndDisabledSystem({ key: "name", value: false }));
      });
    },
    [token, dispatch],
  );

  const onClickSetCurrentPage = (currentPage: number) => {
    dispatch(
      avitoChatBotAction.setCurrentPageNew({ accountId: avitoAccId, currentPage: currentPage }),
    );
  };

  const onClickDeleteAutoAnswer = (id: number) => {
    dispatch(thunkAvitoChatBot.deleteAutoAnswer({ avitoAccId, id }));
    reset();
  };

  const onClickOpenModalDelAutoAnswer = () => {
    setOpenDeleteModal(true);
  };
  const onClickCloseModalDelAutoAnswer = () => {
    setOpenDeleteModal(false);
  };
  const onClickCloseModalAddAutoAnswer = () => {
    setOpenModalAddAutoAnswer(false);
    reset();
  };
  const onClickOpenModalAddAutoAnswer = () => {
    setOpenModalAddAutoAnswer(true);
  };
  return (
    <>
      {autoAnswers.length > 0 ? (
        itemsForCurrentPage.map((el) => {
          return (
            <div key={el.id}>
              {isAdmin && autoAnswers.length > 0 && (
                <div className={s.modalAndPagination}>
                  <PaginationTabs
                    array={autoAnswers}
                    tabNameProperty={"name"}
                    totalCount={totalCount}
                    currentPages={currentPage}
                    pageLimit={limit}
                    portionPage={10}
                    onClickPagination={onClickSetCurrentPage}
                  />
                  {isAdmin && autoAnswers.length > 0 && autoAnswers.length < 2 && (
                    <TooltipMiu title={"добавить автоответчик"} placement={"right-start"}>
                      <IconButton
                        className={s.addAvitoAnswersIcon}
                        size="medium"
                        onClick={onClickOpenModalAddAutoAnswer}
                      >
                        <AddCircleSharpIcon />
                      </IconButton>
                    </TooltipMiu>
                  )}
                  <DialogRadix
                    childOverflowOff
                    title={"Добавить автоответчик"}
                    open={openModalAddAutoAnswer}
                    onOpenCloseDialog={onClickCloseModalAddAutoAnswer}
                    onClickDialogConfirmOk={handleDialogConfirm}
                    className={s.dialogAddNewAutoAnswer}
                  >
                    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                      <ControlledTextField
                        control={control}
                        name={"nameAutoAnswer"}
                        error={errors.nameAutoAnswer && errors.nameAutoAnswer.message}
                        label={"Введите имя автоответчика"}
                      />
                    </form>
                  </DialogRadix>
                </div>
              )}
              <div className={s.deleteBlock}>
                <TooltipMiu title={"удалить автоответчик"} placement={"top-end"}>
                  <IconButton
                    onClick={onClickOpenModalDelAutoAnswer}
                    aria-label="delete=autoAnswer"
                    className={s.deleteAvitoAnswer}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TooltipMiu>
                <DialogRadix
                  title={"Удаление автоответчика"}
                  open={openDeleteModal}
                  onOpenCloseDialog={onClickCloseModalDelAutoAnswer}
                  onClickDialogConfirmOk={() => onClickDeleteAutoAnswer(el.id)}
                  description={"Вы уверены что хотите удалить автоответчик? "}
                />
              </div>
              <div className={s.untRow}>
                <AvitoBasicSettings
                  avitoAccId={avitoAccId}
                  onChangeSelectMessagePause={onChangeSelectMessagePause}
                  onChangeAutoAnswersSetting={onChangeAutoAnswersSetting}
                  onChangeTimeOffsetFromUTC={onChangeTimeOffsetFromUTC}
                  addedAdsList={addedAdsList}
                  removeElementAdsList={removeElementAdsList}
                  messagePauseSeconds={el.message_pause_seconds}
                  id={el.id}
                  timeOffsetFromUtc={el.time_offset_from_utc}
                  mode={el.ads_list_mode}
                  autoAnswersSetting={el.auto_answers_setting}
                  adsList={el.ads_list}
                  name={el.name}
                  editTitleAvitoAnswer={editTitleAvitoAnswer}
                />
                <AdsList
                  avitoAccId={avitoAccId}
                  onChangeSelectMode={onChangeAdsListMode}
                  autoAnswersSetting={el.auto_answers_setting}
                  addedAdsList={addedAdsList}
                  removeElementAdsList={removeElementAdsList}
                  id={el.id}
                  adsList={el.ads_list}
                  mode={el.ads_list_mode}
                />
              </div>
              <div>
                <AvitoSchedule
                  avitoAccId={avitoAccId}
                  id={el.id}
                  mode={el.ads_list_mode}
                  answer={el.auto_answers_setting}
                  timeIntervals={el.time_intervals}
                  updateTimeIntervals={updateTimeIntervals}
                />
              </div>
              <div className={s.untRow}>
                <AvitoMessageText
                  avitoAccId={avitoAccId}
                  id={el.id}
                  text={el.text}
                  messageText={messageText}
                />
                <AvitoCommand
                  sendCommandValue={el.send_commands_list}
                  avitoAnswerId={el.id}
                  onChangeSendCommandList={onChangeSendCommandList}
                />{" "}
                {/* Блок с командами */}
              </div>
              {isAdmin && autoAnswers.length > 0 && (
                <Pagination
                  totalCount={totalCount}
                  currentPages={currentPage}
                  pageLimit={limit}
                  portionPage={portionPage}
                  onClickPagination={onClickSetCurrentPage}
                />
              )}
            </div>
          );
        })
      ) : (
        <Block
          classNameWrapper={s.blockAddAutoAnswer}
          classNameChildren={s.blockAddAutoAnswerChildren}
        >
          <React.Fragment>
            {localLoading ? (
              <Loading size={50} sx={{ color: "gray" }} />
            ) : (
              <div>
                <div className={s.avitoIcon}>
                  {theme === Theme.DARK ? (
                    <>
                      <img src={avitoIcon} alt="avitoIcon" />
                      <img src={avitoTextWhite} alt="avitoText" />
                    </>
                  ) : (
                    <>
                      <img src={avitoIcon} alt="avitoIcon" />
                      <img src={avitoText} alt="avitoText" />
                    </>
                  )}
                </div>
                <form className={s.inputBlock} onSubmit={handleSubmit(onSubmit)}>
                  <ControlledTextField
                    label={"Создать автоответчик"}
                    placeholder={"Введите имя"}
                    control={control}
                    name={"nameAutoAnswer"}
                    error={errors.nameAutoAnswer && errors.nameAutoAnswer.message}
                  />
                  <IconButton
                    type={"submit"}
                    className={s.addAvitoAnswersIcon}
                    size="medium"
                    disabled={globalLoading}
                  >
                    <AddCircleSharpIcon />
                  </IconButton>
                </form>
              </div>
            )}
          </React.Fragment>
        </Block>
      )}
    </>
  );
};

export default React.memo(AvitoAnswer);
