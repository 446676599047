import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "../../../../../common/utils/createAppAsyncThunk";
import { appAction } from "../../../../../app/app-reducer";
import {
  AddVkCommandDomainReqType,
  vkCommandApi,
  EditVkCommandDomainReq,
  GetVkCommandResType,
  GetVkCommandDomainReqType,
  DeleteVkCommandDomain,
  ApiModelEditCommandReq,
} from "./vk.command.api";
import { handleApiError } from "../../../../../common/utils/errorUtils";
import { alertTranslateMsg } from "../../../../../common/utils/alertTranslateMsgFromRes";
import { thunkVkChatBot } from "../../vkChatBotReducer";
import { vkAccountThunks } from "../../../../Account/VkAccount/vkAccountReducer";

export type VkCommandStateType = {
  [key: string]: Array<GetVkCommandResType>;
};

const slice = createSlice({
  name: "vkCommand",
  initialState: {
    command_list: {} as VkCommandStateType,
    limit: 5 as number,
    totalCount: 0 as number,
    currentPage: 0 as number,
    // portionPage: 10 as number,
  },
  reducers: {
    setCurrentPage(state, action: PayloadAction<{ currentPage: number }>) {
      state.currentPage = action.payload.currentPage;
    },
    setTotalCount(state, action: PayloadAction<{ totalCount: number }>) {
      state.totalCount = action.payload.totalCount;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunkVkChatBot.getAutoAnswer.fulfilled, (state, action) => {
        action.payload.vkAnswer.forEach((answerArray) => {
          answerArray.forEach((el) => {
            state.command_list[el.id] = [];
          });
        });
      })
      .addCase(vkAccountThunks.deleteVkAcc.fulfilled, (state, action) => {
        //TODO: сделать зачищение стейта при удалинии вк аккаунта
      })
      .addCase(thunkVkChatBot.addedVkAnswer.fulfilled, (state, action) => {
        const answerId = action.payload.autoAnswer.id;
        if (!state.command_list[answerId]) {
          state.command_list[answerId] = [];
        }
      })
      .addCase(vkCommandThunk.getVkCommand.fulfilled, (state, action) => {
        const answerId = action.payload.vkAnswerId;
        state.command_list[answerId] = [...action.payload.commands_list];
      })
      .addCase(vkCommandThunk.addVkCommand.fulfilled, (state, action) => {
        const answerId = action.payload.vkAnswerId;
        const command = action.payload.command;
        state.command_list[answerId].push(command);
      })
      .addCase(vkCommandThunk.deleteVkCommand.fulfilled, (state, action) => {
        const answerId = action.payload.answer_id;
        const commandId = action.payload.command_id;
        const index = state.command_list[answerId].findIndex((command) => command.id === commandId);

        if (index !== -1) {
          state.command_list[answerId].splice(index, 1);
        }
      })
      .addCase(vkCommandThunk.editVkCommand.fulfilled, (state, action) => {
        const answerId = action.payload.answerId;
        const commandId = action.payload.commandId;
        const command = action.payload.vkCommand;
        const index = state.command_list[answerId].findIndex((command) => command.id === commandId);
        if (index !== -1) {
          state.command_list[answerId].splice(index, 1, command);
        }
      });
  },
});

const getVkCommand = createAppAsyncThunk<
  { commands_list: GetVkCommandResType[]; accountId: number; vkAnswerId: number },
  GetVkCommandDomainReqType
>("vkCommand/getVkCommand", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));
  try {
    const res = await vkCommandApi.getVkCommand({ token: arg.token, answer_id: arg.answer_id });
    const commands_list = res.data;
    return { commands_list, accountId: arg.account_id, vkAnswerId: arg.answer_id };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const addVkCommand = createAppAsyncThunk<
  { command: GetVkCommandResType; accountId: number; vkAnswerId: number },
  AddVkCommandDomainReqType
>("vkCommand/addVkCommand", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));
  try {
    const res = await vkCommandApi.addVkCommand({
      answer_id: arg.answer_id,
      token: arg.token,
      data: arg.data,
    });
    return { command: res.data, accountId: arg.account_id, vkAnswerId: arg.answer_id };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const deleteVkCommand = createAppAsyncThunk<
  { command_id: number; answer_id: number; account_id: number },
  DeleteVkCommandDomain
>("vkCommand/deleteVkCommand", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));
  try {
    const res = await vkCommandApi.deleteVkCommand({
      token: arg.token,
      command_id: arg.command_id,
    });
    dispatch(appAction.setAlerts({ alerts: alertTranslateMsg(res.data.msg) }));

    return {
      command_id: arg.command_id,
      account_id: arg.account_id,
      answer_id: arg.answer_id,
      msg: res.data.msg,
    };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const editVkCommand = createAppAsyncThunk<
  { vkCommand: GetVkCommandResType; accountId: number; answerId: number; commandId: number },
  EditVkCommandDomainReq
>("vkCommand/editVkCommand", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));

  const state = getState();
  const vkCommand = state.vkCommand.command_list[arg.answerId].find(
    (command) => command.id === arg.model.id,
  );

  if (!vkCommand) {
    console.warn("vk command not found in the state");
    return rejectWithValue(null);
  }
  const apiCommandModel: ApiModelEditCommandReq = {
    ...vkCommand,
    ...arg.model,
  };
  try {
    const res = await vkCommandApi.editCommandResponse({
      token: arg.token,
      model: apiCommandModel,
    });
    return {
      vkCommand: res.data,
      accountId: arg.accountId,
      answerId: arg.answerId,
      commandId: arg.model.id,
    };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

export const vkCommandReducer = slice.reducer;
export const vkCommandAction = slice.actions;
export const vkCommandThunk = { getVkCommand, addVkCommand, deleteVkCommand, editVkCommand };
