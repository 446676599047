import * as AccordionRadixComponent from "@radix-ui/react-accordion";
import React, { ElementRef, forwardRef } from "react";
import clsx from "clsx";
import s from "./Accordion.module.scss";

type RootType = "single" | "multiple";
type AccordionProps = {
  root?: RootType;
  collapsible?: boolean;
  children: React.ReactNode;
  defaultValue?: string;
  classNameWrapper?: string;
  fullwidth?: boolean;
};

export const Accordion = forwardRef<
  ElementRef<typeof AccordionRadixComponent.Root>,
  AccordionProps
>((props, ref) => {
  const { root = "single", children, classNameWrapper, collapsible = true, fullwidth } = props;

  const classNames = {
    accordionRoot: clsx(
      s.accordionRoot,
      classNameWrapper && s.classNameWrapper,
      fullwidth && s.fullwidth,
    ),
  };

  return (
    <>
      <AccordionRadixComponent.Root
        className={classNames.accordionRoot}
        type={root}
        ref={ref}
        collapsible={collapsible}
      >
        {children}
      </AccordionRadixComponent.Root>
    </>
  );
});
