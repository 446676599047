import { instance } from "../../../common/api/api";

type AvitoAccountApiType = {
  link: "avito/account/link";
  deleteAvitoAccount: "avito/account";
};

const avitoAccountEndpoints = {
  link: "avito/account/link",
  deleteAvitoAccount: "avito/account",
} as AvitoAccountApiType;

export const avitoAccountApi = {
  link: (token: { token: string }) => {
    return instance.get<{ location: string }>(avitoAccountEndpoints.link, {
      headers: { Authorization: `Bearer ${token.token}` },
    });
  },
  deleteAvitoAccount: (arg: DeleteAvitoAccountReqType) => {
    return instance.delete(
      avitoAccountEndpoints.deleteAvitoAccount +
        `/${arg.account_id}?is_confirmed=${arg.is_confirmed}`,
      {
        headers: { Authorization: `Bearer ${arg.token}` },
      },
    );
  },
};

/** Удалить аккаунт req */
export type DeleteAvitoAccountReqType = {
  account_id: number;
  is_confirmed: boolean;
  token: string;
};
