import React from "react";
import { AuthContainer } from "../AuthContainer/AuthContainer";
import s from "./ForgorPassword.module.scss";
import { clsx } from "clsx";
import HttpsSharpIcon from "@mui/icons-material/HttpsSharp";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "../../../components/Typography/Typography";
import { ButtonMiu } from "../../../components/Buttons/Button/ButtonMiu";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../common/hooks/hooks";
import { authThunks } from "../auth-reducer";
import { validationSchemes } from "../../../common/validationSchemes/validationSchemes";
import { ControlledTextField } from "../../../components/TextField/controlled-textField";

const schema = yup.object().shape({
  email: validationSchemes.auth.email,
});
type FormDataType = yup.InferType<typeof schema>;
export const ForgotPassword = () => {
  const classNames = {
    root: clsx(s.forgotPassBlock),
    form: clsx(s.formBlock),
    typography: clsx(s.typography),
    button: clsx(s.button),
    link: clsx(s.linkBlock),
    typographyLink: clsx(s.typographyLinkText),
    linkToLogin: clsx(s.linkToLogin),
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
  });

  const dispatch = useAppDispatch();
  const onSubmit = (data: FormDataType) => {
    reset();
    dispatch(authThunks.resetPass({ email: data.email }));
  };

  return (
    <div className={classNames.root}>
      <AuthContainer title={"Восстановление пароля"} iconHeader={<HttpsSharpIcon />}>
        <form onSubmit={handleSubmit(onSubmit)} className={classNames.form}>
          <ControlledTextField
            fullwidth
            label={"Введите email"}
            placeholder={"Введите email"}
            control={control}
            name={"email"}
            error={errors.email && errors.email.message}
          />
          <Typography className={classNames.typography} variant={"Body2"}>
            Инструкции для восстановления пароля будут высланы на вашу почту.
          </Typography>
          <ButtonMiu type={"submit"} className={classNames.button} title={"Восстановить"} />
        </form>
        <div className={classNames.link}>
          <Typography className={classNames.typographyLink} variant={"Body2"}>
            Вы помните пароль?
          </Typography>
          <Link className={classNames.linkToLogin} to={"/login"}>
            Войти
          </Link>
        </div>
      </AuthContainer>
    </div>
  );
};
