import React, { ReactNode } from "react";
import Tooltip from "@mui/material/Tooltip";
import clsx from "clsx";
import s from "./tooltipMiu.module.scss";

type TooltipMiuPropsType = {
  children: ReactNode;
  title: string;
  className?: string;
  open?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  slotProps?: any;
  followCursor?: boolean;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
};

export const TooltipMiu: React.FC<TooltipMiuPropsType> = (props) => {
  const {
    children,
    placement,
    title,
    onClose,
    onOpen,
    open,
    className,
    followCursor,
    ...otherProps
  } = props;

  const onCloseHandler = () => {
    if (onClose) {
      onClose();
    }
  };
  const onOpenHandler = () => {
    if (onOpen) {
      onOpen();
    }
  };
  const classNames = {
    children: clsx(s.children),
  };

  return (
    <Tooltip
      className={className}
      open={open}
      title={title}
      placement={placement}
      onClose={onCloseHandler}
      onOpen={onOpenHandler}
      followCursor={followCursor}
      {...otherProps}
    >
      <div className={classNames.children}>{children}</div>
    </Tooltip>
  );
};
