import React from "react";
import { Route, Routes } from "react-router-dom";
import { Account } from "./Account/Account";
import { ChatBot } from "./ChatBot/ChatBot";
import { SignUp } from "./Auth/SignUp/SignUp";
import { ProtectedRoute } from "common/hoc/protectedRoute";
import { useDisabledChatBot } from "common/hooks/hooks";
import { ForgotPassword } from "./Auth/ForgotPassword/ForgotPassword";
import { SetNewPassword } from "./Auth/SetNewPassword/SetNewPassword";
import { SignIn } from "./Auth/SignIn/SignIn";
import { ProtectedRoutePositive } from "common/hoc/protectedRoutePositive";
import { NotFoundPage } from "components/NotFoundPage/NotFoundPage";
import { Help } from "./Help/Help";
import { Subscription } from "./Subscription/Subscription";
import { ConfirmReg } from "./Auth/ConfirmReg/ConfirmReg";
import { Offer } from "components/Offer/Offer";

export type PatchType = {
  DEFAULT: "/";
  CHAT_BOT: "/chat-bot";
  PROFILE: "/profile";
  AUTH: {
    REGISTER: "/register";
    LOGIN: "/login";
    FORGOT_PASSWORD: "/forgot_password";
    SET_NEW_PASSWORD: "/set-new-password";
    CONFIRM_REG: "/confirm-reg";
  };
  HELP: "/help";
  SUBSCRIPTION: "/subscription";
  OFFER: "/offer";
};

export const PATCH: PatchType = {
  DEFAULT: "/",
  CHAT_BOT: "/chat-bot",
  PROFILE: "/profile",
  AUTH: {
    REGISTER: "/register",
    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgot_password",
    SET_NEW_PASSWORD: "/set-new-password",
    CONFIRM_REG: "/confirm-reg",
  },
  HELP: "/help",
  SUBSCRIPTION: "/subscription",
  OFFER: "/offer",
};

export const Pages = () => {
  const { isDisabled } = useDisabledChatBot();
  return (
    <>
      <Routes>
        <Route
          path={PATCH.SUBSCRIPTION}
          element={
            <ProtectedRoute>
              <Subscription />
            </ProtectedRoute>
          }
        />
        <Route
          path={PATCH.DEFAULT}
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route
          path={PATCH.PROFILE}
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route
          path={PATCH.CHAT_BOT}
          element={
            <ProtectedRoute>
              <ChatBot disabled={isDisabled} />
            </ProtectedRoute>
          }
        />
        <Route
          path={PATCH.AUTH.REGISTER}
          element={
            <ProtectedRoutePositive>
              <SignUp />
            </ProtectedRoutePositive>
          }
        />
        <Route
          path={PATCH.AUTH.LOGIN}
          element={
            <ProtectedRoutePositive>
              <SignIn />
            </ProtectedRoutePositive>
          }
        />
        <Route
          path={PATCH.AUTH.FORGOT_PASSWORD}
          element={
            <ProtectedRoutePositive>
              <ForgotPassword />
            </ProtectedRoutePositive>
          }
        />
        <Route
          path={PATCH.AUTH.SET_NEW_PASSWORD}
          element={
            <ProtectedRoutePositive>
              <SetNewPassword />
            </ProtectedRoutePositive>
          }
        />
        <Route
          path={PATCH.AUTH.CONFIRM_REG}
          element={
            <ProtectedRoutePositive>
              <ConfirmReg />
            </ProtectedRoutePositive>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
        <Route
          path={PATCH.HELP}
          element={
              <Help />
          }
        />
        <Route
          path={PATCH.OFFER}
          element={
              <Offer />

          }
        />
      </Routes>
    </>
  );
};
