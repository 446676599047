import { isAxiosError } from "axios";
import { appAction } from "../../app/app-reducer";
import { Dispatch } from "redux";
import { authActions } from "../../pages/Auth/auth-reducer";
import { deleteTokenInLocalStorage } from "./localStorageUtils";

/** Перевод ошибок */
export const errorTranslations: Record<string, string> = {
  "This email already exists": "Этот email уже существует",
  "Incorrect username or password": "Неверный логин или пароль",
  "Token expired": "Срок действия токена истек, войдите в аккаунт",
  "You are trying to add more objects than allowed for this entity":
    "Вы пытаетесь добавить больше чат-ботов, чем вам доступно!",
  "The server has not received the access token": "Для доступа войдите в аккаунт.",
  "This auto_answer setting already has got provided command": "Данная команда уже есть в списке.",
  "No groups found in the VK profile": "В профиле ВК не найдено групп",
  "External API error An error occurred during the request":
    "Ошибка внешнего API. Во время запроса произошла ошибка.",
  "External API error An error occurred during the request:":
    "Ошибка внешнего API. Во время запроса произошла ошибка.",
  "Could not add more accounts": "Не удалось добавить больше аккаунтов",
  "User has already used demo": "Пользователь уже использовал демо-версию",
  "Email not found in confirmations": "Электронная почта не найдена в подтверждениях",
  "is already awaiting for confirmation":
    "уже ожидает подтверждения. Для повторной отправки письма, нажмите на кнопку «Отправить подтверждение» ",
  "Account not found": "Аккаунт не найден",
  "There is no email with awaiting confirmation": "Нет письма с ожиданием подтверждения",
  "Token sent": "Подтверждение почты отправлено",
  "is awaiting for confirmation":
    "Поздравляем с регистрацией! Вам отправлено письмо с ссылкой для активации аккаунта. " +
    "Перейдите по ней, чтобы получить доступ к функционалу приложения. Проверьте почту, включая папку  «Спам». " +
    "Для повторной отправки письма, нажмите на кнопку «Отправить подтверждение» ",
};

/** Обработка ошибок */
export const handleApiError = (e: unknown, dispatch: Dispatch) => {
  let errorMessage: string;
  if (isAxiosError(e)) {
    errorMessage = e?.response?.data?.detail || e.message;
    if (
      errorMessage === "Token expired" ||
      errorMessage === "The server has not received the access token"
    ) {
      //Проверка на просроченность токена по ошибки
      deleteTokenInLocalStorage("tokenChatBot");
      dispatch(authActions.logout({ me: false }));
    }
    const translatedErrorMessage = errorTranslations[errorMessage] || errorMessage;
    dispatch(appAction.setError({ error: translatedErrorMessage }));
  } else {
    const error = e as Error;
    errorMessage = `Native error:  ${error.message}`;
    dispatch(appAction.setError({ error: errorMessage }));
  }
  return null;
};

export const handleApiErrorStatusCode200 = (msg: string, dispatch: Dispatch) => {
  const errorMsg = errorTranslations[msg];
  return dispatch(appAction.setError({ error: errorMsg }));
};
