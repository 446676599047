import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "../../common/utils/createAppAsyncThunk";
import { subscriptionApi, SubscriptionGetResponse, SubscriptionGetType } from "./subscription-api";
import { handleApiError } from "../../common/utils/errorUtils";
import { navigateActualLinkToSubscribe } from "../../common/utils/navigateActualLinkToSubscribe";

const slice = createSlice({
  name: "subscription",
  initialState: {
    subscriptionGet: {
      data: {
        plan: null,
        msg: null,
        link: null,
      } as SubscriptionGetResponse,
      disabledBtn: false,
    },
  },
  reducers: {
    setDisabledBtn: (state, action: PayloadAction<{ disabled: boolean }>) => {
      state.subscriptionGet.disabledBtn = action.payload.disabled;
    },
    dataSubscriptionNull: (state) => {
      state.subscriptionGet.data.link = null;
      state.subscriptionGet.data.plan = null;
      state.subscriptionGet.data.link = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(subscriptionThunks.subscriptionGet.fulfilled, (state, action) => {
      state.subscriptionGet.data = action.payload.data;
    });
  },
});

export const subscriptionGet = createAppAsyncThunk<
  {
    data: SubscriptionGetResponse;
  },
  SubscriptionGetType
>("subscription/get", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(subscriptionAction.setDisabledBtn({ disabled: true }));
  try {
    const res = await subscriptionApi.subscriptionGetGet({ days: arg.days, token: arg.token });
    navigateActualLinkToSubscribe(res.data.link, dispatch);
    return { data: res.data };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(subscriptionAction.setDisabledBtn({ disabled: false }));
  }
});

export const subscriptionReducer = slice.reducer;
export const subscriptionAction = slice.actions;
export const subscriptionThunks = { subscriptionGet };
