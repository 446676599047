import React from "react";
import s from "./Footer.module.scss";
import { Link } from "react-router-dom";
import { PATCH } from "../../pages/Pages";
import { Typography } from "../Typography/Typography";
import clsx from "clsx";

export const Footer = () => {
  const cl = {
    footer: clsx(s.footer),
    container: clsx(s.container),
  };

  return (
    <div className={cl.footer}>
      <div className={cl.container}>
        <Link to={PATCH.OFFER}>
          <Typography>Оферта</Typography>
        </Link>
      </div>
    </div>
  );
};
