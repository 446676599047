import React, { useState } from "react";
import s from "./PaginationTabs.module.scss";
import { clsx } from "clsx";

type PaginationTabsType = {
  array: any[];
  tabNameProperty: string;
  totalCount: number;
  currentPages: number;
  pageLimit: number;
  portionPage: number;
  onClickPagination: (currentPage: number) => void;
  classNameWrapper?: string; //styles className

  classNamePage?: string;
  elementTabClassName?: string;
  activeTabClassName?: string;
};

export const PaginationTabs: React.FC<PaginationTabsType> = (props) => {
  const {
    classNameWrapper,
    elementTabClassName,
    activeTabClassName,
    classNamePage,
    totalCount,
    currentPages,
    pageLimit,
    onClickPagination,
    portionPage,
    array = [],
    tabNameProperty,
  } = props;
  const active = currentPages;

  const classNames = {
    wrapper: clsx(s.paginationContainer, classNameWrapper),
    page: clsx(s.page, classNamePage),
    elementTab: (isActive: boolean) =>
      clsx(s.elementTab, elementTabClassName, { [activeTabClassName || s.activeTab]: isActive }),
  };
  const pagesCount = Math.ceil(totalCount / pageLimit); //кол-во страниц
  const items = [];
  for (let number = 1; number <= pagesCount; number++) {
    items.push(number);
  }

  const portionCount = Math.ceil(pagesCount / portionPage);
  const [portionNumber, setPortionNumber] = useState(1);
  const leftPortionPageNumber = (portionNumber - 1) * portionPage + 1;
  const rightPortionPageNumber = portionNumber * portionPage;

  let tabTitle = tabNameProperty;
  const property = array.map((el) => el[tabTitle]); //ключ для отображения на табе какого-либо значения из массива
  //допустим "Имя" Автоответчика

  return (
    <div className={classNames.wrapper}>
      {portionNumber > 1 && (
        <button
          onClick={() => {
            setPortionNumber(portionNumber - 1);
          }}
        >
          PREF
        </button>
      )}
      <div className={classNames.page}>
        {items
          .filter((p) => p >= leftPortionPageNumber && p <= rightPortionPageNumber)
          .map((p) => {
            let propertyValue = property[p - 1];
            if (propertyValue === undefined) {
              propertyValue = p;
            }
            return (
              <div
                key={p}
                className={classNames.elementTab(active === p)}
                onClick={() => {
                  onClickPagination(p);
                }}
              >
                {propertyValue}
              </div>
            );
          })}
      </div>
      {portionCount > portionNumber && (
        <button
          onClick={() => {
            setPortionNumber(portionNumber + 1);
          }}
        >
          NEXT
        </button>
      )}
    </div>
  );
};
