import React, { useCallback, useRef } from "react";
import styles from "./AvitoBasicSettings.module.scss";
import QuestionAnswerSharpIcon from "@mui/icons-material/QuestionAnswerSharp";
import PauseCircleSharpIcon from "@mui/icons-material/PauseCircleSharp";
import RestoreSharpIcon from "@mui/icons-material/RestoreSharp";
import { AdsListModeType, AutoAnswersSettingType } from "../../avito.chatBot.api";
import { EditableText } from "../../../../../components/EditableText/EditableText";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Block } from "../../../../../components/Layout/Block/Block";
import { useAppDispatch, useAppSelector } from "../../../../../common/hooks/hooks";
import { LoadingAndDisabledState } from "../../avitoChatBotReducer";
import { SelectItem } from "../../../../../components/Select/SelectItem";
import { Select } from "../../../../../components/Select";
import { Loader } from "../../../../../components/Loadings/Loader/Loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validationSchemes } from "../../../../../common/validationSchemes/validationSchemes";
import { secondsToMinutes } from "../../../../../common/utils/parseTimeMinutes";
import { TooltipMiu } from "../../../../../components/TooltipMiu/TooltipMiu";
import { IconButton } from "../../../../../components/Buttons/IconButton";
import IcSave from "../../../../../components/Icon/IconComponents/IcSave/IcSave";
import { appAction } from "../../../../../app/app-reducer";
import { TooltipEnum } from "../../../../../common/enam/enamProject";
import { Typography } from "../../../../../components/Typography/Typography";
import { ControlledTextField } from "../../../../../components/TextField/controlled-textField";

type AvitoBasicSettingsPropsType = {
  avitoAccId: string;
  onChangeSelectMessagePause: (avitoAccId: string, id: number, time: number) => void;
  onChangeAutoAnswersSetting: (
    avitoAccId: string,
    id: number,
    autoAnswerSetting: AutoAnswersSettingType,
  ) => void;
  onChangeTimeOffsetFromUTC: (avitoAccId: string, id: number, utc: number) => void;
  addedAdsList: (avitoAccId: string, id: number, adsList: string) => void;
  removeElementAdsList: (avitoAccId: string, id: number, removeElement: string) => void;
  editTitleAvitoAnswer: (avitoAccId: string, id: number, name: string) => void;
  mode: AdsListModeType;
  autoAnswersSetting: AutoAnswersSettingType;
  id: number;
  timeOffsetFromUtc: number;
  messagePauseSeconds: number;
  adsList: string[];
  disabled?: boolean | null;
  name: string;
};
const autoAnswersSettingsMode = [
  { value: "OFF", text: "Выкл" },
  { value: "TIME_INTERVAL", text: "По расписанию" },
  { value: "FIRST_MESSAGE", text: "Приветствие" },
];
const timeZone = [
  -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
  14,
];

const iconWordModeOn = { color: "#27a456", fontSize: "30px", cursor: "pointer" };
const iconWordModeOnDisabled = { color: "#27a456", fontSize: "30px" };
const iconWordModeOff = { color: "#c5c5c5", fontSize: "30px" };

const schema = yup.object().shape({
  pause: validationSchemes.pause,
});
type DataType = yup.InferType<typeof schema>;

const AvitoBasicSettings: React.FC<AvitoBasicSettingsPropsType> = (props) => {
  const {
    avitoAccId,
    messagePauseSeconds,
    autoAnswersSetting,
    onChangeSelectMessagePause,
    id,
    onChangeAutoAnswersSetting,
    onChangeTimeOffsetFromUTC,
    timeOffsetFromUtc,
    name,
    editTitleAvitoAnswer,
  } = props;

  const loadingAndDisabledValues = useAppSelector<LoadingAndDisabledState>(
    (state) => state.avitoChatBot.avitoChatBot.loadingAndDisabledSystem.basic_settings,
  );
  const refBtn = useRef<HTMLButtonElement>(null);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const onSubmit = (data: DataType) => {
    if (!isDirty) {
      return;
    } else {
      if (data.pause !== undefined) {
        onChangeSelectMessagePause(avitoAccId, id, data.pause);
      } else {
        console.log("undefined");
      }
    }
  };
  const onBlurTextFieldSaveTimePause = () => {
    if (isDirty && refBtn.current) {
      if (!errors || !errors.pause || !errors.pause.message) {
        refBtn.current.focus();
        dispatch(appAction.setAlerts({ alerts: "Не забудьте сохранить значение паузы!" }));
      } else {
        dispatch(appAction.setAlerts({ alerts: `Исправьте ошибку: ${errors.pause.message}` }));
      }
    }
  };

  const handleSelectAutoAnswersSetting = useCallback(
    (id: number, event: string) => {
      const autoAnswersSetting = event as AutoAnswersSettingType;
      onChangeAutoAnswersSetting(avitoAccId, id, autoAnswersSetting);
    },
    [avitoAccId, onChangeAutoAnswersSetting],
  );

  const handleChangeTimeZoneUTC = useCallback(
    (id: number, event: string) => {
      const timeOffsetFromUTC = +event as number;
      onChangeTimeOffsetFromUTC(avitoAccId, id, timeOffsetFromUTC);
    },
    [avitoAccId, onChangeTimeOffsetFromUTC],
  );

  const handleEditableSpanData = useCallback(
    (text: string) => {
      const id = props.id;
      editTitleAvitoAnswer(avitoAccId, id, text);
    },
    [avitoAccId, editTitleAvitoAnswer, props.id],
  );
  return (
    <>
      <Block title={"Основные настройки"} classNameWrapper={styles.block}>
        <div className={styles.state}>
          <div className={styles.stateFlex}>
            <Typography asChild>
              <span>Название:</span>
            </Typography>
            <div className={styles.nameAvitoAnswer}>
              {loadingAndDisabledValues["name"] ? (
                <Loader />
              ) : (
                <EditableText text={name} onEditableSpanData={handleEditableSpanData} />
              )}
              <DriveFileRenameOutlineIcon sx={iconWordModeOnDisabled} />
            </div>
          </div>
          <div className={styles.stateFlex}>
            <TooltipMiu
              placement={"top-end"}
              title={
                autoAnswersSetting === "TIME_INTERVAL"
                  ? TooltipEnum.timeInterval
                  : autoAnswersSetting === "FIRST_MESSAGE"
                    ? TooltipEnum.firstMessages
                    : ""
              }
            >
              <Typography asChild>
                <span>Режим работы:</span>
              </Typography>
            </TooltipMiu>
            <div className={styles.workMode}>
              <Select
                disabled={loadingAndDisabledValues["auto_answers_setting"]}
                scrollBtn={false}
                loading={loadingAndDisabledValues["auto_answers_setting"]}
                value={autoAnswersSetting}
                onValueChange={(event) => handleSelectAutoAnswersSetting(id, event)}
              >
                {autoAnswersSettingsMode.map((el, index) => {
                  return (
                    <SelectItem key={index} value={el.value}>
                      {el.text}
                    </SelectItem>
                  );
                })}
              </Select>
              {autoAnswersSetting === "OFF" ? (
                <QuestionAnswerSharpIcon sx={iconWordModeOff} />
              ) : (
                <QuestionAnswerSharpIcon sx={iconWordModeOn} />
              )}
            </div>
          </div>
          <div className={styles.stateFlex}>
            <TooltipMiu placement={"top-end"} title={TooltipEnum.pause}>
              <Typography asChild>
                <span>Пауза (в минутах):</span>
              </Typography>
            </TooltipMiu>
            <div className={styles.pauseSeconds}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TooltipMiu
                  title={
                    "«Интервал,\n" +
                    "через ĸоторый автоответчиĸ будет срабатывать. При изменении\n" +
                    "настройĸа применится ТОЛЬКО ПОСЛЕ следующего\n" +
                    "срабатывания автоответчиĸа. Если предыдущая пауза еще не\n" +
                    "завершена, необходимо дождаться ее завершения»"
                  }
                >
                  <ControlledTextField
                    control={control}
                    name={"pause"}
                    error={errors.pause && errors.pause.message}
                    textFieldClassName={styles.textFieldPause}
                    defaultValue={secondsToMinutes(messagePauseSeconds).toString()}
                    onBlur={onBlurTextFieldSaveTimePause}
                    triggerEnd={
                      <IconButton ref={refBtn} variant={"inputColor"} type={"submit"}>
                        <IcSave size={1.2} />
                      </IconButton>
                    }
                  />
                </TooltipMiu>
              </form>
              {autoAnswersSetting === "OFF" ? (
                <PauseCircleSharpIcon sx={iconWordModeOff} />
              ) : (
                <PauseCircleSharpIcon sx={iconWordModeOnDisabled} />
              )}
            </div>
          </div>
          <div className={styles.stateFlex}>
            <Typography asChild>
              <span>Часовой пояс (UTC):</span>
            </Typography>
            <div className={styles.workTimeZone}>
              <Select
                scrollBtn={false}
                position={"item-aligned"}
                disabled={loadingAndDisabledValues["time_offset_from_utc"]}
                loading={loadingAndDisabledValues["time_offset_from_utc"]}
                value={`${timeOffsetFromUtc}`}
                onValueChange={(event) => handleChangeTimeZoneUTC(id, event)}
              >
                {timeZone.map((el, index) => {
                  return (
                    <SelectItem key={index} value={el.toString()}>
                      {el.toString()}
                    </SelectItem>
                  );
                })}
              </Select>
              {autoAnswersSetting === "OFF" ? (
                <RestoreSharpIcon sx={iconWordModeOff} />
              ) : (
                <RestoreSharpIcon sx={iconWordModeOn} />
              )}
            </div>
          </div>
        </div>
      </Block>
    </>
  );
};

export default React.memo(AvitoBasicSettings);
