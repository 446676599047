import React, { useState } from "react";
import s from "./Pagination.module.scss";

type PaginationType = {
  totalCount: number;
  currentPages: number;
  pageLimit: number;
  portionPage: number;
  onClickPagination: (currentPage: number) => void;
};

export const Pagination: React.FC<PaginationType> = (props) => {
  const { totalCount, currentPages, pageLimit, onClickPagination, portionPage } = props;
  /**  */
  const pagesCount = Math.ceil(totalCount / pageLimit); //кол-во страниц
  const items = [];
  for (let number = 1; number <= pagesCount; number++) {
    items.push(number);
  }

  const active = currentPages;

  const portionCount = Math.ceil(pagesCount / portionPage);

  const [portionNumber, setPortionNumber] = useState(1);
  const leftPortionPageNumber = (portionNumber - 1) * portionPage + 1;
  const rightPortionPageNumber = portionNumber * portionPage;

  const paginationStyles = `${s.pagintaionContainerDiv} ${s.paginationContainer}`;

  return (
    <div className={paginationStyles}>
      {portionNumber > 1 && (
        <button
          onClick={() => {
            setPortionNumber(portionNumber - 1);
          }}
        >
          PREF
        </button>
      )}
      <div className={s.page}>
        {items
          .filter((p) => p >= leftPortionPageNumber && p <= rightPortionPageNumber)
          .map((p) => {
            return (
              <div
                key={p}
                className={
                  active === p ? `${s.paginationNumber} ${s.activePage} ` : `${s.paginationNumber}`
                }
                onClick={() => {
                  onClickPagination(p);
                }}
              >
                {p}
              </div>
            );
          })}
      </div>
      {portionCount > portionNumber && (
        <button
          onClick={() => {
            setPortionNumber(portionNumber + 1);
          }}
        >
          NEXT
        </button>
      )}
    </div>
  );
};
