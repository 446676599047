import React from "react";
import s from "./NotFoundPage.module.scss";
import { clsx } from "clsx";
import { Typography } from "../Typography/Typography";
import { ButtonMiu } from "../Buttons/Button/ButtonMiu";
import { useNavigate } from "react-router-dom";

export const NotFoundPage = () => {
  const classNames = {
    wrapper: clsx(s.wrapper),
    blockError: clsx(s.blockError),
    statusError: clsx(s.statusError),
    descriptionError: clsx(s.descriptionError),
  };
  const navigate = useNavigate();
  const onClickHandlerToNavigate = () => {
    navigate("/");
  };

  return (
    <div className={classNames.wrapper}>
      <div className={classNames.blockError}>
        <Typography colorText={"darkGray"} variant={"Large"} className={classNames.statusError}>
          404
        </Typography>
        <Typography
          variant={"Large"}
          className={classNames.descriptionError}
          colorText={"darkGray"}
        >
          Страница не найдена!
        </Typography>
        <ButtonMiu callBack={onClickHandlerToNavigate} title={"На главную"}></ButtonMiu>
      </div>
    </div>
  );
};
