import { AnyAction, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { appReducer } from "./app-reducer";
import thunkMiddleware from "redux-thunk";
import { authReducer } from "../pages/Auth/auth-reducer";
import { avitoChatBotReducer } from "../pages/ChatBot/AvitoChatBot/avitoChatBotReducer";
import { avitoAccountReducer } from "../pages/Account/AvitoAccount/avitoAccountReducer";
import { avitoCommandReducer } from "../pages/ChatBot/AvitoChatBot/AvitoAnswer/AvitoCommand/avitoCommandReducer";
import { vkAccountReducer } from "../pages/Account/VkAccount/vkAccountReducer";
import { vkChatBotReducer } from "../pages/ChatBot/VkChatBot/vkChatBotReducer";
import { vkCommandReducer } from "../pages/ChatBot/VkChatBot/vkAnswer/vkCommand/vkCommandReducer";
import { subscriptionReducer } from "../pages/Subscription/subscription-reducer";

export const store = configureStore({
  reducer: {
    app: appReducer,
    authorization: authReducer,
    avitoChatBot: avitoChatBotReducer,
    vkChatBot: vkChatBotReducer,
    avitoAccount: avitoAccountReducer,
    vkAccount: vkAccountReducer,
    avitoCommand: avitoCommandReducer,
    vkCommand: vkCommandReducer,
    subscription: subscriptionReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(thunkMiddleware),
});

export type AppRootStateType = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppRootStateType,
  unknown,
  AnyAction
>;

// @ts-ignore
window.store = store;
