import React from "react";
import { CustomizedTabs, TabItem } from "components/CustomizedTabs/CustomizedTabs";
import { AvitoIcon } from "components/Icon/IconComponents/AvitoIcon";
import { VkontakteIcon } from "components/Icon/IconComponents/VkontakteIcon";
import { VkChatBot } from "./VkChatBot/VkChatBot";
import { AvitoChatBot } from "./AvitoChatBot/AvitoChatBot";

type ChatBotType = {
  disabled?: boolean | null;
};

export const ChatBot: React.FC<ChatBotType> = ({ disabled }) => {
  const tabs: TabItem[] = [
    {
      icon: <AvitoIcon />,
      component: <AvitoChatBot disabled={disabled} />,
      label: ``,
    },
    {
      icon: <VkontakteIcon />,
      component: <VkChatBot disabled={disabled} />,
      label: ``,
    },
  ];
  return <CustomizedTabs tabs={tabs} disabled={disabled} />;
};
