import React, { ChangeEvent, ComponentProps, forwardRef, useState } from "react";
import { clsx } from "clsx";

import s from "./TextField.module.scss";
import { useId } from "../../common/hooks/useId";
import { ButtonProps, IconButton } from "../Buttons/IconButton";
import { IcSearch } from "../Icon/IconComponents/IcSearch/IcSearch";
import { IcClear } from "../Icon/IconComponents/IcClear/IcClear";
import { IcCloseEye } from "../Icon/IconComponents/IcCloseEye/IcCloseEye";
import { IcOpenEye } from "../Icon/IconComponents/IcOpenEye/IcOpenEye";
import { IconProps } from "../Icon/IconWrapper/IconWrapper";

export type BaseTextFieldProps = {
  defaultValue?: readonly string[] | string | undefined;
  disabled?: boolean;
  error?: boolean | null | string;
  fullwidth?: boolean;
  id?: string;
  label?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChangeText?: (value: string) => void;
  onClickSearch?: () => void;
  onEnter?: () => void;
  placeholder?: string;
  textFieldClassName?: string;
  triggerEnd?: React.ReactElement<ButtonProps | IconProps>;
  type?: "password" | "search" | "text";
} & Omit<ComponentProps<"input">, "type">;

export const TextField = forwardRef<HTMLInputElement, BaseTextFieldProps>((props, ref) => {
  const {
    defaultValue,
    disabled,
    error,
    fullwidth,
    id,
    triggerEnd,
    label,
    onBlur: customOnBlur,
    onChange,
    onChangeText,
    onClickSearch,
    onEnter,
    textFieldClassName,
    onKeyDown,
    placeholder,
    type = "text",
    ...respProps
  } = props;

  const [value, setValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const showClearButton = value.length! > 0;
  const textFieldType = type === "password" && showPassword ? "text" : type;
  const textFieldId = useId(id, "textField");

  const onClickHandleShowPass = () => {
    setShowPassword(!showPassword);
  };
  const onChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
    const value = e.currentTarget.value;

    onChangeText?.(value);
    setValue(value);
  };
  const onBlurCallback = (e: React.FocusEvent<HTMLInputElement>) => {
    customOnBlur?.(e);
  };
  const onKeyPressCallback = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === "password" && e.code === "Enter" && !showPassword) {
      e.preventDefault();
      onEnter && onEnter();
    }
    if (type === "text") {
      if (e.code === "Enter") {
        onEnter && onEnter();
      }
    }
  };

  const onClickHandlerSearchButton = () => {
    if (type === "search" && onClickSearch && !disabled) {
      onClickSearch();
    }
  };

  const onClickClearSearchText = () => {
    if (!disabled) {
      setValue("");
    }
  };

  const classNames = {
    btnClear: clsx(type === "search" && s.btnClear, label && s.withLabel),
    btnSearch: clsx(
      type === "search" && s.btnSearch,
      label && s.withLabel,
      fullwidth && s.btnSearchWithFullWidth,
    ),
    button: clsx(s.button),
    error: clsx(s.error, disabled && s.errorWithDisabled),
    iconSearch: clsx(s.iconSearch),
    iconShowPass: clsx(s.iconShowPass),
    label: clsx(s.labelText, disabled && s.labelWithDisabled),
    showPassBtn: clsx(s.showPassBtn),
    triggerEnd: clsx(s.triggerEnd, label && s.withLabel, fullwidth && s.triggerEndWithFullwidth),
    showPassword: clsx(s.showPassword, label && s.withLabel, fullwidth && s.showPassWithFullwidth),
    textField: clsx(
      s.textField,
      fullwidth && s.fullwidth,
      error && s.errorText,
      type === "password" && s.withShowPassword,
      type === "search" && s.withSearchType,
      triggerEnd && s.withTriggerEnd,
      textFieldClassName,
    ),
    textFieldWrapper: clsx(s.textFieldWrapper, fullwidth && s.fullwidth),
  };

  /** If we use defaultValue, then the value is disabled  */
  const inputValue = defaultValue !== undefined ? defaultValue : value;

  return (
    <div className={classNames.textFieldWrapper}>
      {type === "search" && (
        <>
          <IconButton
            className={classNames.btnSearch}
            disabled={disabled}
            onClick={onClickHandlerSearchButton}
            variant={"secondary"}
            type="button"
          >
            <IcSearch className={classNames.iconSearch} size={1.3} />
          </IconButton>
          {showClearButton && (
            <IconButton
              className={classNames.btnClear}
              disabled={disabled}
              onClick={onClickClearSearchText}
              type={"reset"}
            >
              <IcClear size={1.3} />
            </IconButton>
          )}
        </>
      )}
      {label && (
        <label className={classNames.label} htmlFor={textFieldId}>
          {label}
        </label>
      )}
      <input
        className={classNames.textField}
        defaultValue={defaultValue}
        disabled={disabled}
        id={textFieldId}
        onBlur={onBlurCallback}
        onChange={onChangeCallback}
        onKeyDown={onKeyPressCallback}
        placeholder={placeholder}
        ref={ref}
        type={textFieldType}
        {...(defaultValue !== undefined ? {} : { value: inputValue })}
        {...respProps}
      />
      {error && (
        <span className={classNames.error} id={textFieldId}>
          {error}
        </span>
      )}
      {triggerEnd && <div className={classNames.triggerEnd}>{triggerEnd}</div>}
      {type === "password" && (
        <div className={classNames.showPassword}>
          {showPassword ? (
            <IconButton
              className={classNames.showPassBtn}
              disabled={disabled}
              onClick={onClickHandleShowPass}
              type="button"
            >
              <IcCloseEye className={classNames.iconShowPass} size={1.3} />
            </IconButton>
          ) : (
            <IconButton
              className={classNames.showPassBtn}
              disabled={disabled}
              onClick={onClickHandleShowPass}
              type="button"
            >
              <IcOpenEye className={classNames.iconShowPass} size={1.3} />
            </IconButton>
          )}
        </div>
      )}
    </div>
  );
});
