export const AvitoIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.595" cy="5.225" r="3.325" fill="#965EEB"></circle>
      <circle cx="22.245" cy="7.235" r="7.235" fill="#0AF"></circle>
      <circle cx="8.9" cy="18.6" r="8.9" fill="#04E061"></circle>
      <circle cx="24.325" cy="21.005" r="5.375" fill="#FF4053"></circle>
    </svg>
  );
};
