import React, { ChangeEvent, ReactNode, RefObject, useEffect, useRef, useState } from "react";
import { PaperProps } from "@mui/material";
import { AddAvitoCommandDataType } from "../avito.command.api";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DialogRadix } from "../../../../../../components/Dialog/DialogRadix/DialogRadix";
import s from "./CommandCreatorNew.module.scss";
import { NavigationMenu } from "../../../../../../components/Layout/NavigationMenu/NavigationMenu";
import SentimentSatisfiedSharpIcon from "@mui/icons-material/SentimentSatisfiedSharp";
import { Emoji } from "../../../../../../components/Emoji/Emoji";
import { Typography } from "../../../../../../components/Typography/Typography";
import { TextField } from "../../../../../../components/TextField";
import {
  createValidationSchema,
  hookFormHandler,
} from "../../../../../../common/utils/handlerCommandsValues";

type AvitoCommandCreatorType = {
  additionalParamRef: RefObject<string | undefined>;
  answerId: number;
  onClose: (open: boolean) => void;
  open: boolean;
  title: string;
  descriptionText?: string;
  children?: ReactNode;
  paperProps?: PaperProps;
  className?: string;
  addCommandHandler: (data: AddAvitoCommandDataType) => void;
  commandValue?: string;
  commandResponse?: string;
  id?: number;
  commandList?: any[];
};

export const CommandCreator: React.FC<AvitoCommandCreatorType> = (props) => {
  const {
    additionalParamRef,
    onClose,
    open,
    addCommandHandler,
    commandValue,
    commandResponse,
    title,
    commandList,
  } = props;
  const commandType = additionalParamRef.current;

  const commandValues = hookFormHandler(commandType, commandValue, commandResponse);
  const schema = createValidationSchema(commandType, commandList as any[]);

  type DataSchemaAvitoCommand = yup.InferType<typeof schema>;
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    reset,
    resetField,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const formRef = useRef<HTMLFormElement>(null);
  const emojiRef = useRef<HTMLDivElement | null>(null);
  const [emojiWindow, setEmojiWindow] = useState(false);
  const [remainingLetters, setRemainingLetters] = useState(0);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (emojiRef.current && !emojiRef.current.contains(event.target as Node)) {
        setEmojiWindow(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const onSubmit = (data: DataSchemaAvitoCommand) => {
    addCommandHandler({
      command_response: data.answer,
      command_value: data.question,
      command_description: data.answer,
    });
    onClose(false);
    reset();
    resetField("question");
  };

  const insertEmoji = (emoji: string) => {
    const currentText = getValues("answer");
    const newText = currentText + emoji;
    setValue("answer", newText);
    setRemainingLetters([...newText].length);
    setEmojiWindow(false);
  };
  const onClickEmojiWindowsOpen = () => {
    setEmojiWindow(!emojiWindow);
  };
  const onTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const enteredText = e.target.value;
    const count = [...enteredText].length;
    setRemainingLetters(count);
  };

  const handleDialogConfirm = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
  };

  const onclickCloseDialog = () => {
    onClose(false);
    reset();
  };

  return (
    <DialogRadix
      title={title}
      onOpenCloseDialog={onclickCloseDialog}
      open={open}
      onClickDialogConfirmOk={handleDialogConfirm}
      className={s.avitoCommandCreator}
      titleButtonConfirm={"Сохранить"}
    >
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <div className={s.content}>
          <div className={s.question}>
            <div className={s.titleText}>
              <Typography>Текст команды</Typography>
            </div>
            <TextField
              fullwidth
              {...register("question")}
              defaultValue={commandValues.commandValue}
              id="question"
            />
            <div className={s.questionError}>{errors.question && errors.question.message}</div>
          </div>
          <div className={s.answers}>
            <div className={s.titleText}>
              <Typography>Текст ответа</Typography>
            </div>
            <div className={s.textArea}>
              <textarea
                className={s.textAreaInput}
                {...register("answer")}
                onChange={onTextChange}
                id="textAreaAnswer"
                defaultValue={commandValues.commandResponse}
              />
              <div className={s.textAreaError}>{errors.answer && errors.answer.message}</div>
            </div>
            <NavigationMenu className={s.emoji}>
              <SentimentSatisfiedSharpIcon
                onClick={onClickEmojiWindowsOpen}
                className={s.imageOpenWindowEmoji}
              />
              {emojiWindow && (
                <>
                  <div>
                    <div className={s.emojiDiv}>
                      <Emoji
                        searchDisabled={false}
                        onEmojiClick={insertEmoji}
                        width={270}
                        height={349}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className={s.countSymbols}>{`${remainingLetters} / 1000`}</div>
            </NavigationMenu>
          </div>
        </div>
      </form>
    </DialogRadix>
  );
};
