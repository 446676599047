import React, { useContext, useState } from "react";
import styled from "./AvitoAccount.module.scss";
import Grid3x3SharpIcon from "@mui/icons-material/Grid3x3Sharp";
import EmailSharpIcon from "@mui/icons-material/EmailSharp";
import { ButtonMiu } from "../../../components/Buttons/Button/ButtonMiu";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/hooks";
import avitoIcon from "../../../common/assets/icons/svg/avito/avitoIcon.svg";
import avitoText from "../../../common/assets/icons/svg/avito/avitoText.svg";
import avitoIconDarkTheme from "../../../common/assets/icons/svg/avito/avitoTextWhite.svg";
import { avitoAccountThunks, LoadingAndDisabledState } from "./avitoAccountReducer";
import { AvitoAccountType } from "../../Auth/auth.api";
import DeleteIcon from "@mui/icons-material/Delete";
import { Switch } from "@mui/material";
import { Block } from "../../../components/Layout/Block/Block";
import { Loader } from "../../../components/Loadings/Loader/Loader";
import { IconButton } from "../../../components/Buttons/IconButton";
import { IcReconnection } from "../../../components/Icon/IconComponents/IcReconnection";
import { TooltipMiu } from "../../../components/TooltipMiu/TooltipMiu";
import AddIcon from "@mui/icons-material/Add";
import { DialogRadix } from "../../../components/Dialog/DialogRadix/DialogRadix";
import { Typography } from "../../../components/Typography/Typography";
import { Theme, ThemeContext } from "../../../common/hoc/ThemeProvider/ThemeContext";
import { authThunks } from "../../Auth/auth-reducer";

export const AvitoAccount = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector<string>((state) => state.authorization.login.access_token);

  const [openModalDeleteAvitoAcc, setOpenModalDeleteAvitoAcc] = useState(false);
  const [confirmDelAcc, setConfirmDelAcc] = React.useState(false);
  const { theme } = useContext(ThemeContext);
  const iconSx = { color: "#27a456", fontSize: "20px", marginLeft: "7px" };
  const isLoading = useAppSelector<boolean>((state) => state.app.isLocalLoading);
  const avitoAccounts = useAppSelector<AvitoAccountType[]>(
    (state) => state.authorization.profile.avito_accounts,
  );
  const loadingAvitoAcc = useAppSelector<LoadingAndDisabledState>(
    (state) => state.avitoAccount.loadingAndDisabledAvitoAcc,
  );
  const isAdmin = useAppSelector<boolean>((state) => state.authorization.profile.is_admin);

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const windowWidth = 450;
  const windowHeight = 600;
  const left = (screenWidth - windowWidth) / 2;
  const top = (screenHeight - windowHeight) / 2;
  const windowFeatures = `width=${windowWidth},height=${windowHeight},top=${top},left=${left}`;

  let authWindow: Window | null = null;
  const onClickAddAvitoAccount = () => {
    dispatch(avitoAccountThunks.link({ token: token }))
      .unwrap()
      .then((res) => {
        const redirectURL = res.location;
        authWindow = window.open(redirectURL, "Open_window", windowFeatures);

        const updateAccountsAndCloseWindow = () => {
          if (authWindow) {
            authWindow.close();
            authWindow = null;
            dispatch(authThunks.profile({ token: token }));
          }
        };

        const checkInterval = setInterval(() => {
          if (authWindow && authWindow.closed) {
            clearInterval(checkInterval);
            updateAccountsAndCloseWindow();
          }
        }, 1000);
      })
      .catch((e) => {
        console.warn(e);
      });
  };
  const onClickCloseModalDeleteAvitoAcc = () => {
    setOpenModalDeleteAvitoAcc(false);
  };
  const onClickOpenModalDelAvitoAcc = () => {
    setOpenModalDeleteAvitoAcc(true);
  };
  const onClickDeleteAvitoAcc = (accountId: number) => {
    dispatch(
      avitoAccountThunks.deleteAvitoAcc({
        account_id: accountId,
        is_confirmed: confirmDelAcc,
        token: token,
      }),
    );
    onClickCloseModalDeleteAvitoAcc();
  };
  const handleChangeConfirmDeleteAccount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.currentTarget.checked;
    setConfirmDelAcc(checked);
  };

  return (
    <div className={styled.avito}>
      <div className={styled.untRow}>
        {avitoAccounts && avitoAccounts.length > 0 ? (
          <>
            {avitoAccounts.map((el) => {
              return (
                <Block
                  tooltipTrigger={"Переподключить аккаунт"}
                  key={el.id}
                  title={"Авито аккаунт"}
                  trigger={
                    <div className={styled.reconnectBtnDiv}>
                      {loadingAvitoAcc["addAndDeleteAvitoAcc"] ? (
                        <Loader />
                      ) : (
                        <IconButton onClick={onClickAddAvitoAccount}>
                          <IcReconnection size={1.3} />
                        </IconButton>
                      )}
                    </div>
                  }
                  classNameWrapper={styled.avitoBlock}
                >
                  <div className={styled.stateFlex}>
                    <Typography>ID профиля:</Typography>
                    <div className={styled.answer}>
                      <Typography>{el.id}</Typography>
                      <Grid3x3SharpIcon sx={iconSx} />
                    </div>
                  </div>
                  <div className={styled.deleteBlock}>
                    <ButtonMiu
                      callBack={onClickOpenModalDelAvitoAcc}
                      title={"Удалить аккаунт"}
                      endCoin={<DeleteIcon />}
                    />
                  </div>
                  <DialogRadix
                    title={"Удалить авито аккаунт"}
                    open={openModalDeleteAvitoAcc}
                    onClickDialogConfirmOk={() => {
                      onClickDeleteAvitoAcc(el.id);
                    }}
                    onOpenCloseDialog={onClickCloseModalDeleteAvitoAcc}
                    disabledButton={!confirmDelAcc}
                    description={"Для удаления аккаунта передвинте ползунок и нажмите ОК"}
                  >
                    <div className={styled.switchBlock}>
                      <Switch
                        checked={confirmDelAcc}
                        onChange={handleChangeConfirmDeleteAccount}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </div>
                  </DialogRadix>
                </Block>
              );
            })}
            {isAdmin && avitoAccounts.length < 2 && (
              <TooltipMiu
                title={"Добавить аккаунт"}
                placement={"top"}
                className={styled.blockNewTooltip}
              >
                <Block classNameWrapper={styled.blockNewAcc}>
                  <AddIcon className={styled.addAccIcon} onClick={onClickAddAvitoAccount} />
                </Block>
              </TooltipMiu>
            )}
          </>
        ) : (
          <Block classNameWrapper={styled.blockAvitoConnect}>
            <div className={styled.wrapper}>
              <div className={styled.label}>
                {theme === Theme.DARK ? (
                  <>
                    <img className={styled.avitoIcon} src={avitoIcon} alt={"avito-icon"} />
                    <img className={styled.avitoText} src={avitoIconDarkTheme} alt={"avito-icon"} />
                  </>
                ) : (
                  <>
                    <img className={styled.avitoIcon} src={avitoIcon} alt={"avito-icon"} />
                    <img className={styled.avitoText} src={avitoText} alt={"avito-icon"} />
                  </>
                )}
              </div>
              <ButtonMiu
                title={"привязать avito аккаунт"}
                callBack={onClickAddAvitoAccount}
                loading={isLoading}
                loadingPosition={"end"}
                endCoin={<EmailSharpIcon />}
                tabIndex={0}
              />
            </div>
          </Block>
        )}
      </div>
    </div>
  );
};
