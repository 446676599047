/** преобразование даты в формат 13.01.1994 */
export const formatDateToCustomFormat = (inputDate: string | Date | null) => {
  if (inputDate === null) {
    return "не аĸтивирован";
  }

  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  return `${day}.${month}.${year}`;
};

export const parsingDateInMilliseconds = (inputDate: string | Date | null) => {
  let dataExpiryMoment = null;
  if (typeof inputDate === "string") {
    dataExpiryMoment = Date.parse(inputDate);
  }

  return dataExpiryMoment;
};
