import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "../../../../../common/utils/createAppAsyncThunk";
import { appAction } from "../../../../../app/app-reducer";
import {
  AddAvitoCommandReqDomain,
  avitoCommandApi,
  DeleteAvitoCommandDomain,
  EditAvitoCommandReqDomain,
  GetAvitoCommandReqDomain,
  GetAvitoCommandResType,
} from "./avito.command.api";
import { handleApiError } from "../../../../../common/utils/errorUtils";
import { alertTranslateMsg } from "../../../../../common/utils/alertTranslateMsgFromRes";
import { thunkAvitoChatBot } from "../../avitoChatBotReducer";
import { avitoAccountThunks } from "../../../../Account/AvitoAccount/avitoAccountReducer";

export type AvitoCommandState = {
  [key: string]: Array<GetAvitoCommandResType>;
};

const slice = createSlice({
  name: "avitoCommand",
  initialState: {
    command_list: {} as AvitoCommandState,
    limit: 5 as number,
    totalCount: 0 as number,
    currentPage: 0 as number,
    // portionPage: 10 as number,
  },
  reducers: {
    setCurrentPage(state, action: PayloadAction<{ currentPage: number }>) {
      state.currentPage = action.payload.currentPage;
    },
    setTotalCount(state, action: PayloadAction<{ totalCount: number }>) {
      state.totalCount = action.payload.totalCount;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunkAvitoChatBot.getAutoAnswer.fulfilled, (state, action) => {
        action.payload.avitoAnswer.forEach((answerArray) => {
          answerArray.forEach((el) => {
            state.command_list[el.id] = [];
          });
        });
      })
      .addCase(avitoAccountThunks.deleteAvitoAcc.fulfilled, (state, action) => {
        //TODO: сделать зачищение стейта при удалинии вк аккаунта
      })
      .addCase(thunkAvitoChatBot.addedAvitoAnswer.fulfilled, (state, action) => {
        const answerId = action.payload.autoAnswer.id;
        if (!state.command_list[answerId]) {
          state.command_list[answerId] = [];
        }
      })
      .addCase(avitoCommandThunk.getAvitoCommand.fulfilled, (state, action) => {
        const answerId = action.payload.answer_id;
        state.command_list[answerId] = [...action.payload.commands_list];
      })
      .addCase(avitoCommandThunk.addAvitoCommand.fulfilled, (state, action) => {
        const answerId = action.payload.answer_id;
        const command = action.payload.command;
        state.command_list[answerId].push(command);
      })
      .addCase(avitoCommandThunk.deleteAvitoCommand.fulfilled, (state, action) => {
        const answerId = action.payload.answer_id;
        const commandId = action.payload.command_id;
        const index = state.command_list[answerId].findIndex((command) => command.id === commandId);

        if (index !== -1) {
          state.command_list[answerId].splice(index, 1);
        }
      })
      .addCase(avitoCommandThunk.editAvitoCommand.fulfilled, (state, action) => {
        const answerId = action.payload.answer_id;
        const commandId = action.payload.command_id;
        const command = action.payload.avitoCommand;
        const index = state.command_list[answerId].findIndex((command) => command.id === commandId);
        if (index !== -1) {
          state.command_list[answerId].splice(index, 1, command);
        }
      });
  },
});
const getAvitoCommand = createAppAsyncThunk<
  { answer_id: number; commands_list: GetAvitoCommandResType[] },
  GetAvitoCommandReqDomain
>("avitoCommand/getAvitoCommand", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));
  try {
    const res = await avitoCommandApi.getAvitoCommand({
      token: arg.token,
      answer_id: arg.answer_id,
    });
    const commands_list = res.data;
    return { answer_id: arg.answer_id, commands_list };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const addAvitoCommand = createAppAsyncThunk<
  { answer_id: number; command: GetAvitoCommandResType },
  AddAvitoCommandReqDomain
>("avitoCommand/addAvitoCommand", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));
  try {
    const res = await avitoCommandApi.addAvitoCommand({
      answer_id: arg.answer_id,
      token: arg.token,
      data: arg.data,
    });
    return { answer_id: res.data.auto_answer_id, command: res.data };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const deleteAvitoCommand = createAppAsyncThunk<
  { answer_id: number; command_id: number },
  DeleteAvitoCommandDomain
>("avitoCommand/deleteAvitoCommand", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));
  try {
    const res = await avitoCommandApi.deleteAvitoCommand({
      token: arg.token,
      command_id: arg.command_id,
    });
    dispatch(appAction.setAlerts({ alerts: alertTranslateMsg(res.data.msg) }));
    return { answer_id: arg.answer_id, command_id: arg.command_id, msg: res.data.msg };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const editAvitoCommand = createAppAsyncThunk<
  { answer_id: number; command_id: number; avitoCommand: GetAvitoCommandResType },
  EditAvitoCommandReqDomain
>("avitoCommand/editAvitoCommand", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));

  const state = getState();
  const avitoCommand = state.avitoCommand.command_list[arg.answerId].find(
    (command) => command.id === arg.model.id,
  );

  if (!avitoCommand) {
    console.warn("avito command not found in the state");
    return rejectWithValue(null);
  }
  const apiCommandModel: GetAvitoCommandResType = {
    ...avitoCommand,
    ...arg.model,
  };
  try {
    const res = await avitoCommandApi.editCommandResponse({
      token: arg.token,
      model: apiCommandModel,
    });
    return { answer_id: arg.answerId, command_id: res.data.id, avitoCommand: res.data };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

export const avitoCommandReducer = slice.reducer;
export const avitoCommandAction = slice.actions;
export const avitoCommandThunk = {
  getAvitoCommand,
  addAvitoCommand,
  deleteAvitoCommand,
  editAvitoCommand,
};
