export function getTokenFromUrl(queryParameter: { params: string }) {
  try {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams !== null || true) {
      return queryParams.get(queryParameter.params);
    }
  } catch (e) {
    console.warn("There is no token, to resolve the issue please contact technical support");
    return null;
  }
}
