import React, { CSSProperties } from "react";
import s from "./Separator.module.scss";
import { clsx } from "clsx";

type ColorSeparatorType = 'dark' | 'light'

type SeparatorProps = {
  style?: CSSProperties;
  className?: string
  colorSeparator?: ColorSeparatorType
};

export const Separator: React.FC<SeparatorProps> = ({ style,className ,colorSeparator = 'dark'}) => {
  const classNames = {
    separator: clsx(s.separator, className, s[colorSeparator]),
  };

  return <div style={style} className={classNames.separator}></div>;
};
