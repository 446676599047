import React from "react";
import s from "./Help.module.scss";
import { Block } from "components/Layout/Block/Block";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { Accordion } from "components/Accordion/Accordion";
import { AccordionItem } from "components/Accordion/AccordionItem/AccordionItem";
import { faq, questions } from "./helpData";

export const Help = () => {
  const classNames = {
    layoutBlock: clsx(s.layoutBlock),
    childrenBlock: clsx(s.childrenBlock),
    help: clsx(s.help),
    faqTitle: clsx(s.faqTitle),
  };

  return (
    <Block
      title={"Помощь"}
      variant={"Large"}
      mb={"20px"}
      classNameWrapper={classNames.layoutBlock}
      classNameChildren={classNames.childrenBlock}
      borderNone
    >
      <div className={classNames.help}>
        <Typography className={classNames.faqTitle} variant={"h6"}>
          Часто задаваемые вопросы.
        </Typography>
        <Accordion fullwidth>
          {faq.map((el, i) => {
            return (
              <AccordionItem
                key={i}
                value={el.value}
                triggerTitle={el.trigger}
                content={el.content}
              />
            );
          })}
        </Accordion>
      </div>
      <div className={classNames.help}>
        <Typography className={classNames.faqTitle} variant={"h6"}>
          Вопросы.
        </Typography>
        <Accordion fullwidth>
          {questions.map((el, i) => {
            return (
              <AccordionItem
                key={i}
                value={el.value}
                triggerTitle={el.trigger}
                content={el.content}
              />
            );
          })}
        </Accordion>
      </div>
    </Block>
  );
};
