import {instance} from "../../../common/api/api";
import {GetAvitoCommandResType} from "./AvitoAnswer/AvitoCommand/avito.command.api";

/** АВИТО ЧАТ БОТ  API */

type ChatBotApiEndpointsType = {
    autoAnswer: 'avito/auto_answer/'
}

const chatBotApiEndpoints: ChatBotApiEndpointsType = {
    autoAnswer: 'avito/auto_answer/'
}

export const avitoChatBotApi = {
    getAutoAnswer: (arg: GetAutoAnswerReqType) => {
        return instance.get<GetAutoAnswerResponseType[]>(chatBotApiEndpoints.autoAnswer + `?account_id=${arg.account_id}`,
            {headers: {'Authorization': `Bearer ${arg.token}`}})
    },
    addAutoAnswer:(arg: AddedAutoAnswerReqType) => {
        return instance.post(chatBotApiEndpoints.autoAnswer, arg.domainModel, {headers: {'Authorization': `Bearer ${arg.token}`}})
    },
    updateAutoAnswer:(arg: UpdateAutoAnswerReqType)=> {
        return instance.put<UpdateAutoAnswerResDomainAPIType>(chatBotApiEndpoints.autoAnswer + `${arg.id}`, arg.domainModel, {
            headers: {'Authorization': `Bearer ${arg.token}`}
        })
    },
    deleteAutoAnswer:(arg:{id:number,token:string})=>{
        return instance.delete<string>(chatBotApiEndpoints.autoAnswer + `${arg.id}`, {
            headers: {'Authorization': `Bearer ${arg.token}`}
        })
    },
    commandPreview:(arg:{id:number, token:string}) => {
        return instance.get<string>(chatBotApiEndpoints.autoAnswer + `${arg.id}/commands_preview` , {
            headers: {'Authorization': `Bearer ${arg.token}`}
        })
    }
}

export type GetAutoAnswerReqType = {
    account_id: number,
    token: string
}
export type GetAutoAnswerResponseType = Omit<AddAutoAnswerResponseType, "token">
export type AddAutoAnswerResponseType = {
    name:string
    id: number
    account_id: number
    text: string
    ads_list:  string[]
    ads_list_mode: AdsListModeType
    auto_answers_setting: AutoAnswersSettingType
    time_intervals: TimeIntervals
    time_offset_from_utc: number
    message_pause_seconds:number
    commands_status:boolean
    commands_list: GetAvitoCommandResType[]
    commands_description: string
    send_commands_list:boolean
} & { token: string}

export type AdsListModeType =  'ALL' | "INCLUDE" | "EXCLUDE"
// ALL - для всех
// INCLUDE- включая
// EXCLUDE - исключая

export type AutoAnswersSettingType = 'OFF' | "TIME_INTERVAL" | "FIRST_MESSAGE"

export type TimeIntervalItemType = {
    end: string;
    start: string;
    next_day_end: boolean;
    is_active: boolean
}
export type TimeIntervals = {
    [key: string]: TimeIntervalItemType;
}

export type UpdateAutoAnswerResDomainType = {
    name?:string
    id?: number
    account_id?: number
    text?: string
    // ads_list?:  string[] | string; //переделат тип(затычка = string не идет в ads_list)
    ads_list?:  string[]
    ads_list_mode?: AdsListModeType
    auto_answers_setting?: AutoAnswersSettingType;
    time_intervals?: TimeIntervals
    message_pause_seconds?:number
    time_offset_from_utc?: number
    commands_status?:boolean
    commands_list?: GetAvitoCommandResType[]
    commands_description?: string
    send_commands_list?:boolean
}
export type UpdateAutoAnswerResDomainAPIType = {
    name:string
    id: number
    account_id: number
    text: string;
    ads_list:  string[]
    ads_list_mode: AdsListModeType
    auto_answers_setting: AutoAnswersSettingType
    time_intervals: TimeIntervals
    time_offset_from_utc: number
    message_pause_seconds:number
    commands_status:boolean
    commands_list: GetAvitoCommandResType[]
    commands_description: string
    send_commands_list:boolean
}
export type UpdateAutoAnswerReqType = {
    avitoAccId:string
    id: number ,
    token: string,
    domainModel : UpdateAutoAnswerResDomainType
}
export type UpdateAutoAnswerReqAdsListType = {
    avitoAccId:string
    id: number ,
    token: string,
    ads_list_str: string
}

export type AddedAutoAnswerReqType = {
    token: string,
    domainModel : AddedAutoAnswerObject
}
export type AddedAutoAnswerObject  = {
    name:string
    account_id: number;
    text: string;
    ads_list:  string[];
    ads_list_mode: AdsListModeType;
    auto_answers_setting: AutoAnswersSettingType;
    time_intervals: TimeIntervals;
    time_offset_from_utc: number;
    commands_status:boolean
    commands_list: GetAvitoCommandResType[]
    message_pause_seconds:number
    commands_description: string
    send_commands_list:boolean
}