import * as yup from "yup";
import { validationSchemes } from "../validationSchemes/validationSchemes";

export enum RefCommandEnum {
  CREATECOMMAND = "createCommand",
  EDITCOMMAND = "editCommand",
  OFF = "off",
}

export function hookFormHandler(
  commandType: string | undefined | null,
  commandValue: string | undefined,
  commandResponse: string | undefined,
) {
  if (commandType === RefCommandEnum.CREATECOMMAND) {
    commandValue = "";
    commandResponse = "";
  }
  return { commandValue, commandResponse };
}

export function createValidationSchema(commandType: string | undefined | null, commandList: any[]) {
  return yup.object().shape({
    question: validationSchemes.commands.question.test(
      "unique-question",
      "Команда уже добавлена",
      function (value) {
        if (commandType !== RefCommandEnum.CREATECOMMAND) {
          return true;
        }
        if (!value) {
          return true;
        }
        const lowercaseValue = value.toLowerCase();
        return !commandList?.some((el) => el.command_value.toLowerCase() === lowercaseValue);
      },
    ),
    answer: validationSchemes.commands.answer,
  });
}
