import { instance } from "../../../common/api/api";

type VkAccountApi = {
  vkAccount: "vk/account/";
};
const avitoAccountEndpoints = {
  vkAccount: "vk/account/",
} as VkAccountApi;

export const vkAccountApi = {
  addAvitoAccount: (arg: LinkVkAccountReq) => {
    return instance.post<LinkVkAccountRes>(
      avitoAccountEndpoints.vkAccount + `?api_key=${arg.api_key}`,
      {},
      { headers: { Authorization: `Bearer ${arg.token}` } },
    );
  },
  deleteAvitoAccount: (arg: DeleteVkAccountReq) => {
    return instance.delete(
      avitoAccountEndpoints.vkAccount + `${arg.account_id}?is_confirmed=${arg.is_confirmed}`,
      {
        headers: { Authorization: `Bearer ${arg.token}` },
      },
    );
  },
  statusWebHook: (arg: StatusWebHook) => {
    return instance.get<StatusWebHookRes>(
      avitoAccountEndpoints.vkAccount + `${arg.account_id}/webhook`,
      {
        headers: { Authorization: `Bearer ${arg.token}` },
      },
    );
  },
};

export type LinkVkAccountReq = {
  token: string;
  api_key: string;
};
export type DeleteVkAccountReq = {
  account_id: number;
  is_confirmed: boolean;
  token: string;
};
export type StatusWebHook = Omit<DeleteVkAccountReq, "is_confirmed">;
export type StatusWebHookRes = null | boolean;

export type LinkVkAccountRes = {
  status_code: number;
  detail: string;
  headers: null;
};
