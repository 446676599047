import React, { useContext, useState } from "react";
import EmojiPicker, { EmojiClickData, EmojiStyle } from "emoji-picker-react";
import { PreviewConfig } from "emoji-picker-react/dist/config/config";
import { Theme, ThemeContext } from "../../common/hoc/ThemeProvider/ThemeContext";

type EmojiType = {
  onEmojiClick: (emoji: string) => void;
  autoFocusSearch?: boolean;
  className?: string;
  searchDisabled?: boolean;
  width?: number | string;
  height?: number | string;
};
export const Emoji: React.FC<EmojiType> = (props) => {
  const {
    onEmojiClick,
    autoFocusSearch = false,
    className,
    searchDisabled = true,
    height,
    width,
    ...otherProps
  } = props;

  const { theme } = useContext(ThemeContext);
  const [selectedEmoji, setSelectedEmoji] = useState<string>("");

  const onClick = (emojiData: EmojiClickData) => {
    const emoji = emojiData.isCustom ? emojiData.unified : emojiData.emoji;
    setSelectedEmoji(emoji);
    onEmojiClick(emoji);
  };

  const previewConfig = {
    defaultCaption: "",
    showPreview: false,
  } as PreviewConfig;

  return (
    <EmojiPicker
      theme={theme === Theme.DARK ? Theme.DARK : Theme.LIGHT}
      onEmojiClick={onClick}
      autoFocusSearch={autoFocusSearch}
      emojiStyle={EmojiStyle.APPLE}
      lazyLoadEmojis={false}
      searchDisabled={searchDisabled}
      width={width}
      skinTonesDisabled={true}
      height={height}
      previewConfig={previewConfig}
      {...otherProps}
    />
  );
};

// import React, {useState} from 'react';
// import EmojiPicker, {EmojiClickData, EmojiStyle} from "emoji-picker-react";
// import {PreviewConfig} from "emoji-picker-react/dist/config/config";
//
//
// type EmojiType = {
//     onEmojiClick: (emoji: string) => void
//     autoFocusSearch?: boolean
//     className?: string
//     searchDisabled?: boolean
//     width?: number | string
//     height?: number | string
// }
// export const Emoji: React.FC<EmojiType> = (props) => {
//
//     const {
//         onEmojiClick,
//         autoFocusSearch = false,
//         className,
//         searchDisabled = true,
//         height,
//         width,
//         ...otherProps
//     } = props
//     const [selectedEmoji, setSelectedEmoji] = useState<string>("");
//
//     const onClick = (emojiData: EmojiClickData) => {
//         const emoji = emojiData.isCustom ? emojiData.unified : emojiData.emoji;
//         setSelectedEmoji(emoji);
//         onEmojiClick(emoji);
//     };
//
//     const previewConfig = {
//         defaultCaption: '',
//         showPreview: false,
//     } as PreviewConfig
//
//     return (
//         <EmojiPicker
//             onEmojiClick={onClick}
//             autoFocusSearch={autoFocusSearch}
//             emojiStyle={EmojiStyle.NATIVE}
//             lazyLoadEmojis={false}
//             searchDisabled={searchDisabled}
//             width={width}
//             skinTonesDisabled={true}
//             height={height}
//             previewConfig={previewConfig}
//             {...otherProps}
//         />
//     );
// };
