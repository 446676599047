import {
  ComponentPropsWithoutRef,
  ElementRef,
  ElementType,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from "react";

import s from "./Layout.module.scss";
import { clsx } from "clsx";

export type LayoutType<T extends ElementType = "div"> = {
  as?: T;
  children?: ReactNode;
  className?: string;
} & ComponentPropsWithoutRef<T>;

export const Layout = forwardRef((props: LayoutType, ref: ForwardedRef<ElementRef<"div">>) => {
  const { children, className, as: Component = "div", ...rest } = props;

  const classNames = {
    root: clsx(s.layout, className && className),
  };
  return (
    <Component ref={ref} className={classNames.root} {...rest}>
      {children}
    </Component>
  );
});
