import React, { ComponentPropsWithoutRef, ElementType, Ref, forwardRef } from "react";
import { clsx } from "clsx";

import s from "./IconButton.module.scss";
import { IconProps } from "../../Icon/IconWrapper/IconWrapper";

type IconType = React.ReactElement<IconProps>;

export type ButtonProps<T extends ElementType = "button"> = {
  as?: T;
  backgroundEffect?: boolean;
  children?: IconType;
  className?: string;
  icon?: string;
  variant?: "gray" | "primary" | "secondary" | "dark";
} & ComponentPropsWithoutRef<T>;

const IconButtonBase = <T extends ElementType>(
  props: ButtonProps<T>,
  ref: Ref<HTMLButtonElement>,
) => {
  const {
    as: Component = "button",
    backgroundEffect = true,
    children,
    className,
    variant = "secondary",
    ...rest
  } = props;

  const iconButtonClasses = {
    button: clsx(
      s.button,
      variant && s[variant],
      backgroundEffect && s.backgroundEffect,
      !backgroundEffect && s.notBackgroundEffect,
      className && className,
    ),
  };

  return (
    <Component className={iconButtonClasses.button} ref={ref} {...rest}>
      {children}
    </Component>
  );
};

export const IconButton = forwardRef(IconButtonBase);
