import React, { ElementRef, forwardRef, useContext } from "react";
import * as AccordionRadixComponent from "@radix-ui/react-accordion";
import s from "./AccordionTrigger.module.scss";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { clsx } from "clsx";
import { Theme, ThemeContext } from "../../../../common/hoc/ThemeProvider/ThemeContext";

type AccordionTriggerProps = {
  children: React.ReactNode;
};

export const AccordionTrigger = forwardRef<
  ElementRef<typeof AccordionRadixComponent.Trigger>,
  AccordionTriggerProps
>((props, ref) => {
  const { children } = props;

  const { theme } = useContext(ThemeContext);
  const classNames = {
    accordionHeader: clsx(s.accordionHeader),
    accordionTrigger: clsx(s.accordionTrigger),
    accordionChevron: clsx(s.accordionChevron),
  };
  const inlineStyle =
    theme === Theme.DARK
      ? {
          backgroundColor: "var(--dark-theme-background-color-block)",
          color: "var(--dark-theme-color-text)",
          border: "1px solid var(--dark-theme-border-color)",
        }
      : {};

  return (
    <>
      <AccordionRadixComponent.Header className={classNames.accordionHeader}>
        <AccordionRadixComponent.Trigger
          className={classNames.accordionTrigger}
          style={inlineStyle}
          {...props}
          ref={ref}
        >
          {children}
          <ChevronDownIcon className={classNames.accordionChevron} aria-hidden />
        </AccordionRadixComponent.Trigger>
      </AccordionRadixComponent.Header>
    </>
  );
});
