import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "../../../common/utils/createAppAsyncThunk";
import {
  GetAutoAnswerResponseType,
  vkChatBotApi,
  UpdateAutoAnswerResDomainType,
  UpdateAutoAnswerResDomainAPIType,
  AddedAutoAnswerObject,
  UpdateAutoAnswerReqDomainType,
} from "./vk.chatBot.api";
import { appAction } from "../../../app/app-reducer";
import { handleApiError } from "../../../common/utils/errorUtils";
import { authThunks } from "../../Auth/auth-reducer";
import { vkCommandThunk } from "./vkAnswer/vkCommand/vkCommandReducer";
import { vkAccountThunks } from "../../Account/VkAccount/vkAccountReducer";

export type KeyDisabledAndLoading =
  | "name"
  | "pause"
  | "auto_answers_setting"
  | "time_offset_from_utc"
  | "send_commands_list";
export type LoadingAndDisabledState = {
  [K in KeyDisabledAndLoading]: boolean;
};

export type AnswersStateType = {
  [key: string]: Array<GetAutoAnswerResponseType>;
};
export type AnswersPaginationState = {
  [key: string]: { countElement: number; currentPage: number };
};

const initialState = {
  vkChatBot: {
    vkAnswer: {} as AnswersStateType,
    vkAnswerPagination: {} as AnswersPaginationState, //Вычисление количество элементов массива для Pagination
    commandPreview: "" as string,
    loadingAndDisabledSystem: {
      basic_settings: {
        name: false,
        pause: false,
        auto_answers_setting: false,
        time_offset_from_utc: false,
      } as LoadingAndDisabledState,
      vk_command: {
        send_commands_list: false,
      } as LoadingAndDisabledState,
    },
    limit: 1 as number, //лимит на 1 странице (не менять!)
    portionPage: 10 as number,
  },
};
const slice = createSlice({
  name: "vkChatBot",
  initialState,
  reducers: {
    setCurrentPageNew(state, action: PayloadAction<{ accountId: string; currentPage: number }>) {
      const { accountId, currentPage } = action.payload;
      if (state.vkChatBot.vkAnswerPagination[accountId]) {
        state.vkChatBot.vkAnswerPagination[accountId].currentPage = currentPage;
      }
    },
    setTotalCount(state, action: PayloadAction<{ accountId: string; totalCount: number }>) {
      const { accountId, totalCount } = action.payload;
      if (state.vkChatBot.vkAnswerPagination[accountId]) {
        state.vkChatBot.vkAnswerPagination[accountId].countElement = totalCount;
      }
    },
    setLoadingAndDisabledSystem(
      state,
      action: PayloadAction<{ key: KeyDisabledAndLoading; value: boolean }>,
    ) {
      state.vkChatBot.loadingAndDisabledSystem.basic_settings[action.payload.key] =
        action.payload.value;
      state.vkChatBot.loadingAndDisabledSystem.vk_command[action.payload.key] =
        action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authThunks.profile.fulfilled, (state, action) => {
        action.payload.profile.vk_accounts.forEach((el) => {
          state.vkChatBot.vkAnswer[el.id] = [];
          state.vkChatBot.vkAnswerPagination[el.id] = { countElement: 0, currentPage: 0 };
        }); //* Формирования ID для автоответчиков vk */
      })
      .addCase(thunkVkChatBot.getAutoAnswer.fulfilled, (state, action) => {
        const vkAnswers = action.payload.vkAnswer;
        vkAnswers.forEach((vkAnswerArray) => {
          vkAnswerArray.forEach((answer) => {
            const accountId = answer.account_id;
            if (state.vkChatBot.vkAnswer[accountId]) {
              state.vkChatBot.vkAnswer[accountId] = [...vkAnswerArray];
              state.vkChatBot.vkAnswerPagination[accountId] = {
                countElement: vkAnswerArray.length,
                currentPage: 1,
              };
            }
          });
        });
      })
      .addCase(thunkVkChatBot.updateAutoAnswer.fulfilled, (state, action) => {
        const vkAccountId = action.payload.autoAnswer.account_id;
        const answerId = action.payload.autoAnswer.id;
        const autoAnswer = action.payload.autoAnswer;

        if (vkAccountId === undefined) return;
        const autoAnswers = state.vkChatBot.vkAnswer[vkAccountId];

        const index = autoAnswers.findIndex((el) => el.id === answerId);
        if (index !== -1) {
          autoAnswers[index] = { ...autoAnswers[index], ...autoAnswer };
        }
      })
      .addCase(thunkVkChatBot.addedVkAnswer.fulfilled, (state, action) => {
        const vkAccountId = action.payload.autoAnswer.account_id;
        const vkAnswer = action.payload.autoAnswer;
        state.vkChatBot.vkAnswer[vkAccountId].push(vkAnswer);
      })
      .addCase(thunkVkChatBot.deleteAutoAnswer.fulfilled, (state, action) => {
        const accountId = action.payload.accountId;
        const answerId = action.payload.answerId;

        const index = state.vkChatBot.vkAnswer[accountId].findIndex(
          (autoAnswer) => autoAnswer.id === answerId,
        );
        if (index !== -1) {
          state.vkChatBot.vkAnswer[accountId].splice(index, 1);
        }
      })
      .addCase(vkAccountThunks.deleteVkAcc.fulfilled, (state, action) => {
        delete state.vkChatBot.vkAnswer[action.payload.id];
        delete state.vkChatBot.vkAnswerPagination[action.payload.id];
      })
      .addCase(thunkVkChatBot.commandPreview.fulfilled, (state, action) => {
        state.vkChatBot.commandPreview = action.payload.previewValue;
      });
  },
});

const getAutoAnswer = createAppAsyncThunk<
  { vkAnswer: GetAutoAnswerResponseType[][] },
  {
    account_ids: number[];
  }
>("vkChatBot/getAutoAnswerUpdate", async (arg, thunkAPI) => { //требуется оптимизация
  const { dispatch, rejectWithValue, getState } = thunkAPI;

  dispatch(appAction.setIsLoading({ isLocalLoading: true }));
  const token = getState().authorization.login.access_token;

  try {
    const promises = arg.account_ids.map((account_id) =>
      vkChatBotApi.getAutoAnswer({ token: token, account_id: account_id }),
    );
    const responses = await Promise.all(promises);
    const vkAnswers = responses.map((res) => res.data);

    /** Сущность получения комманд vkCommand */
    vkAnswers.forEach((answers, index) => {
      const accountId = arg.account_ids[index];
      answers.forEach((answer) => {
        const vkAnswerId = answer.id;
        dispatch(
          vkCommandThunk.getVkCommand({ account_id: accountId, answer_id: vkAnswerId, token }),
        ); //Получение комманд
      });
    });

    return { vkAnswer: vkAnswers };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const updateAutoAnswer = createAppAsyncThunk<
  {
    autoAnswer: UpdateAutoAnswerResDomainType;
  },
  UpdateAutoAnswerReqDomainType
>("vkChatBot/updateAutoAnswer", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue, getState } = thunkAPI;

  dispatch(appAction.setIsLoading({ isLocalLoading: true }));

  const state = getState();
  const autoAnswer = state.vkChatBot.vkChatBot.vkAnswer[arg.accountId].find(
    (el) => el.id === arg.id,
  );
  if (!autoAnswer) {
    console.warn("autoAnswer not found in the state");
    return rejectWithValue(null);
  }

  const apiModel: UpdateAutoAnswerResDomainAPIType = {
    ...autoAnswer,
    ...arg.domainModel,
  };
  try {
    const res = await vkChatBotApi.updateAutoAnswer({
      id: arg.id,
      token: arg.token,
      domainModel: apiModel,
    });
    return { autoAnswer: res.data };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const addedVkAnswer = createAppAsyncThunk<
  { autoAnswer: GetAutoAnswerResponseType },
  {
    vkAccId: string;
    name: string;
  }
>("vkChatBot/addedVkAnswer", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));

  const token = getState().authorization.login.access_token;

  const idVkAccount = arg.vkAccId;
  const name = arg.name;

  const model = {
    name: name,
    account_id: Number(idVkAccount), // соблюдение типизации
    text: "",
    message_pause_seconds: 86400,
    send_commands_list: false,
    auto_answers_setting: "OFF",
    time_intervals: {
      "0": {
        end: "23:59",
        start: "00:00",
        next_day_end: false,
        is_active: false,
      },
      "1": {
        end: "23:59",
        start: "00:00",
        next_day_end: false,
        is_active: false,
      },
      "2": {
        end: "23:59",
        start: "00:00",
        next_day_end: false,
        is_active: false,
      },
      "3": {
        end: "23:59",
        start: "00:00",
        next_day_end: false,
        is_active: false,
      },
      "4": {
        end: "23:59",
        start: "00:00",
        next_day_end: false,
        is_active: false,
      },
      "5": {
        end: "23:59",
        start: "00:00",
        next_day_end: false,
        is_active: false,
      },
      "6": {
        end: "23:59",
        start: "00:00",
        next_day_end: false,
        is_active: false,
      },
    },
    time_offset_from_utc: 3,
    commands_status: false,
    commands_list: [],
    commands_description: "",
  } as AddedAutoAnswerObject;
  try {
    const res = await vkChatBotApi.addAutoAnswer({ token: token, domainModel: model });

    // debugger
    /** PAGINATION */
    const totalCount =
      getState().vkChatBot.vkChatBot.vkAnswerPagination[idVkAccount].countElement + 1;
    const setCurrentPage =
      getState().vkChatBot.vkChatBot.vkAnswerPagination[idVkAccount].currentPage + 1;

    dispatch(vkChatBotAction.setTotalCount({ accountId: idVkAccount, totalCount: totalCount }));
    dispatch(
      vkChatBotAction.setCurrentPageNew({ accountId: idVkAccount, currentPage: setCurrentPage }),
    );
    /** PAGINATION */

    return { autoAnswer: res.data };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const deleteAutoAnswer = createAppAsyncThunk<
  { accountId: string; answerId: number },
  {
    accountId: string;
    answerId: number;
  }
>("vkChatBot/deleteVkAnswer", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue, getState } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));

  const token = getState().authorization.login.access_token;

  try {
    await vkChatBotApi.deleteAutoAnswer({ id: arg.answerId, token: token });

    /** PAGINATION */
    const totalCount =
      getState().vkChatBot.vkChatBot.vkAnswerPagination[arg.accountId].countElement;

    const resultTotalCount = Math.ceil(totalCount - 1);
    const resultCurrentPage = Math.ceil(1);
    /** PAGINATION */

    dispatch(
      vkChatBotAction.setTotalCount({ accountId: arg.accountId, totalCount: resultTotalCount }),
    );
    dispatch(
      vkChatBotAction.setCurrentPageNew({
        accountId: arg.accountId,
        currentPage: resultCurrentPage,
      }),
    );

    dispatch(appAction.setAlerts({ alerts: "Автоответчик удален" }));
    return { accountId: arg.accountId, answerId: arg.answerId };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

const commandPreview = createAppAsyncThunk<
  { previewValue: string },
  {
    id: number;
    token: string;
  }
>("avitoChatBot/commandPreview", async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(appAction.setIsLoading({ isLocalLoading: true }));
  try {
    const res = await vkChatBotApi.commandPreview({ id: arg.id, token: arg.token });
    return { previewValue: res.data };
  } catch (e) {
    handleApiError(e, dispatch);
    return rejectWithValue(null);
  } finally {
    dispatch(appAction.setIsLoading({ isLocalLoading: false }));
  }
});

export const vkChatBotReducer = slice.reducer;
export const vkChatBotAction = slice.actions;
export const thunkVkChatBot = {
  updateAutoAnswer,
  addedVkAnswer,
  deleteAutoAnswer,
  commandPreview,
  getAutoAnswer,
};
