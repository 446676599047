import React, { useCallback } from "react";
import s from "./AdsList.module.scss";
import { ButtonMiu } from "../../../../../components/Buttons/Button/ButtonMiu";
import { Chip } from "@mui/material";
import { TooltipMiu } from "../../../../../components/TooltipMiu/TooltipMiu";
import { AdsListModeType, AutoAnswersSettingType } from "../../avito.chatBot.api";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Block } from "../../../../../components/Layout/Block/Block";
import { IcInfo } from "../../../../../components/Icon/IconComponents/IcInfo";
import { TooltipMessages } from "./enum";
import { SelectItem } from "../../../../../components/Select/SelectItem";
import { Select } from "../../../../../components/Select";
import { useAppSelector } from "../../../../../common/hooks/hooks";
import { LoadingAndDisabledState } from "../../avitoChatBotReducer";
import { ControlledTextField } from "../../../../../components/TextField/controlled-textField";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "../../../../../components/Buttons/IconButton";

type AdsListProps = {
  avitoAccId: string;
  mode: AdsListModeType;
  id: number;
  onChangeSelectMode: (avitoAccId: string, id: number, mode: AdsListModeType) => void;
  adsList: string[];
  addedAdsList: (avitoAccId: string, id: number, adsList: string) => void;
  removeElementAdsList: (avitoAccId: string, id: number, removeElement: string) => void;
  autoAnswersSetting: AutoAnswersSettingType;
};

const modeWork = [
  { value: "ALL", text: "Вкл для всех объявлений" },
  { value: "INCLUDE", text: "Вкл для списка объявлений" },
  { value: "EXCLUDE", text: "Выкл для списка объявлений" },
];

export const AdsList = (props: AdsListProps) => {
  const {
    avitoAccId,
    id,
    onChangeSelectMode,
    mode,
    adsList,
    autoAnswersSetting,
    addedAdsList,
    removeElementAdsList,
  } = props;

  const schema = yup.object().shape({
    announcement: yup
      .string()
      .transform((originalValue) => {
        if (typeof originalValue === "string") {
          return originalValue.replace(/\s/g, "");
        }
        return originalValue;
      })
      .matches(/^\d+$/, "Разрешены только цифры")
      .test("uniqueAnnouncement", "Объявление уже добавлено", function (value) {
        if (!value) {
          return true;
        }
        return !adsList.includes(value);
      }),
  });
  type FormDataType = yup.InferType<typeof schema>;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { announcement: "" },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const loadingAndDisabledValues = useAppSelector<LoadingAndDisabledState>(
    (state) => state.avitoChatBot.avitoChatBot.loadingAndDisabledSystem.basic_settings,
  );
  const onSubmit = useCallback(
    (data: FormDataType) => {
      const id = props.id;
      addedAdsList(avitoAccId, id, data.announcement as string);
      reset();
    },
    [addedAdsList, avitoAccId, props.id, reset],
  );

  const onClickDeleteExcludeAnnouncement = useCallback(
    (announcement: string) => {
      const id = props.id;
      removeElementAdsList(avitoAccId, id, announcement);
    },
    [avitoAccId, props.id, removeElementAdsList],
  );

  const handleSelectModeChange = useCallback(
    (id: number, event: string) => {
      const mode = event as AdsListModeType;
      onChangeSelectMode(avitoAccId, id, mode);
    },
    [avitoAccId, onChangeSelectMode],
  );

  return (
    <Block
      classNameWrapper={s.blockWrapper}
      title={
        mode === "INCLUDE"
          ? "Cписок объявлений (включая)"
          : mode === "EXCLUDE"
            ? "Cписок объявлений (исключая)"
            : "Cписок объявлений"
      }
      trigger={
        <>
          {(mode === "INCLUDE" || mode === "EXCLUDE") && (
            <IconButton>
              <IcInfo size={1.1} />
            </IconButton>
          )}
        </>
      }
      tooltipTrigger={`${mode === "INCLUDE" ? TooltipMessages.include : TooltipMessages.exclude}`}
    >
      <div className={s.adsMode}>
        <Select
          align={"end"}
          classNameTrigger={s.triggerSelect}
          disabled={loadingAndDisabledValues["ads_list_Mode"]}
          scrollBtn={false}
          loading={loadingAndDisabledValues["ads_list_Mode"]}
          value={mode}
          onValueChange={(event) => handleSelectModeChange(id, event)}
        >
          {modeWork.map((el, index) => {
            return (
              <SelectItem key={index} value={el.value}>
                {el.text}
              </SelectItem>
            );
          })}
        </Select>
      </div>
      <TooltipMiu
        title={
          autoAnswersSetting === "OFF" || mode === "ALL"
            ? "Список объявлений недоступен. Для доступа к списку объявлений переключите «Состояние» чат-бота."
            : ""
        }
        followCursor={true}
        className={s.tooltipExclude}
      >
        <form className={s.formExcludedAds} onSubmit={handleSubmit(onSubmit)}>
          <ControlledTextField
            textFieldClassName={s.textFieldExcludedAds}
            control={control}
            placeholder={autoAnswersSetting === "OFF" || mode === "ALL" ? "ввод запрещен" : ""}
            name={"announcement"}
            id="outlined-basic-message"
            label={"Введите ID объявления"}
            error={errors.announcement && errors.announcement.message}
            disabled={autoAnswersSetting === "OFF" || mode === "ALL"}
          />
          <ButtonMiu
            title={"Добавить"}
            variant={"outlined"}
            className={s.btnExcludeProfileStyles}
            disabled={autoAnswersSetting === "OFF" || mode === "ALL"}
            type={"submit"}
          />
        </form>
        <div className={s.excludeProfileFlex}>
          {adsList &&
            adsList.map((el, index) => {
              return (
                <div key={index} className={s.excludeProfile}>
                  <Chip
                    className={s.chip}
                    disabled={autoAnswersSetting === "OFF" || mode === "ALL"}
                    label={el}
                    size="small"
                    variant="outlined"
                    deleteIcon={<DeleteIcon className={s.chipIcon} />}
                    onDelete={() => onClickDeleteExcludeAnnouncement(el)}
                  />
                </div>
              );
            })}
        </div>
      </TooltipMiu>
    </Block>
  );
};
