import React, { useContext, useEffect, useState } from "react";
import s from "./Account.module.scss";
import { ButtonMiu } from "components/Buttons/Button/ButtonMiu";
import PaidIcon from "@mui/icons-material/Paid";
import CheckIcon from "@mui/icons-material/Check";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import { useAppDispatch, useAppSelector } from "../../common/hooks/hooks";
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import EmailSharpIcon from "@mui/icons-material/EmailSharp";
import { CustomizedTabs, TabItem } from "../../components/CustomizedTabs/CustomizedTabs";
import { AvitoAccount } from "./AvitoAccount/AvitoAccount";
import HttpsSharpIcon from "@mui/icons-material/HttpsSharp";
import { authActions, authThunks } from "../Auth/auth-reducer";
import { deleteTokenInLocalStorage } from "../../common/utils/localStorageUtils";
import { ProfileResType } from "../Auth/auth.api";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import PlayCircleFilledWhiteSharpIcon from "@mui/icons-material/PlayCircleFilledWhiteSharp";
import { TooltipMiu } from "../../components/TooltipMiu/TooltipMiu";
import {
  formatDateToCustomFormat,
  parsingDateInMilliseconds,
} from "../../common/utils/DateProcessing";
import { AvitoIcon } from "../../components/Icon/IconComponents/AvitoIcon";
import { VkontakteIcon } from "../../components/Icon/IconComponents/VkontakteIcon";
import { VkAccount } from "./VkAccount/VkAccount";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Block } from "../../components/Layout/Block/Block";
import { validationSchemes } from "../../common/validationSchemes/validationSchemes";
import { Typography } from "../../components/Typography/Typography";
import { ControlledTextField } from "../../components/TextField/controlled-textField";
import { Theme, ThemeContext } from "../../common/hoc/ThemeProvider/ThemeContext";
import { Switch } from "../../components/Switch/Switch";
import { useNavigate } from "react-router-dom";
import { PATCH } from "../Pages";

const schema = yup.object().shape({
  password: validationSchemes.auth.password,
  confirmPassword: validationSchemes.auth.confirmPassword,
});

type FormDataType = yup.InferType<typeof schema>;

export const Account = () => {
  const token = useAppSelector<string>((state) => state.authorization.login.access_token);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(authThunks.profile({ token: token }));
  }, [dispatch, token]);

  const tabs: TabItem[] = [
    { icon: <AvitoIcon />, component: <AvitoAccount /> },
    { icon: <VkontakteIcon />, component: <VkAccount /> },
  ];

  const navigation = useNavigate();
  const profile = useAppSelector<ProfileResType>((state) => state.authorization.profile);
  const actualDate = useAppSelector<number | null>((state) => state.app.actualDate);
  const iconSxGreen = { color: "#27a456", fontSize: "20px", marginLeft: "7px" };
  const iconSxRed = { color: "#ff0000", fontSize: "20px", marginLeft: "7px" };
  const dataExpiryMoment = parsingDateInMilliseconds(profile.access_expiry_moment); //Дата окончания подписки в мс
  const disabledButtonTariff = profile.has_access === false || profile.has_access === null;
  const { theme, setTheme } = useContext(ThemeContext);
  const [checkTheme, setCheckTheme] = useState<boolean>(theme !== Theme.DARK);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormDataType>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = (data: FormDataType) => {
    dispatch(authThunks.changePass({ new_password: data.password, token }));
    reset();
  };
  const onclickButtonTariff = () => {
    navigation(PATCH.SUBSCRIPTION);
  };
  const onclickButtonLogout = () => {
    deleteTokenInLocalStorage("tokenChatBot");
    dispatch(authActions.logout({ me: false }));
  };
  const onclickButtonDemoMode = () => {
    dispatch(authThunks.requestDemo({ token }));
  };

  const onChangeValueTheme = () => {
    setCheckTheme(!checkTheme);

    if (checkTheme && Theme.DARK) {
      setTheme("dark" as Theme);
    } else {
      setTheme("light" as Theme);
    }
  };

  return (
    <Block
      classNameWrapper={s.layoutAccount}
      classNameTitleBlock={s.layoutAccountTitleBlock}
      borderNone
      title={"Профиль"}
      mb={"20px"}
      variant={"Large"}
      trigger={
        <TooltipMiu
          placement={"top-start"}
          title={theme === Theme.DARK ? "Включить светлую тему" : "Включить темную тему"}
        >
          <Switch
            themeMod
            checked={checkTheme}
            onCheckedChange={onChangeValueTheme}
            classNameWrapper={s.switchToggleTheme}
          />
        </TooltipMiu>
      }
    >
      <div className={s.untRow}>
        <Block
          title={"Информация"}
          classNameWrapper={s.blockSettings}
          classNameChildren={s.blockSettingsChildren}
        >
          <div className={s.stateFlex}>
            <Typography asChild>
              <span>Электронная почта:</span>
            </Typography>
            <div className={s.answer}>
              <Typography asChild>
                <span>{profile.email}</span>
              </Typography>
              <EmailSharpIcon sx={iconSxGreen} />
            </div>
          </div>
          <div className={s.stateFlex}>
            <Typography asChild>
              <span>Аккаунт:</span>
            </Typography>
            <div className={s.answer}>
              <Typography
                asChild
                colorText={
                  profile.has_access === null || !profile.has_access ? "danger" : undefined
                }
              >
                <span>
                  {profile.has_access === null || !profile.has_access
                    ? "не подтвержден"
                    : "подтвержден"}
                </span>
              </Typography>
              {profile.has_access === null || !profile.has_access ? (
                <ClearSharpIcon sx={iconSxRed} />
              ) : (
                <CheckCircleSharpIcon sx={iconSxGreen} />
              )}
            </div>
          </div>
          <div className={s.blockButton}>
            <div className={s.stateFlexBtn}>
              <ButtonMiu
                title={"Выйти"}
                callBack={onclickButtonLogout}
                endCoin={<LogoutSharpIcon />}
                variant={"outlined"}
              />
            </div>
          </div>
        </Block>
        <Block
          title={"Подписка"}
          classNameWrapper={s.blockSettings}
          classNameChildren={s.blockSettingsChildren}
        >
          <div className={s.stateFlex}>
            <Typography asChild>
              <span>Состояние:</span>
            </Typography>
            <div className={profile.has_access ? s.answer : s.badAnswer}>
              {profile.has_access &&
              actualDate !== null &&
              dataExpiryMoment !== null &&
              actualDate < dataExpiryMoment ? (
                <React.Fragment>
                  <Typography asChild>
                    <span>доступен:</span>
                  </Typography>
                  <CheckIcon sx={iconSxGreen} />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography colorText={"danger"} asChild>
                    <span>без доступа:</span>
                  </Typography>
                  <ClearSharpIcon sx={{ color: "#ff0000", fontSize: "20px", marginLeft: "7px" }} />
                </React.Fragment>
              )}
            </div>
          </div>
          <div className={s.stateFlex}>
            <Typography asChild>
              <span>Оплачен до:</span>
            </Typography>
            <div className={profile.has_access ? s.answer : s.badAnswer}>
              {profile.has_access &&
              actualDate !== null &&
              dataExpiryMoment !== null &&
              actualDate < dataExpiryMoment ? (
                <React.Fragment>
                  <Typography asChild>
                    <span>{formatDateToCustomFormat(profile.access_expiry_moment)}</span>
                  </Typography>
                  <CalendarMonthSharpIcon sx={iconSxGreen} />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <TooltipMiu title={"не аĸтивирован"} placement={"top"}>
                    <Typography colorText={"danger"} asChild>
                      <span>{formatDateToCustomFormat(profile.access_expiry_moment)}</span>
                    </Typography>
                    <ClearSharpIcon
                      sx={{ color: "#ff0000", fontSize: "20px", marginLeft: "7px" }}
                    />
                  </TooltipMiu>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className={s.blockButton}>
            <div className={s.stateFlexBtn}>
              {profile.has_used_demo === null && profile.access_expiry_moment === null ? (
                <React.Fragment>
                  <TooltipMiu
                    placement={"top"}
                    title={
                      profile.has_access !== true ? "Подтвердите аккаунт" : "активировать demo"
                    }
                  >
                    <ButtonMiu
                      title={"demo"}
                      callBack={onclickButtonDemoMode}
                      endCoin={<PlayCircleFilledWhiteSharpIcon />}
                      variant={"outlined"}
                      disabled={profile.has_access !== true}
                    />
                  </TooltipMiu>
                  <TooltipMiu
                    placement={"top"}
                    title={
                      profile.has_access !== true ? "Подтвердите аккаунт" : "Приобрести доступ"
                    }
                  >
                    <ButtonMiu
                      title={"Приобрести доступ"}
                      callBack={onclickButtonTariff}
                      endCoin={<PaidIcon />}
                      variant={"outlined"}
                      disabled={disabledButtonTariff}
                      style={{ marginLeft: "5px", marginTop: "5px" }}
                    />
                  </TooltipMiu>
                </React.Fragment>
              ) : (
                <ButtonMiu
                  title={"Приобрести доступ"}
                  callBack={onclickButtonTariff}
                  endCoin={<PaidIcon />}
                  variant={"outlined"}
                  disabled={disabledButtonTariff}
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                />
              )}
            </div>
          </div>
        </Block>
        <Block title={"Сменить пароль"}>
          <form className={s.formResetPass} onSubmit={handleSubmit(onSubmit)}>
            <ControlledTextField
              fullwidth
              control={control}
              label={"Введите новый пароль"}
              name={"password"}
              error={errors.password && errors.password.message}
              type={"password"}
            />
            <ControlledTextField
              fullwidth
              control={control}
              label={"Повторите новый пароль"}
              name={"confirmPassword"}
              error={errors.confirmPassword && errors.confirmPassword.message}
              type={"password"}
            />
            <div className={s.stateFlexEndButton}>
              <ButtonMiu
                title={"Сменить"}
                type={"submit"}
                variant={"outlined"}
                endCoin={<HttpsSharpIcon />}
              />
            </div>
          </form>
        </Block>
      </div>
      <div className={s.tabs}>
        <CustomizedTabs tabs={tabs} />
      </div>
    </Block>
  );
};
