import React, { useContext, useEffect } from "react";
import s from "./VkChatBot.module.scss";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/hooks";
import { AnswersStateType, thunkVkChatBot } from "./vkChatBotReducer";
import { ProfileResType, VkAccountType } from "../../Auth/auth.api";
import { ButtonMiu } from "../../../components/Buttons/Button/ButtonMiu";
import EmailSharpIcon from "@mui/icons-material/EmailSharp";
import { Block } from "../../../components/Layout/Block/Block";
import { clsx } from "clsx";
import vkLabelBlack from "../../../common/assets/icons/svg/vk/VKLogo.svg";
import vkLabelWhite from "../../../common/assets/icons/svg/vk/VKTextLogoWhite.svg";
import { Theme, ThemeContext } from "../../../common/hoc/ThemeProvider/ThemeContext";
import { useNavigate } from "react-router-dom";
import { PATCH } from "../../Pages";
import { PaginationTabs } from "../../../components/Paginations/PaginationTabs/PaginationTabs";
import { vkAccountActions } from "../../Account/VkAccount/vkAccountReducer";
import VkAnswer from "./vkAnswer/VkAnswer";

type VkChatBotType = {
  disabled?: boolean | null;
};

export const VkChatBot: React.FC<VkChatBotType> = (props) => {
  const { disabled } = props;
  const classNames = {
    blockWrapperNotAcc: clsx(s.blockWrapperNotAcc),
  };
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useAppSelector<ProfileResType>((state) => state.authorization.profile);

  useEffect(() => {
    if (profile?.vk_accounts && profile?.vk_accounts.length > 0) {
      const accountIds = profile.vk_accounts.map((account) => account.id);
      dispatch(thunkVkChatBot.getAutoAnswer({ account_ids: accountIds }));
    }
  }, [dispatch, profile]);

  /** <Pagination> */
  const portionPage = useAppSelector<number>((state) => state.vkAccount.pagination.portionPage);
  const currentPage = useAppSelector<number>((state) => state.vkAccount.pagination.currentPage);
  const totalCount = useAppSelector<number>((state) => state.vkAccount.pagination.totalCount);
  const limit = useAppSelector<number>((state) => state.vkAccount.pagination.limit);

  const vkAccount = useAppSelector<VkAccountType[]>(
    (state) => state.authorization.profile.vk_accounts,
  );
  const currentAccountId = vkAccount[currentPage - 1]?.id.toString();

  const vkAnswers = useAppSelector<AnswersStateType>((state) => state.vkChatBot.vkChatBot.vkAnswer);
  const autoAnswers = vkAnswers[currentAccountId];
  /** </Pagination> */

  const onclickNavigateToProfile = () => {
    navigate(PATCH.PROFILE);
  };
  const onClickSetCurrentPage = (currentPage: number) => {
    dispatch(vkAccountActions.setCurrentPage({ currentPage: currentPage }));
  };

  return (
    <div className={disabled ? `${s.chatBot} ${s.disabled}` : s.chatBot}>
      {profile?.vk_accounts && profile.vk_accounts.length > 0 ? (
        <React.Fragment>
          <PaginationTabs
            classNameWrapper={s.paginationWrapper}
            elementTabClassName={s.paginationTab}
            activeTabClassName={s.activeTab}
            classNamePage={s.page}
            array={vkAccount}
            tabNameProperty={"id"}
            totalCount={totalCount}
            currentPages={currentPage}
            pageLimit={limit}
            portionPage={portionPage}
            onClickPagination={onClickSetCurrentPage}
          />
          <Block
            borderNone
            title={"Автоответчик"}
            variant={"Large"}
            mb={"20px"}
            classNameWrapper={s.layout}
          >
            {currentAccountId && (
              <React.Fragment key={currentAccountId}>
                <VkAnswer accountId={currentAccountId} autoAnswers={autoAnswers || []} />
              </React.Fragment>
            )}
          </Block>
        </React.Fragment>
      ) : (
        <Block classNameWrapper={classNames.blockWrapperNotAcc}>
          <div className={s.blockFlex}>
            {theme === Theme.DARK ? (
              <img className={s.logoVk} src={vkLabelWhite} alt={"vkLogo"} />
            ) : (
              <img className={s.logoVk} src={vkLabelBlack} alt={"vkLogo"} />
            )}
            <div className={s.buttonBlock}>
              <ButtonMiu
                endCoin={<EmailSharpIcon />}
                title={"Привязать вк аккаунт"}
                callBack={onclickNavigateToProfile}
              />
            </div>
          </div>
        </Block>
      )}
    </div>
  );
};
