import React, { Suspense, useContext, useEffect, useRef, useState } from "react";
import Grid3x3SharpIcon from "@mui/icons-material/Grid3x3Sharp";
import { ButtonMiu } from "../../../components/Buttons/Button/ButtonMiu";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/hooks";
import vkLabel from "../../../common/assets/icons/svg/vk/VKTextLogo.svg";
import vkLabelDarkTheme from "../../../common/assets/icons/svg/vk/VKTextLogoWhite.svg";
import { VkAccountType } from "../../Auth/auth.api";
import DeleteIcon from "@mui/icons-material/Delete";
import { Switch } from "@mui/material";
import styled from "./VkAccount.module.scss";
import {
  LoadingAndDisabledVkAccState,
  MOCK_ID_LOADING_DISABLED_SYSTEM,
  vkAccountThunks,
} from "./vkAccountReducer";
import { TooltipMiu } from "../../../components/TooltipMiu/TooltipMiu";
import AddCircleSharpIcon from "@mui/icons-material/AddCircleSharp";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Block } from "../../../components/Layout/Block/Block";
import { DialogRadix } from "../../../components/Dialog/DialogRadix/DialogRadix";
import { IcReconnection } from "../../../components/Icon/IconComponents/IcReconnection";
import { appAction } from "../../../app/app-reducer";
import { ControlledTextField } from "../../../components/TextField/controlled-textField";
import { validationSchemes } from "../../../common/validationSchemes/validationSchemes";
import { Typography } from "../../../components/Typography/Typography";
import { IcInfo } from "../../../components/Icon/IconComponents/IcInfo";
import { IconButton } from "../../../components/Buttons/IconButton";
import { LinkVkApiKey, VkAccountEnum } from "../../../common/enam/enamProject";
import WebhookIcon from "@mui/icons-material/Webhook";
import { Loader } from "../../../components/Loadings/Loader/Loader";
import { Loading } from "../../../components/Loadings/Loading";
import AddIcon from "@mui/icons-material/Add";
import { GifHelpMe } from "../../../components/Gif/ApiKeyVkGif/GifHelpMe";
import GifVkConnect from "../../../common/assets/icons/gif/account_connect.gif";
import GifVkWebHook from "../../../common/assets/icons/gif/account_confirmation.gif";
import { Theme, ThemeContext } from "../../../common/hoc/ThemeProvider/ThemeContext";

const schema = yup.object().shape({
  api_key: validationSchemes.api_key,
});
type DataType = yup.InferType<typeof schema>;

export const VkAccount = () => {
  const vkAccount = useAppSelector<VkAccountType[]>(
    (state) => state.authorization.profile.vk_accounts,
  );
  const isAdmin = useAppSelector<boolean>((state) => state.authorization.profile.is_admin);
  const dispatch = useAppDispatch();
  const token = useAppSelector<string>((state) => state.authorization.login.access_token);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (vkAccount) {
      vkAccount.forEach((account) => {
        const id = account.id;
        dispatch(vkAccountThunks.statusWebHook({ account_id: id, token }));
      });
    }
  }, [dispatch, token, vkAccount]);

  const loadingSystem = useAppSelector<LoadingAndDisabledVkAccState>(
    (state) => state.vkAccount.loadingAndDisabledSystemVkAcc,
  );
  const statusWebHook = useAppSelector<null | boolean>((state) => state.vkAccount.statusWebHook);

  const [openModalDeleteVkAcc, setOpenModalDeleteVkAcc] = React.useState<number | null>(null);
  const [checkedDeleteAcc, setCheckedDeleteAcc] = React.useState(false);
  const [openDialogReconnectAcc, setOpenDialogReconnectAcc] = useState({
    value: false,
    flagReconnect: false,
  });
  const [openModalInfoApiKey, setOpenModalInfoApiKey] = useState(false);
  const [openDialogWebhookStatusFalse, setOpenDialogWebhookStatusFalse] = React.useState(false);
  const iconSx = { color: "#27a456", fontSize: "20px" };
  const reconnectVkAccRef = useRef<HTMLButtonElement>(null);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      api_key: "",
    },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const onClickDeleteVkAcc = (accountId: number) => {
    dispatch(
      vkAccountThunks.deleteVkAcc({
        account_id: accountId,
        is_confirmed: checkedDeleteAcc,
        token: token,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(appAction.setAlerts({ alerts: "Вконтакте аккаунт успешно удален." }));
      })
      .catch((e) => {
        console.warn(e, "Delete VK account error");
      });
    setOpenModalDeleteVkAcc(null);
    setCheckedDeleteAcc(false);
  };

  const onSubmit = (data: DataType) => {
    dispatch(vkAccountThunks.addVkAccount({ token: token, api_key: data.api_key! }))
      .unwrap()
      .then(() => {
        dispatch(appAction.setAlerts({ alerts: "Вк аккаунт успешно добавлен" }));
        setCheckedDeleteAcc(false);
      })
      .catch((e) => {
        console.warn(e);
      });
    reset();
  };
  const onClickStatusWebhook = (id: number) => {
    dispatch(vkAccountThunks.statusWebHook({ account_id: id, token }));
  };
  const handleChangeConfirmDelVkAcc = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.currentTarget.checked;
    setCheckedDeleteAcc(checked);
  };

  const onClickCloseModalDeleteVkAcc = () => {
    setOpenModalDeleteVkAcc(null);
    setCheckedDeleteAcc(false);
  };
  const onClickOpenModalDelVkAcc = (id: number) => {
    setOpenModalDeleteVkAcc(id);
  };
  const onClickCloseDialogReconnect = () => {
    setOpenDialogReconnectAcc({ value: false, flagReconnect: false });
  };
  const onClickOpenDialogReconnect = (flagReconnect: boolean) => {
    setOpenDialogReconnectAcc({ value: true, flagReconnect: flagReconnect });
  };
  const onClickBtnOpenDialogWebhookFalse = () => {
    setOpenDialogWebhookStatusFalse(true);
  };

  const onClickReconnectAcc = () => {
    if (isDirty) {
      onSubmit(getValues());
      onClickCloseDialogReconnect();
    } else {
      onClickCloseDialogReconnect();
    }
  };

  const onClickBtnStatusWebhookNull = () => {
    if (reconnectVkAccRef && reconnectVkAccRef.current) {
      reconnectVkAccRef.current.focus();
    }
  };

  return (
    <>
      {vkAccount && vkAccount.length > 0 ? (
        <div className={styled.vkAccountFlex}>
          {vkAccount.map((el) => {
            return (
              <React.Fragment key={el.id}>
                <Block
                  key={el.id}
                  title={"Вконтакте аккаунт"}
                  tooltipTrigger={VkAccountEnum.accountReconnect}
                  trigger={
                    <div className={styled.reconnectBtnDiv}>
                      {loadingSystem["deleteVkAcc"][el.id] ? (
                        <Loader />
                      ) : (
                        <IconButton
                          ref={reconnectVkAccRef}
                          onClick={() => {
                            onClickOpenDialogReconnect(true);
                          }}
                        >
                          <IcReconnection size={1.3} />
                        </IconButton>
                      )}
                    </div>
                  }
                  classNameWrapper={styled.vkBlock}
                >
                  <div className={styled.stateFlex}>
                    <Typography className={styled.text}>ID профиля:</Typography>
                    <div className={styled.answer}>
                      <Typography className={styled.text}>{el.id}</Typography>
                      <Grid3x3SharpIcon sx={iconSx} />
                    </div>
                  </div>
                  <div className={styled.stateFlex}>
                    <Typography className={styled.text}>Статус вебхука</Typography>
                    <div className={styled.answer}>
                      {statusWebHook === false ? (
                        <div className={styled.statusWebHookBlock}>
                          <TooltipMiu placement={"top"} title={"Обновить статус веб хука"}>
                            <IconButton
                              className={styled.updateStatusWebhook}
                              onClick={() => {
                                onClickStatusWebhook(el.id);
                              }}
                            >
                              <IcReconnection size={1} />
                            </IconButton>
                          </TooltipMiu>
                          <Typography colorText={"danger"} className={styled.textNoWebhook}>
                            не подтвержден
                          </Typography>
                          {loadingSystem["statusWebhook"][el.id] ? (
                            <Loader />
                          ) : (
                            <TooltipMiu
                              placement={"top-start"}
                              title={VkAccountEnum.statusWebhookFalse}
                            >
                              <IconButton
                                onClick={onClickBtnOpenDialogWebhookFalse}
                                className={styled.btnIconStatusWebhook}
                              >
                                <IcInfo size={1.1} />
                              </IconButton>
                            </TooltipMiu>
                          )}
                        </div>
                      ) : statusWebHook === null ? (
                        <div className={styled.statusWebHookBlock}>
                          <TooltipMiu placement={"top"} title={"Обновить статус веб хука"}>
                            <IconButton
                              className={styled.updateStatusWebhook}
                              onClick={() => {
                                onClickStatusWebhook(el.id);
                              }}
                            >
                              <IcReconnection size={1} />
                            </IconButton>
                          </TooltipMiu>
                          <Typography colorText={"danger"} className={styled.textNoWebhook}>
                            не найден
                          </Typography>
                          {loadingSystem["statusWebhook"][el.id] ? (
                            <Loader />
                          ) : (
                            <TooltipMiu
                              placement={"top-start"}
                              title={VkAccountEnum.statusWebhookNull}
                            >
                              <IconButton
                                onClick={onClickBtnStatusWebhookNull}
                                className={styled.btnIconStatusWebhook}
                              >
                                <IcInfo size={1.1} />
                              </IconButton>
                            </TooltipMiu>
                          )}
                        </div>
                      ) : (
                        <div className={styled.statusWebHookBlock}>
                          <Typography className={styled.text}>готов к работе</Typography>
                          <WebhookIcon sx={iconSx} />
                        </div>
                      )}
                    </div>
                  </div>
                  <DialogRadix
                    title={
                      openDialogReconnectAcc.flagReconnect
                        ? "Переподключить вк аккаунт"
                        : "Привязать вк аккаунт"
                    }
                    onClickDialogConfirmOk={onClickReconnectAcc}
                    onOpenCloseDialog={onClickCloseDialogReconnect}
                    open={openDialogReconnectAcc.value}
                    disabledButton={
                      loadingSystem["addVkAccount"][MOCK_ID_LOADING_DISABLED_SYSTEM.ADD_VK_ACC]
                    }
                    className={styled.dialogReconnectWrapper}
                    trigger={
                      <span>
                        {loadingSystem["addVkAccount"][
                          MOCK_ID_LOADING_DISABLED_SYSTEM.ADD_VK_ACC
                        ] && <Loader />}
                      </span>
                    }
                  >
                    <form
                      className={styled.dialogReconnectChildren}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <ControlledTextField
                        fullwidth
                        id="added-autoAnswer-recconect"
                        control={control}
                        name={"api_key"}
                        placeholder={"введите API-KEY Вконтакте"}
                        error={errors.api_key && !errors.api_key.message}
                      />
                    </form>
                  </DialogRadix>
                  <div className={styled.deleteBlock}>
                    <ButtonMiu
                      callBack={() => onClickOpenModalDelVkAcc(el.id)}
                      title={"Удалить аккаунт"}
                      endCoin={<DeleteIcon />}
                      disabled={
                        loadingSystem["addVkAccount"][MOCK_ID_LOADING_DISABLED_SYSTEM.ADD_VK_ACC]
                      }
                    />
                    <DialogRadix
                      title={"Удалить вконтакте аккаунт"}
                      open={openModalDeleteVkAcc === el.id}
                      onClickDialogConfirmOk={() => {
                        onClickDeleteVkAcc(el.id);
                      }}
                      onOpenCloseDialog={onClickCloseModalDeleteVkAcc}
                      disabledButton={!checkedDeleteAcc || loadingSystem["deleteVkAcc"][el.id]}
                      description={"Для удаления аккаунта передвинте ползунок и нажмите ОК"}
                    >
                      <div className={styled.switchBlock}>
                        {loadingSystem["deleteVkAcc"][el.id] ? (
                          <Loading />
                        ) : (
                          <Switch
                            checked={checkedDeleteAcc}
                            onChange={handleChangeConfirmDelVkAcc}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        )}
                      </div>
                    </DialogRadix>
                  </div>
                  <DialogRadix
                    onOpenCloseDialog={() => {
                      setOpenDialogWebhookStatusFalse(false);
                    }}
                    title={"Статус веб хука"}
                    open={openDialogWebhookStatusFalse}
                    onClickDialogConfirmOk={() => {
                      setOpenDialogWebhookStatusFalse(false);
                    }}
                    className={styled.dialogInfoApiKey}
                    classNameChildren={styled.childrenDialogInfoApiKey}
                  >
                    <GifHelpMe src={GifVkWebHook} alt={"vkontakte connect"} />
                  </DialogRadix>
                </Block>
              </React.Fragment>
            );
          })}
          {isAdmin && vkAccount.length < 2 && (
            <TooltipMiu
              title={"Добавить аккаунт"}
              placement={"top"}
              className={styled.blockNewTooltip}
            >
              <Block classNameWrapper={styled.blockNewAcc}>
                {loadingSystem["addVkAccount"][MOCK_ID_LOADING_DISABLED_SYSTEM.ADD_VK_ACC] ? (
                  <Loading size={50} sx={{ color: "gray" }} />
                ) : (
                  <AddIcon
                    className={styled.addAccIcon}
                    onClick={() => {
                      onClickOpenDialogReconnect(false);
                    }}
                  />
                )}
              </Block>
            </TooltipMiu>
          )}
        </div>
      ) : loadingSystem["addVkAccount"][MOCK_ID_LOADING_DISABLED_SYSTEM.ADD_VK_ACC] ? (
        <Block classNameWrapper={styled.blockLoadingVkAcc}>
          <div className={styled.loading}>
            <Loading size={50} sx={{ color: "gray" }} />
          </div>
        </Block>
      ) : (
        <Block classNameWrapper={styled.blockVkConnect} classNameChildren={styled.children}>
          {theme === Theme.DARK ? (
            <img className={styled.avitoIcon} src={vkLabelDarkTheme} alt={"vk-label"} />
          ) : (
            <img className={styled.avitoIcon} src={vkLabel} alt={"vk-label"} />
          )}
          <form className={styled.inputBlock} onSubmit={handleSubmit(onSubmit)}>
            <ControlledTextField
              control={control}
              name={"api_key"}
              label={"Привязать аккаунт вконтакте"}
              placeholder={"введите API-KEY Вконтакте"}
              error={errors.api_key && errors.api_key.message}
            />
            <TooltipMiu title={"Привязать вконтакте аккаунт"} placement={"top-start"}>
              <IconButton
                variant={"gray"}
                type={"submit"}
                className={styled.addAvitoAnswersIcon}
                size="medium"
              >
                <AddCircleSharpIcon />
              </IconButton>
            </TooltipMiu>
          </form>
          <div className={styled.blockInfo}>
            <Typography variant={"Overline"} colorText={"gray"}>
              Инструкция получения API-KEY
            </Typography>
            <IconButton
              variant={"gray"}
              onClick={() => {
                setOpenModalInfoApiKey(true);
              }}
            >
              <IcInfo size={1.1} />
            </IconButton>
            <DialogRadix
              title={"Получение ключа доступа"}
              open={openModalInfoApiKey}
              onClickDialogConfirmOk={() => {
                setOpenModalInfoApiKey(false);
              }}
              onOpenCloseDialog={() => {
                setOpenModalInfoApiKey(false);
              }}
              tooltipTrigger={"Открыть инструкцию из вк"}
              trigger={
                <IconButton as={"a"} target="_blank" href={LinkVkApiKey.linkInstruction}>
                  <IcInfo size={1.1} />
                </IconButton>
              }
              className={styled.dialogInfoApiKey}
              classNameChildren={styled.childrenDialogInfoApiKey}
            >
              <Suspense fallback={<Loader />}>
                <GifHelpMe src={GifVkConnect} alt={"Help vkontakte connect"} />
              </Suspense>
            </DialogRadix>
          </div>
        </Block>
      )}
    </>
  );
};
