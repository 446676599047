import React, { ForwardedRef, forwardRef, ReactNode } from "react";
import s from "./NavigationMenu.module.scss";
import clsx from "clsx";
import { Layout } from "../Layout";

type NavigationMenuProps = {
  className?: string;
  children: ReactNode;
};

export const NavigationMenu = forwardRef(
  (props: NavigationMenuProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { className, children } = props;
    const classNames = {
      root: clsx(s.navigationMenu, className && className),
    };
    return (
      <Layout ref={ref} className={classNames.root}>
        {children}
      </Layout>
    );
  },
);
