import React, { useCallback, useRef } from "react";
import s from "./VkBasicSettings.module.scss";
import QuestionAnswerSharpIcon from "@mui/icons-material/QuestionAnswerSharp";
import RestoreSharpIcon from "@mui/icons-material/RestoreSharp";
import { AutoAnswersSettingVkType } from "../../vk.chatBot.api";
import { EditableText } from "../../../../../components/EditableText/EditableText";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Block } from "../../../../../components/Layout/Block/Block";
import { useAppDispatch, useAppSelector } from "../../../../../common/hooks/hooks";
import { LoadingAndDisabledState } from "../../vkChatBotReducer";
import { Select } from "../../../../../components/Select";
import { SelectItem } from "../../../../../components/Select/SelectItem";
import { Loader } from "../../../../../components/Loadings/Loader/Loader";
import { TooltipMiu } from "../../../../../components/TooltipMiu/TooltipMiu";
import { TextField } from "../../../../../components/TextField";
import { secondsToMinutes } from "../../../../../common/utils/parseTimeMinutes";
import { IconButton } from "../../../../../components/Buttons/IconButton";
import IcSave from "../../../../../components/Icon/IconComponents/IcSave/IcSave";
import PauseCircleSharpIcon from "@mui/icons-material/PauseCircleSharp";
import { appAction } from "../../../../../app/app-reducer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validationSchemes } from "../../../../../common/validationSchemes/validationSchemes";
import { TooltipEnum } from "../../../../../common/enam/enamProject";
import { Typography } from "../../../../../components/Typography/Typography";

type AvitoBasicSettingsPropsType = {
  onChangeSelectMessagePause: (accountId: number, id: number, time: number) => void;
  onChangeMessagePause: (accountId: number, id: number, messagePause: number) => void;
  onChangeAutoAnswersSetting: (
    accountId: number,
    id: number,
    autoAnswerSetting: AutoAnswersSettingVkType,
  ) => void;
  onChangeTimeOffsetFromUTC: (accountId: number, id: number, utc: number) => void;
  editTitleAvitoAnswer: (accountId: number, id: number, name: string) => void;
  accountId: number;
  id: number;
  messagePauseSeconds: number;
  autoAnswersSetting: AutoAnswersSettingVkType;
  timeOffsetFromUtc: number;
  disabled?: boolean | null;
  name: string;
};
const autoAnswersSettingsMode = [
  { value: "OFF", text: "Выкл" },
  { value: "TIME_INTERVAL", text: "По расписанию" },
  { value: "FIRST_MESSAGE", text: "Приветствие" },
];
const timeZone = [
  -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
  14,
];

const iconWordModeOn = { color: "#27a456", fontSize: "30px", marginLeft: "7px", cursor: "pointer" };
const iconWordModeOnDisabled = { color: "#27a456", fontSize: "30px", marginLeft: "7px" };
const iconWordModeOff = { color: "#c5c5c5", fontSize: "30px", marginLeft: "7px" };

const schema = yup.object().shape({
  pause: validationSchemes.pause,
});
type DataType = yup.InferType<typeof schema>;

const VkBasicSettings: React.FC<AvitoBasicSettingsPropsType> = (props) => {
  const {
    messagePauseSeconds,
    autoAnswersSetting,
    id,
    accountId,
    onChangeSelectMessagePause,
    onChangeAutoAnswersSetting,
    onChangeTimeOffsetFromUTC,
    timeOffsetFromUtc,
    name,
    editTitleAvitoAnswer,
  } = props;

  const refBtn = useRef<HTMLButtonElement>(null);
  const loadingAndDisabledValues = useAppSelector<LoadingAndDisabledState>(
    (state) => state.vkChatBot.vkChatBot.loadingAndDisabledSystem.basic_settings,
  );
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const onSubmit = (data: DataType) => {
    if (!isDirty) {
      return;
    } else {
      if (data.pause !== undefined) {
        onChangeSelectMessagePause(accountId, id, data.pause);
      } else {
        console.log("undefined");
      }
    }
  };
  const onBlurTextFieldSaveTimePause = () => {
    if (isDirty && refBtn.current) {
      if (!errors || !errors.pause || !errors.pause.message) {
        refBtn.current.focus();
        dispatch(appAction.setAlerts({ alerts: "Не забудьте сохранить значение паузы!" }));
      } else {
        dispatch(appAction.setAlerts({ alerts: `Исправьте ошибку: ${errors.pause.message}` }));
      }
    }
  };

  const handleSelectAutoAnswersSetting = useCallback(
    (id: number, event: string) => {
      const autoAnswersSetting = event as AutoAnswersSettingVkType;
      onChangeAutoAnswersSetting(accountId, id, autoAnswersSetting);
    },
    [accountId, onChangeAutoAnswersSetting],
  );

  const handleChangeTimeZoneUTC = useCallback(
    (id: number, event: string) => {
      const timeOffsetFromUTC = +event as number;
      onChangeTimeOffsetFromUTC(accountId, id, timeOffsetFromUTC);
    },
    [accountId, onChangeTimeOffsetFromUTC],
  );

  const handleEditableSpanData = useCallback(
    (text: string) => {
      const id = props.id;
      editTitleAvitoAnswer(accountId, id, text);
    },
    [accountId, editTitleAvitoAnswer, props.id],
  );
  return (
    <>
      <Block title={"Основные настройки"} classNameWrapper={s.block}>
        <div className={s.state}>
          <div className={s.stateFlex}>
            <Typography asChild>
              <span>Название:</span>
            </Typography>
            <div className={s.nameAvitoAnswer}>
              {loadingAndDisabledValues["name"] ? (
                <Loader />
              ) : (
                <EditableText text={name} onEditableSpanData={handleEditableSpanData} />
              )}
              <DriveFileRenameOutlineIcon sx={iconWordModeOnDisabled} />
            </div>
          </div>
          <div className={s.stateFlex}>
            <TooltipMiu placement={"top-end"} title={TooltipEnum.pause}>
              <Typography asChild>
                <span>Пауза (в минутах):</span>
              </Typography>
            </TooltipMiu>
            <div className={s.pauseSeconds}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TooltipMiu
                  title={
                    "«Интервал,\n" +
                    "через ĸоторый автоответчиĸ будет срабатывать. При изменении\n" +
                    "настройĸа применится ТОЛЬКО ПОСЛЕ следующего\n" +
                    "срабатывания автоответчиĸа. Если предыдущая пауза еще не\n" +
                    "завершена, необходимо дождаться ее завершения»"
                  }
                >
                  <TextField
                    error={errors.pause && errors.pause.message}
                    {...register("pause")}
                    textFieldClassName={s.textFieldPause}
                    defaultValue={secondsToMinutes(messagePauseSeconds).toString()}
                    onBlur={onBlurTextFieldSaveTimePause}
                    triggerEnd={
                      <IconButton ref={refBtn} variant={"inputColor"} type={"submit"}>
                        <IcSave size={1.2} />
                      </IconButton>
                    }
                  />
                </TooltipMiu>
              </form>
              {autoAnswersSetting === "OFF" ? (
                <PauseCircleSharpIcon sx={iconWordModeOff} />
              ) : (
                <PauseCircleSharpIcon sx={iconWordModeOnDisabled} />
              )}
            </div>
          </div>
          <div className={s.stateFlex}>
            <TooltipMiu
              placement={"top-end"}
              title={
                autoAnswersSetting === "TIME_INTERVAL"
                  ? TooltipEnum.timeInterval
                  : autoAnswersSetting === "FIRST_MESSAGE"
                    ? TooltipEnum.firstMessages
                    : ""
              }
            >
              <Typography asChild>
                <span>Режим работы:</span>
              </Typography>
            </TooltipMiu>
            <div className={s.workMode}>
              <Select
                scrollBtn={false}
                value={autoAnswersSetting}
                onValueChange={(event) => handleSelectAutoAnswersSetting(id, event)}
                disabled={loadingAndDisabledValues["auto_answers_setting"]}
                loading={loadingAndDisabledValues["auto_answers_setting"]}
              >
                {autoAnswersSettingsMode.map((el, index) => {
                  return (
                    <SelectItem key={index} value={el.value}>
                      {el.text}
                    </SelectItem>
                  );
                })}
              </Select>
              {autoAnswersSetting === "OFF" ? (
                <QuestionAnswerSharpIcon sx={iconWordModeOff} />
              ) : (
                <QuestionAnswerSharpIcon sx={iconWordModeOn} />
              )}
            </div>
          </div>
          <div className={s.stateFlex}>
            <Typography asChild>
              <span>Часовой пояс (UTC):</span>
            </Typography>
            <div className={s.workTimeZone}>
              <Select
                position={"item-aligned"}
                scrollBtn={false}
                value={timeOffsetFromUtc.toString()}
                onValueChange={(event) => handleChangeTimeZoneUTC(id, event)}
                disabled={loadingAndDisabledValues["time_offset_from_utc"]}
                loading={loadingAndDisabledValues["time_offset_from_utc"]}
              >
                {timeZone.map((el, index) => {
                  return (
                    <SelectItem key={index} value={el.toString()}>
                      {el.toString()}
                    </SelectItem>
                  );
                })}
              </Select>
              {autoAnswersSetting === "OFF" ? (
                <RestoreSharpIcon sx={iconWordModeOff} />
              ) : (
                <RestoreSharpIcon sx={iconWordModeOn} />
              )}
            </div>
          </div>
        </div>
      </Block>
    </>
  );
};

export default React.memo(VkBasicSettings);
