type HelpDataType = {
  trigger: string;
  content: string;
  value: string;
};

export const faq: Array<HelpDataType> = [
  {
    trigger: "Где получить API-KEY?",
    content:
      "Откройте раздел Управление сообществом (Управление страницей, если у вас публичная страница), выберите вкладку Работа с API и нажмите Создать ключ доступа.Отметьте необходимые права доступа и подтвердите свой выбор.",
    value: "1",
  },
  {
    trigger: "Как подключить Callback API?",
    content:
      "Для подключения Callback API нужно открыть раздел Управление сообществом (Управление страницей, если у вас публичная страница), перейти во вкладку Работа с API.\n" +
      "\n" +
      "Далее необходимо указать и подтвердить конечный адрес сервера, куда будут направлены все запросы. Вы можете подключить до 10 серверов для Callback API, задать каждому из них отдельный набор событий и версию API.\n" +
      "\n" +
      "После указания адреса сервера и нажатия на кнопку подтвердить на указанный вами адрес отправится запрос с уведомлением типа confirmation. Ваш сервер должен вернуть заданную строку.",
    value: "2",
  },
];

export const questions: Array<HelpDataType> = [
  {
    trigger: "Где получить API-KEY?",
    content:
      "Откройте раздел Управление сообществом (Управление страницей, если у вас публичная страница), выберите вкладку Работа с API и нажмите Создать ключ доступа.Отметьте необходимые права доступа и подтвердите свой выбор.",
    value: "1",
  },
  {
    trigger: "Как подключить Callback API?",
    content:
      "Для подключения Callback API нужно открыть раздел Управление сообществом (Управление страницей, если у вас публичная страница), перейти во вкладку Работа с API.\n" +
      "\n" +
      "Далее необходимо указать и подтвердить конечный адрес сервера, куда будут направлены все запросы. Вы можете подключить до 10 серверов для Callback API, задать каждому из них отдельный набор событий и версию API.\n" +
      "\n" +
      "После указания адреса сервера и нажатия на кнопку подтвердить на указанный вами адрес отправится запрос с уведомлением типа confirmation. Ваш сервер должен вернуть заданную строку.",
    value: "2",
  },
];
