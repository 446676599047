import React, { useEffect, useState } from "react";
import s from "./ConfirmReg.module.scss";
import { AuthContainer } from "../AuthContainer/AuthContainer";
import clsx from "clsx";
import { Loading } from "../../../components/Loadings/Loading";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/hooks";
import ThumbDownAltSharpIcon from "@mui/icons-material/ThumbDownAltSharp";
import { Typography } from "../../../components/Typography/Typography";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { PATCH } from "../../Pages";
import { authThunks } from "../auth-reducer";
import { ButtonMiu } from "../../../components/Buttons/Button/ButtonMiu";
import ThumbUpAltSharpIcon from "@mui/icons-material/ThumbUpAltSharp";

export const ConfirmReg = () => {
  const classNames = {
    root: clsx(s.root),
    confirmBlock: clsx(s.confirmBlock),
    navigateText: clsx(s.navigateText),
  };
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const hashToken = params.get("token");

  const dispatch = useAppDispatch();
  const has_access = useAppSelector<boolean | null>(
    (state) => state.authorization.profile.has_access,
  );
  const [countdown, setCountdown] = useState(15);
  const loading = useAppSelector<boolean>((state) => state.app.isGlobalLoading);

  useEffect(() => {
    if (hashToken) {
      dispatch(authThunks.confirmReg({ token: hashToken }));
    }
  }, [dispatch, hashToken]);

  useEffect(() => {
    let intervalId: string | number | NodeJS.Timeout | undefined;
    if (has_access) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [has_access]);
  useEffect(() => {
    if (countdown === 0) {
      navigate(PATCH.AUTH.LOGIN);
    }
  }, [countdown, navigate]);
  const onClickHandlerSignIn = () => {
    navigate(PATCH.AUTH.LOGIN);
  };

  if (!hashToken) {
    return <Navigate to={PATCH.AUTH.LOGIN} />;
  }
  return (
    <div className={classNames.root}>
      <AuthContainer title={"Подтверждение аккаунта"}>
        <div className={classNames.confirmBlock}>
          {loading ? (
            <Loading size={50} sx={{ color: "gray" }} />
          ) : !loading && !has_access ? (
            <ThumbDownAltSharpIcon sx={{ fontSize: "40px", color: "red" }} />
          ) : (
            <ThumbUpAltSharpIcon sx={{ fontSize: "50px", color: "#5FA57D" }} />
          )}
          {!has_access ? (
            <Typography colorText={"danger"}>Аккаунт не подтвержден!</Typography>
          ) : (
            <Typography className={classNames.navigateText}>
              Аккаунт подтвержден! Перенаправление через {countdown} секунд(ы)...
            </Typography>
          )}
          {has_access && (
            <ButtonMiu
              callBack={() => {
                onClickHandlerSignIn();
              }}
              title={"Войти"}
            />
          )}
        </div>
      </AuthContainer>
    </div>
  );
};
