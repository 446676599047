import React, { ReactElement, ReactNode } from "react";
import s from "./AuthContainer.module.scss";
import { Layout } from "../../../components/Layout";
import { clsx } from "clsx";
import { IconProps } from "../../../components/Icon/IconWrapper/IconWrapper";
import { Typography } from "../../../components/Typography/Typography";

type AuthContainerProps = {
  classNameWrapper?: string;
  children: ReactNode;
  iconHeader?: ReactElement<IconProps>;
  title: string;
  classNameChildren?: string;
};

export const AuthContainer = (props: AuthContainerProps) => {
  const { classNameWrapper, children, iconHeader, title, classNameChildren } = props;

  const classNamesForgotPass = {
    wrapper: clsx(s.wrapper, classNameWrapper && classNameWrapper),
    circle: clsx(s.circle),
    children: clsx(s.children, classNameChildren && classNameChildren),
  };

  return (
    <Layout className={classNamesForgotPass.wrapper}>
      {iconHeader && <div className={classNamesForgotPass.circle}>{iconHeader}</div>}
      <Typography variant={"H1"}>{title}</Typography>
      {children}
    </Layout>
  );
};
