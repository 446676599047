import React from "react";
import s from "./ModalStatusReg.module.scss";
import { DialogRadix } from "../../../components/Dialog/DialogRadix/DialogRadix";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/hooks";
import { RegisterResType, StatusRegister } from "../auth.api";
import { handlerStringWithRegEndpoints } from "../../../common/utils/handlerStringWithRegEndpoints";

import { useNavigate } from "react-router-dom";
import { PATCH } from "../../Pages";
import clsx from "clsx";
import { authThunks } from "../auth-reducer";

type ModalStatusRegType = {
  open: boolean;
  onClose: (open: boolean | number | null) => void;
};

export const ModalStatusReg: React.FC<ModalStatusRegType> = (props) => {
  const { open, onClose } = props;
  const classNames = {
    dialog: clsx(s.dialog),
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const registrationData = useAppSelector<RegisterResType>(
    (state) => state.authorization.registration,
  );
  const email = useAppSelector<string>((state) => state.authorization.email);
  const statusReg = registrationData.status;
  const msgReg = handlerStringWithRegEndpoints(registrationData.msg);

  const onClickCloseModalHandler = () => {
    onClose(false);
  };

  const onclickHandlerResponseReg = (statusReg: StatusRegister) => {
    if (statusReg === "EXISTS") {
      navigate(PATCH.AUTH.LOGIN);
    } else if (statusReg === "OK" || statusReg === "AWAITING") {
      dispatch(authThunks.reconfirmReg({ email: email }))
        .unwrap()
        .then(() => {
          onClose(false);
        })
        .catch(() => {
          console.warn("native error");
        });
    }
  };

  return (
    <DialogRadix
      onOpenCloseDialog={onClickCloseModalHandler}
      open={open}
      title={"Статус регистрации"}
      description={msgReg}
      titleButtonConfirm={
        statusReg === "EXISTS"
          ? "Войти в аккаунт"
          : statusReg === "AWAITING" || statusReg === "OK"
            ? "Отправить подтверждение"
            : "Закрыть"
      }
      onClickDialogConfirmOk={() => {
        onclickHandlerResponseReg(statusReg);
      }}
      className={classNames.dialog}
    ></DialogRadix>
  );
};
